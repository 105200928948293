/* eslint-disable @typescript-eslint/indent */
import symbolSearch from '@components/watchlist/wlSearch'

// Return Types are missing in Arrow functions
export const getCookie = (key: string) => {
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i]
    const splitted = c.split("=")
    if (splitted[0].trim() === key && splitted[1] !== 'undefined') {
      return splitted[1]
    }
  }
  return ""
}

export const checkLogin = (): boolean => {
  const token = getCookie("token")
  const session = getCookie("session")
  if (token && session) {
    return true
  }
  return false
}

export const isDevelopment = () => process.env.REACT_APP_MODE === "development"

export const isStaging = () => process.env.REACT_APP_MODE === "staging"

// Prone to errors
export const formatNumber = (value: number): string => {
  let val: string | number = Math.abs(value)
  if (val >= 10000000) {
    val = `${(val / 10000000)?.toFixed(2)} cr`
    return val
  } if (val >= 100000) {
    val = `${(val / 100000)?.toFixed(2)} L`
    return val
  } if (val >= 1000) {
    val = `${(val / 1000)?.toFixed(1)}K`
    return val
  }
  return Number(val).toFixed(2)
}

export const setCookie = async (key: string, value: string, expires = "") => {
  document.cookie = `${key}=${value};expires=${expires};`
}

// Return Types are missing in Arrow functions
export const removeCookie = async (key: string) => {
  if (isDevelopment()) {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  } else {
    document.cookie = `${key}=; path=/; domain=.tiqs.in; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

export const parseQuery = (queryString: string) => Object.fromEntries(new URLSearchParams(queryString))

export const getQueryItem = (queryString: string, item: string) => {
  const params = new URLSearchParams(queryString)
  if (!item) {
    return null
  }
  return params.get(item)
}

// Debounce function was missing types, added it
// calls after given timer
export const debounce = (func: Function, timeout = 500) => {
  let timer: ReturnType<typeof setTimeout>
  let lastExecutionTime = 0
  return (...args: any[]) => {
    clearTimeout(timer)
    if (lastExecutionTime === 0 || Date.now() - lastExecutionTime > (100 * timeout)) {
      func.apply(this, args)
      lastExecutionTime = Date.now()
    }
    timer = setTimeout(() => {
      func.apply(this, args)
      lastExecutionTime = Date.now()
    }, timeout)
  }
}

export const throttle = (fn: Function, wait: number = 300) => {
  let inThrottle: boolean;
  let lastFn: ReturnType<typeof setTimeout>;
  let lastTime: number;
  // eslint-disable-next-line func-names
  return function (this: any) {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const capitalizeLetter = (value: string | undefined) => {
  if (value) {
    const words = value.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
  } return ""
}

export const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)

export const validatePan = (panVal: string): string | undefined => {
  const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (!regpan.test(panVal)) {
    return 'Invalid pan format'
  }
  return undefined
}

export const getFirstLetterOfWord = (value: string) => value && value.split(' ').map((i) => i.charAt(0))

export const removeCookiesOnLogout = () => {
  removeCookie("session")
  removeCookie("token")
  removeCookie("disclaimer")
}

export const ordinalSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st".sup();
  }
  if (j === 2 && k !== 12) {
    return i + "nd".sup();
  }
  if (j === 3 && k !== 13) {
    return i + "rd".sup();
  }

  return i + "th".sup()
}

interface IMonthNameType {
  [key: number]: string;
}

export const daySuffix: IMonthNameType = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  21: 'st',
  22: 'nd',
  23: 'rd',
  31: 'st',
}

export const monthName: IMonthNameType = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'July',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export const allowOnlyNumber = (val: string) => {
  const reg = /^[0-9]+$/
  return reg.test(val) || !val
}

export const formatSocket = (socketHolding: any, qty = 0, avgPrice = 0) => {
  const parsedQty = Number(qty);
  const netChange = socketHolding?.netChg || 0;
  const ltp = Number(((socketHolding?.ltp || 0) / 100))
  const currentVal = (parsedQty * ltp)
  const pnl = ((ltp - avgPrice) * parsedQty)
  const netChg = (((ltp - avgPrice) / avgPrice) * 100) || 0;
  const dayChg = netChange

  return {
    ltp: Number(ltp),
    currentVal: Number(currentVal),
    pnl: Number(pnl),
    netChg: Number(netChg),
    dayChg,
  }
}

export const instrumentSearch = symbolSearch

export const getUniqueEleFrom1 = (arr1: any, arr2: any) => {
  const tempDuplicate = arr1.filter((o: any) => arr2.indexOf(o) === -1);
  return [...new Set(tempDuplicate)]
}

export const getUniqueArr = (arr: any[]) => [...new Set(arr)]

function isObject(val: any): val is Record<string, any> {
  return typeof val === 'object' && val !== null;
}

export const deepClone = <T>(source: T): T => {
  // If the source isn't an Object or Array, throw an error.
  if (!isObject(source) || source instanceof Date || source instanceof String) {
    throw new Error('Only Objects or Arrays are supported.');
  }

  // Set the target data type before copying.
  const target = (source instanceof Array ? [] : {}) as T;

  // eslint-disable-next-line no-restricted-syntax
  for (const prop in source) {
    // Make sure the property isn't on the prototype
    if (!Object.prototype.hasOwnProperty.call(source, prop)) {
      // eslint-disable-next-line no-continue
      continue
    }

    // If the current property is an Array or Object, recursively clone it, else copy its value
    if (isObject(source[prop]) && !(source[prop] instanceof Date) && !(source[prop] instanceof String)) {
      // @ts-ignore
      target[prop] = deepClone(source[prop]) as T[Extract<keyof T, string>];
    } else {
      // @ts-ignore
      target[prop] = source[prop] as T[Extract<keyof T, string>];
    }
  }

  return target;
};

const padLeft = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}
export const formatDate = (val: Date) => {
  const convertedDate = val ? `${[padLeft((val.getMonth() + 1)),
  padLeft(val.getDate()),
  val.getFullYear()].join('/')} ${[padLeft(val.getHours()),
  padLeft(val.getMinutes()),
  padLeft(val.getSeconds())].join(':')}` : ""
  return convertedDate
}

export const isInputSelected = () => !document.activeElement!.tagName.toLowerCase().match(/input|textarea/)

export const isEqual = (first: any, second: any): boolean => {
  if (first === second) {
    return true
  }
  if (
    (first === undefined
      || second === undefined
      || first === null
      || second === null)
    && (first || second)
  ) {
    return false
  }
  const firstType = first?.constructor.name
  const secondType = second?.constructor.name
  if (firstType !== secondType) {
    return false
  }
  if (firstType === "Array") {
    if (first.length !== second.length) {
      return false
    }
    let equal = true
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i])) {
        equal = false
        break
      }
    }
    return equal
  }
  if (firstType === "Object") {
    let equal = true
    const fKeys = Object.keys(first)
    const sKeys = Object.keys(second)
    if (fKeys.length !== sKeys.length) {
      return false
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (first[fKeys[i]] && second[fKeys[i]]) {
        if (first[fKeys[i]] === second[fKeys[i]]) {
          continue // eslint-disable-line
        }
        if (
          first[fKeys[i]]
          && (first[fKeys[i]].constructor.name === "Array"
            || first[fKeys[i]].constructor.name === "Object")
        ) {
          equal = isEqual(first[fKeys[i]], second[fKeys[i]])
          if (!equal) {
            break
          }
        } else if (first[fKeys[i]] !== second[fKeys[i]]) {
          equal = false
          break
        }
      } else if (
        (first[fKeys[i]] && !second[fKeys[i]])
        || (!first[fKeys[i]] && second[fKeys[i]])
      ) {
        equal = false
        break
      }
    }
    return equal
  }
  return first === second;
};

export const getTernaryValue = (conditions: [boolean, boolean], values: any[]) => {
  if (conditions[0]) {
    return values[0]
  } if (conditions[1]) {
    return values[1]
  }
  return values[2]
}

export const getQuaternaryValue = (conditions: [boolean, boolean, boolean], values: any[]) => {
  if (conditions[0]) {
    return values[0]
  } if (conditions[1]) {
    return values[1]
  } if (conditions[2]) {
    return values[2]
  }
  return values[3]
}

export const getEncodedParams = (params: Record<string, any>) => {
  const searchParams: any[] = [];
  Object.keys(params)
    .forEach((key) => {
      const encodedKey = encodeURIComponent(key);
      if (params[key] && params[key].constructor.name === 'Array') {
        const encodedValue = encodeURIComponent(JSON.stringify(params[key]));
        searchParams.push(`${encodedKey}=${encodedValue}`);
      } else {
        const encodedValue = encodeURIComponent(params[key]);
        searchParams.push(`${encodedKey}=${encodedValue}`);
      }
    });
  return searchParams.join('&');
};

export const bounceAnimation = {
  animationDuration: "500ms",
  animationTimingFunction: "ease-in-out",
  animationName: "shakeY",
  "@keyframes shakeY": {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(-5px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
};

export const fadeInDownAnimation = {
  animationDuration: "300ms",
  animationTimingFunction: "ease-in-out",
  animationName: "fadeInDown",
  "@keyframes fadeInDown": {
    "0%": {
      opacity: 0,
      transform: "translateY(-20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}

export const fadeInAnimation = {
  animationDuration: "300ms",
  animationTimingFunction: "ease-in-out",
  animationName: "fadeIn",
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}

export const windowTableContainer = (data: any[]) => (data.length < 10 ? data.length * 50 + 60 : data.length * 50 + 60)

export const windowTableWidth = () => {
  if (window.screen.availWidth < 600) {
    return window.screen.availWidth
  } if (window.screen.availWidth > 600 && window.screen.availWidth <= 1440) {
    return window.screen.availWidth - 460
  }
  return 980
}

export const keys = (object) => Object.keys(object)

export const convertPascalToLowerCase = (str) => {
  if (str.length === 0) {
    return "";
  }

  // Convert the first character to lowercase
  let convertedStr = str.charAt(0).toLowerCase();

  for (let i = 1; i < str.length; i++) {
    if (str.charAt(i) === str.charAt(i).toUpperCase()) {
      convertedStr += ` ${str.charAt(i).toLowerCase()}`;
    } else {
      convertedStr += str.charAt(i);
    }
  }

  return convertedStr;
}

export const removeEQFromSymbol = (symbol: string): string => (symbol?.includes("-EQ") ? symbol?.slice(0, -3) : symbol)

export const getUniqueKey = (dict1, dict2) => {
  Object.keys(dict2).forEach((key) => {
    const value2 = key;
    let isUnique = true;

    Object.keys(dict1).forEach((key2) => {
      const value1 = key2;

      if (value1 === value2) {
        isUnique = false;
        return {};
      }
      return {};
    });

    if (isUnique) {
      return { [key]: 0 };
    }
    return {};
  });
};

export const getSegment = (exchange, symbol) => {
  if (exchange === "NFO") {
    if (symbol.slice(-1).toLowerCase() === "f") {
      return "NFO-FUT"
    } if (typeof Number(symbol.slice(-1)) === "number") {
      return "NFO-OPT"
    }
  }
  return "NSE"
};

export interface SEOData {
  title?: string;
  metaDescription?: string;
}

export function setTitle(data: SEOData): void {
  const title = data.title || 'Tiqs';
  document.title = title;
}

export function epochToTimeFormat(epoch: string) {
  const date = new Date(Number(epoch) * 1000); // Convert to milliseconds by multiplying with 1000
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedTime = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  return formattedTime;
}

export function isPartialExecution(c: string, f: string) {
  if (Number(c) > 0 && Number(f) > 0) {
    return true
  }
  return false
}

export function formatToINR(val: number) {
  return val.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  })
}

export const getDropdownCoordinates = () => {
  const spanElement = document.querySelector('.dots');
  const rect = spanElement?.getBoundingClientRect();
  return {
    top: rect?.top, right: rect?.right, bottom: rect?.bottom, left: rect?.left,
  }
}

export const checkRedirect = (): (string | false)[] => {
  const params = new URLSearchParams(window.location.search)
  const urlSlashSplit = window.location.href.split('/');
  const redirectTarget = params.get('redirect') || false;
  const redirectTargetRoute = params.get('route') || false;
  const redirectTo = urlSlashSplit && urlSlashSplit.length > 1 && urlSlashSplit[urlSlashSplit.length - 1]

  return [redirectTarget, redirectTo, redirectTargetRoute]
}

export const childSites = ["hub"]

export const navigateToSite = (target: string, subRoute: string | boolean) => {
  switch (target) {
    case "hub":
      if (typeof process.env.REACT_APP_HUB_URL === "string") {
        const url = subRoute ? `${process.env.REACT_APP_HUB_URL}/${subRoute}` : process.env.REACT_APP_HUB_URL
        window.location.href = url
      }
      break;
    default:
  }
}

export const getNiceName = (token: string | undefined, exchange: string | undefined, symbol: string | undefined) => {
  if (token && exchange) {
    const segment = getSegment(symbol, exchange);
    const instrumentName = instrumentSearch.tokenMap[token]
    if (instrumentName) {
      return instrumentSearch.get(instrumentName, segment, exchange).niceName
    }
  }
  return symbol
}
