import axios from "../../api/axiosInterceptors"
import { tradesEndPoints, ordersEndPoints } from "../../api/apiEndpoints"

export function requestUserOrders() {
  return axios.request({
    method: "get",
    url: ordersEndPoints.userOrdersUrl,
  })
}

export function requestOrderHistory(orderId: string) {
  return axios.request({
    method: "get",
    url: ordersEndPoints.orderHistoryUrl + orderId,
  })
}

export function requestCancelRegularOrder(orderId: string) {
  return axios.request({
    method: "delete",
    url: `${tradesEndPoints.regularOrderUrl}/${orderId}`,
  })
}

export function requestUserTrades() {
  return axios.request({
    method: "get",
    url: ordersEndPoints.userTradesUrl,
  })
}

export function requestOcoAlert(data: any) {
  return axios.request({
    method: "post",
    url: ordersEndPoints.alertOcoUrl,
    data,
  })
}

export function requestEditOcoAlert(data: any, id: string) {
  return axios.request({
    method: "patch",
    url: `${ordersEndPoints.alertOcoUrl}/${id}`,
    data,
  })
}

export function requestSingleAlert(data: any) {
  return axios.request({
    method: "post",
    url: ordersEndPoints.alertSingleUrl,
    data,
  })
}

export function requestEditSingleAlert(data: any, id: string) {
  return axios.request({
    method: "patch",
    url: `${ordersEndPoints.alertSingleUrl}/${id}`,
    data,
  })
}

export function requestGttDetails() {
  return axios.request({
    method: "get",
    url: ordersEndPoints.gttDetailsUrl,
  })
}

export function requestGttOrder(id: string) {
  return axios.request({
    method: "delete",
    url: ordersEndPoints.deleteGttUrl + id,
  })
}
