import {
  Badge, Box, Button, Divider, Group, Modal,
} from "@mantine/core"
import { buySellConst, orderStatusColor, productType } from "src/utils/constant"
import { memo } from "react"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { getNiceName } from "@utils/index"
import Flex from "@components/atoms/flex/flex"
import { IExtendedPositionItem, IPositionItem } from "@redux/sagas/positions"
import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice"
import { useDispatch } from "react-redux"

interface OrderDetailsModalProps {
  isVisible: boolean
  handlePositionDetailsClose: () => void
  selectedPositionDetails: IExtendedPositionItem
  handleConvertPosition: (item: IExtendedPositionItem) => void
}

interface OrderDetailListItemProps {
  title: string
  value: string | number | undefined
}

const PostionDetailListItem = ({ title, value }: OrderDetailListItemProps) => (
  <Group mb="sm" position="apart" align="center">
    <p className="content_md_regular text_fg_muted">
      {title}
    </p>
    <p className="content_md_regular text_fg_default">
      {typeof value === "number" ? value.toFixed(2) : value || "0.00"}
    </p>
  </Group>
)

const PostionDetailsModal = ({
  isVisible,
  handlePositionDetailsClose,
  selectedPositionDetails,
  handleConvertPosition,
}: OrderDetailsModalProps) => {
  const { isMobile } = useMediaCustom();
  const dispatch = useDispatch();
  // const isMobile = false
  const {
    exchange,
    symbol,
    pnl,
    qty,
    carryForwardBuyQty,
    carryForwardSellQty,
    avgPrice,
    ltp,
    product,
    dayBuyQty,
    dayBuyAvgPrice,
    daySellQty,
    daySellAvgPrice,
    netChg,
    token,
  } = selectedPositionDetails

  const carryForward = Number(carryForwardBuyQty) + Number(carryForwardSellQty)
  const dayBuyValue = Number(dayBuyQty) * Number(dayBuyAvgPrice);
  const daySellValue = Number(daySellQty) * Number(daySellAvgPrice);

  const transactionType = "B";
  const orderStatus = ""
  const instrumentName = getNiceName(token, exchange, symbol)

  const handleSelectExit = (item: IPositionItem) => {
    const itemToken = item.token;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          expiry: 0, symbol, token: itemToken, exchange,
        },
        buySellToggle: qty > 0,
        showTradeModal: true,
        quantity: qty,
      })
    );
    handlePositionDetailsClose()
  }

  const handleSelectAdd = (item: IPositionItem) => {
    const itemToken = item.token;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          expiry: 0, symbol, token: itemToken, exchange,
        },
        buySellToggle: !(qty > 0),
        showTradeModal: true,
        quantity: qty,
      })
    );
    handlePositionDetailsClose();
  }

  return (
    <Modal
      onClose={handlePositionDetailsClose}
      opened={isVisible}
      transitionProps={{ transition: 'fade', duration: 500, timingFunction: 'linear' }}
      size="lg"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Group position="apart" mb="sm">
        <Group position="apart">
          <Badge color={transactionType === "B" ? "blue" : "red"} radius="xs" variant="light">
            {buySellConst[transactionType!]}
          </Badge>
          <p className="heading_sm_semibold">{instrumentName}</p>
          <p className="text_fg_muted content_2xs_regular">{exchange}</p>
        </Group>
        <Badge color={orderStatusColor[orderStatus! || "COMPLETE"]} radius="xs" variant="light">
          {orderStatus || 'Complete'}
        </Badge>
      </Group>
      <Divider />

      {/* modal data */}
      <Flex align="flex-start" mt={16} mb={16}>
        <Box className="w-half pr-10">
          <PostionDetailListItem title="Net qty." value={qty.toString()} />
          <PostionDetailListItem title="Carry forward qty." value={carryForward.toString()} />
          <PostionDetailListItem title="Avg. price" value={avgPrice} />
          <PostionDetailListItem title="Last price" value={ltp} />
          <PostionDetailListItem title="Last close price" value="--" />
          <PostionDetailListItem title="P&L" value={pnl} />
          <PostionDetailListItem title="Day's P&L" value={netChg} />
          <PostionDetailListItem title="Product" value={productType[product]} />
        </Box>
        <Box className="w-half pl-10">
          <p className="content_lg_regular mb-4">Buys</p>
          <PostionDetailListItem title="Qty." value={dayBuyQty} />
          <PostionDetailListItem title="Price" value={dayBuyAvgPrice} />
          <PostionDetailListItem title="Value" value={dayBuyValue} />
          <Divider py={4} />
          <p className="content_lg_regular mb-4">Sells</p>
          <PostionDetailListItem title="Qty." value={daySellQty} />
          <PostionDetailListItem title="Price" value={daySellAvgPrice} />
          <PostionDetailListItem title="Value" value={daySellValue} />
        </Box>
      </Flex>

      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          color="blue"
          onClick={() => handleSelectAdd(selectedPositionDetails)}
        >
          Add
        </Button>
        <Button
          variant="outline"
          color="red"
          onClick={() => handleSelectExit(selectedPositionDetails)}
        >
          Exit
        </Button>
        <Button
          variant="outline"
          color="blue"
          onClick={() => handleConvertPosition(selectedPositionDetails)}
        >
          Convert
        </Button>
        <Button
          variant="outline"
          color="gray"
          onClick={handlePositionDetailsClose}
        >
          Cancel
        </Button>
      </Group>
    </Modal>
  )
}

export default memo(PostionDetailsModal)
