/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Checkbox,
  Button,
  Badge,
} from "@mantine/core"
import { tableUseStyles, pnlStyles } from "src/components/table/styled"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
import {
  useCallback, useEffect, useState,
} from "react";
import {
  OrderTypeML, SUCCESS, ValidityRetention, productType, tableContainer,
} from "@utils/constant";
import { IPositionsItem, IPositionItem, IExtendedPositionItem } from "@redux/sagas/positions";
import {
  formatSocket, isEqual,
} from "@utils/index";
import PnLCell from "@components/table/pnLCell";
import usePrevious from "@hooks/common/usePrevious";
// import ConfirmCancelSingle from "@components/modals/order/confirmCancelSingle";
import useCSVDownload from "@hooks/common/useCSVDownload";
import ConfirmExitPosition from "@components/modals/order/confirmCancel/confirmExitPosition";
import { connect, useDispatch, useSelector } from "react-redux";
import { regularOrder } from "@redux/sagas/trades/tradesSlice";
import { RootState } from "@redux/store";
import TitleHeading from "@components/atoms/titleHeading";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import { cleanNotificationsQueue } from "@mantine/notifications";
import { getUserPositions } from "@redux/sagas/positions/positionsSlice";
import useStyles from "./styled";
import PositionInstrument from "./positionInstrument";

type HydratedData = IExtendedPositionItem;

type IPositionTableList = {
  title: string;
  data: IPositionsItem;
  loading: number | boolean;
  hideCheckbox?: boolean;
  hydratedData: HydratedData[];
  isNoInternet: boolean;
  handlePositionDetails: (item: IExtendedPositionItem) => void;
  handleConvertPosition: (item: IExtendedPositionItem) => void;
  showDisabledState: boolean
};

const getPnLClassName = (netChange, colorScheme) => {
  const { classes } = pnlStyles();
  let className = "";
  if (Number(netChange) > 0) {
    className = `greenColor_${colorScheme}`
  } else if (Number(netChange) < 0) {
    className = `redColor_${colorScheme}`
  }
  return classes[className]
}

const PositionTableList = ({
  title,
  data,
  loading,
  hideCheckbox,
  hydratedData,
  isNoInternet,
  handlePositionDetails,
  handleConvertPosition,
  showDisabledState,
}: IPositionTableList) => {
  const { theme } = tableUseStyles();
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const {
    regularOrder: regularOrderData,
  } = useSelector((state: RootState) => state.trade)

  const prevProps = usePrevious({ regularOrderData })

  const [sortedData, setSortedData] = useState(hydratedData)
  const [selectAllTable, setSelectAllTable] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState("");
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [selectedOrderMap, setSelectedOrderMap] = useState({});

  const { downloadCSV } = useCSVDownload(
    ["product", "symbol", "qty", "avgPrice", "ltp", "pnl", "netChg"],
    "positions"
  );

  const isAnyRowSelected = Object.values(selectedOrderMap).some((value) => value);

  const countRowSelected = Object.values(selectedOrderMap).filter((value) => value).length;
  const rowWithQty = sortedData.filter((item) => item.qty !== 0);

  useEffect(() => {
    if (countRowSelected === rowWithQty.length && rowWithQty.length !== 0) {
      setSelectAllTable(true)
    } else {
      setSelectAllTable(false)
    }
  }, [countRowSelected, sortedData])

  const handleSelectRow = (row: IPositionItem, isCurrentVal: boolean) => {
    const uniqueSymbol = row.symbol + row.product;
    setSelectedOrderMap({ ...selectedOrderMap, [uniqueSymbol]: !isCurrentVal });
  }

  const handleSelectAll = (isVal: Boolean) => {
    const tempOrderMap = {};
    sortedData.forEach((item) => {
      if (item.qty !== 0) {
        tempOrderMap[item.symbol + item.product] = !isVal;
      }
    });
    setSelectedOrderMap(tempOrderMap)
    // setSelectAllTable(!isVal)
  }

  const handleCancelRow = (isBoolean: boolean) => {
    setShowConfirmCancel(isBoolean)
  }

  const handleCancelOrder = (position: IPositionItem) => {
    const payload = {
      exchange: position.exchange,
      token: position.token,
      quantity: Math.abs(position.qty)?.toString(),
      price: "",
      product: position.product,
      transactionType: position.qty > 0 ? "S" : "B",
      order: OrderTypeML[0].value,
      disclosedQty: "0",
      validity: ValidityRetention.DAY,
      amo: false,
      triggerPrice: "",
      tags: "",
    }
    dispatch(regularOrder(payload))
  };
  const regularOrderId = regularOrderData.data && regularOrderData.data.id;
  const previousRegularOrderId = prevProps.regularOrderData.data && prevProps.regularOrderData.data.id;
  useEffect(() => {
    cleanNotificationsQueue()
    if (!hideCheckbox) {
      if (
        !isEqual(previousRegularOrderId, regularOrderId)
        && regularOrderData.status === SUCCESS
      ) {
        dispatch(getUserPositions());
        // showNotification({
        //   color: "green",
        //   title: "Successfully exited",
        //   message: "Order Success",
        // })
      }
    }
  }, [prevProps, regularOrderData])

  const selectedRows = sortedData.filter((item) => {
    const uniqueSymbol = item.symbol + item.product;
    return Object.prototype.hasOwnProperty.call(selectedOrderMap, uniqueSymbol) && selectedOrderMap[uniqueSymbol];
  });

  const handleConfirmCancel = () => {
    selectedRows.forEach((item) => handleCancelOrder(item))
  }

  const sortData = useCallback(() => {
    if (sortInfo.sortBy === '') {
      return;
    }
    const tempSortedData = Array.from(hydratedData).sort((a, b) => {
      if (a[sortInfo.sortBy] < b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? -1 : 1;
      }
      if (a[sortInfo.sortBy] > b[sortInfo.sortBy]) {
        return sortInfo.sortDirection === SortDirection.ASC ? 1 : -1;
      }
      return 0;
    });
    setSortedData(tempSortedData);
  }, [sortInfo]);

  useEffect(() => {
    sortData();
  }, [sortInfo]);

  useEffect(() => {
    if ((sortedData.length === 0 && hydratedData.length) || !isEqual(hydratedData, prevProps.regularOrderData)) {
      // check if sort is enabled then sort and set the data
      if (sortInfo.sortBy !== '') {
        sortData();
      } else { // else simply set the data
        setSortedData(Array.from(hydratedData));
      }
    }
  }, [hydratedData]);

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearchVal(value)
  }, []);

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = hydratedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
      setSortedData(tempSearchData)
    } else if (!sortInfo.sortBy) {
      setSortedData(hydratedData)
    }
  }, [hydratedData, searchVal])

  const tableClassName = sortedData.length < 10 ? "table-min-height" : ""

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  const getBadgeColor = (product: string) => {
    switch (product) {
      case "CNC":
        return "gray";
      case "MIS":
        return "orange";
      case "NRML":
        return "purple";
      default:
        return "";
    }
  };

  // Return the data for a given row index
  const rowGetter = ({ index }) => sortedData[index];

  const headerRowRenderer = () => (
    <section className="table-header-row">
      {!hideCheckbox ? (
        <div
          aria-hidden="true"
          className="tableHeaderCell w-50 pl-10"
        >
          <Checkbox
            disabled={rowWithQty.length === 0}
            checked={selectAllTable}
            onChange={() => handleSelectAll(selectAllTable)}
          />
        </div>
      ) : <div />}

      <TableHeaderItem
        width={130}
        label="Product"
        value="product"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={!hideCheckbox ? 220 : 264}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={95}
        label="Qty"
        value="qty"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Avg. Cost"
        value="avgPrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="P&L"
        value="pnl"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={125}
        label="Net Chg."
        value="netChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  let totalPnL = 0;
  // Iterate through each row in sortedData and add up the PnL values
  for (let i = 0; i < sortedData.length; i++) {
    const row = sortedData[i];
    if (row) {
      totalPnL += row.pnl;
    }
  }

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = sortedData[index];
    if (row) {
      const uniqueSymbol = row.symbol + row.product;
      const disabledClass = row.qty === 0 && showDisabledState ? "disabled-row" : "";
      return (
        <div key={key} style={style} className={cx('table-row', disabledClass)}>
          {!hideCheckbox ? (
            <TableItem
              width={50}
              value={(
                <Checkbox
                  disabled={row.qty === 0}
                  checked={Boolean(selectedOrderMap[uniqueSymbol])}
                  onChange={() => handleSelectRow(row, Boolean(selectedOrderMap[uniqueSymbol]) || false)}
                />
              )}
              rightAlign={false}
              className="pl-10"
            />
          ) : <div />}
          <TableItem
            width={130}
            value={(
              <Badge size="md" radius="xs" color={getBadgeColor(productType[row.product])}>
                {productType[row.product]}
              </Badge>
            )}
            rightAlign={false}
          />
          <PositionInstrument
            row={row}
            handlePositionDetails={handlePositionDetails}
            handleConvertPosition={handleConvertPosition}
            width={!hideCheckbox ? 220 : 264}
            showDisabledState={showDisabledState}
          />
          <TableItem width={95} value={row.qty} />
          <TableItem width={125} value={row.avgPrice.toFixed(2)} />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.qty === 0 ? 0 : row.ltp}
                value={row.ltp || "0.00"}
              />
            )}
          />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.qty === 0 ? 0 : row.pnl}
                value={row.pnl || "0.00"}
              />
            )}
          />
          <TableItem
            width={125}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.qty === 0 ? 0 : row.netChg}
                value={row.netChg === Infinity ? "0.00" : row.netChg || "0.00"}
                showPercentage={row.netChg === Infinity ? false : true || false}
              />
            )}
            className="pr-10"
          />
        </div>
      );
    }
    return null
  };

  return (
    <Box mt={12} mb={20}>
      <TitleHeading
        title={title}
        length={data.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={Boolean(loading)}
        secondaryTitle={hideCheckbox ? "" : "View Analysis"}
        isNoInternet={isNoInternet}
      />
      {sortedData.length ? (
        <>
          <WindowScroller>
            {({
              height, isScrolling, registerChild, onChildScroll, scrollTop,
            }) => (
              <AutoSizer style={tableContainer} ref={() => registerChild(null)} disableHeight>
                {({ width }) => (
                  <Table
                    className={tableClassName}
                    autoHeight
                    width={width}
                    height={height}
                    rowCount={sortedData.length}
                    rowGetter={rowGetter}
                    rowHeight={50}
                    rowRenderer={rowRenderer}
                    headerRowRenderer={headerRowRenderer}
                    scrollTop={scrollTop}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
          <section className={classes.totalContainer}>
            <p className={cx(classes.totalValue, getPnLClassName(totalPnL, theme.colorScheme))}>
              {totalPnL.toFixed(2)}
            </p>
            <p className={classes.totalText}>Total</p>
          </section>
          {
            isAnyRowSelected && (
              <Button mt="lg" variant="light" onClick={() => handleCancelRow(true)}>
                Exit
                {' '}
                {countRowSelected}
                {' '}
                {countRowSelected > 1 ? 'positions' : 'position'}
              </Button>
            )
          }
        </>
      ) : <NotFoundState />}
      {/* show confirm modal before cancel order */}
      {showConfirmCancel ? (
        <ConfirmExitPosition
          isVisible={showConfirmCancel}
          handleCloseModal={handleCancelRow}
          handleConfirmCancel={handleConfirmCancel}
          selectedRows={selectedRows}
          handleSelectAll={handleSelectAll}
        />
      ) : null}
    </Box>
  )
}
// export default PositionTableList

const mapStateToProps = (state: RootState, ownProps: IPositionTableList) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    const {
      qty, avgPrice,
    } = item;
    const {
      ltp = 0,
      pnl = 0,
      netChg = 0,
      currentVal = 0,
    } = formatSocket(data, qty, avgPrice)
    return {
      ...item,
      ltp,
      currentVal,
      pnl,
      netChg,
      dayChg: Number(data.netChange || 0.00),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(PositionTableList)
