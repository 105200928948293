import { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectFullToken, selectQuoteTokens } from "src/redux/sagas/sockets/socketSelectors"
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { RootState } from "src/redux/store"
import {
  setFullSocketData,
  setFullToken,
  setLoadingSocket,
  setOrderUpdate,
  setQuoteSocketsData,
  setQuoteTokens,
} from "@redux/sagas/sockets/socketsSlice"
import usePrevious from "@hooks/common/usePrevious"
import {
  SUCCESS,
  MODE, MSG_TYPE, SOCKET_CONNECT, socketUpdate,
} from "@utils/constant";
import {
  debounce, getCookie, getUniqueArr, isEqual,
} from "../../utils"

const myWorker = new Worker("/websocket.js", { type: "module", name: "myWorker" })

const getSubsUnsubsTokens = (tokens: Record<number, number>) => {
  const sub: number[] = []
  const unSub: number[] = []
  const keys = Object.keys(tokens)
  for (let i = 0; i < keys.length; i++) {
    if (tokens[keys[i]] === 1) {
      sub.push(Number(keys[i]))
    } else if (tokens[keys[i]] === 0) {
      unSub.push(Number(keys[i]))
    }
  }

  const obj: Record<string, number> = {}
  getUniqueArr([...sub, ...unSub]).forEach((item: number) => {
    obj[item] = -1
  })

  return {
    sub,
    unsub: unSub,
    obj,
  }
}

const sendTokensToSubsUnsubs = (sub: number[], unsub: number[], mode = MODE.QUOTE) => {
  if (unsub.length) {
    myWorker.postMessage(
      JSON.stringify({
        data: unsub,
        msgType: MSG_TYPE.UNSUBSCRIBE,
        mode,
      })
    )
  }
  if (sub.length) {
    myWorker.postMessage(
      JSON.stringify({
        data: sub,
        msgType: MSG_TYPE.SUBSCRIBE,
        mode,
      })
    )
  }
}

// ltptokens -> not using anywhere as of now
// quotetokens -> used in wl | holdings | position | used in appbar header (pinned instruments)
// fullTokens -> used for market depth

// sending watchlist token to worker
// 1 -> to be subscribe
// 0 -> to be un-subscribe
// -1 -> already subscribed

let quoteUpdates: Record<string, any> = {};
let fullUpdates: Record<string, any> = {};

const debouncedQuoteData = debounce((dispatch: Dispatch<AnyAction>) => {
  dispatch(setQuoteSocketsData(quoteUpdates));
  quoteUpdates = {};
}, 60);

const debouncedFullData = debounce((dispatch: Dispatch<AnyAction>) => {
  dispatch(setFullSocketData(fullUpdates));
  fullUpdates = {};
}, 60);

const useSocketConnect = () => {
  const dispatch = useDispatch()
  const token = getCookie("token")
  const session = getCookie("session")

  // const watchlistTokens = useSelector(selectWatchlistTokens)
  const marketDepthToken = useSelector(selectFullToken)

  const quoteTokens = useSelector(selectQuoteTokens)
  const prevProps = usePrevious({ quoteTokens })

  const [subsUnsubsTokens, setSubsUnsubsTokens] = useState({});
  const [subsUnsubsFullTokens, setSubsUnsubsFullTokens] = useState({});

  const {
    logout: { status: logoutStatus },
  } = useSelector((state: RootState) => state.auth);

  myWorker.onmessage = (e) => {
    const parsedData = JSON.parse(e.data)
    if (parsedData.mode === MODE.QUOTE) {
      quoteUpdates[parsedData.token] = parsedData;
      debouncedQuoteData(dispatch);
    } else if (parsedData.mode === MODE.FULL) {
      fullUpdates[parsedData.token] = parsedData;
      debouncedFullData(dispatch)
    } else if (parsedData?.type === socketUpdate.ORDER_UPDATE) {
      dispatch(setOrderUpdate(parsedData))
    } else if (parsedData?.status === SOCKET_CONNECT.CONNECTING) {
      dispatch(setLoadingSocket({ loading: true }))
    } else if (parsedData?.status === SOCKET_CONNECT.CONNECTED) {
      dispatch(setLoadingSocket({ loading: false }))
    }
    // else if (parsedData.mode === MODE.LTP) {
    //   dispatch(setLtpSocketsData(parsedData))
    // }
  }

  useEffect(() => {
    // myWorker.terminate();
    if (session && token) {
      myWorker.postMessage(
        JSON.stringify({ msgType: MSG_TYPE.CONNECT, token, session })
      )
    }
  }, [session, token])

  const funcCall = useCallback(() => {
    if (!isEqual(quoteTokens, prevProps.quoteTokens)) {
      const { sub, unsub, obj } = getSubsUnsubsTokens(quoteTokens)
      sendTokensToSubsUnsubs(sub, unsub, MODE.QUOTE)
      // sending unsubscribed by making it -1, so that i will not sent again
      setSubsUnsubsTokens(obj)
    }
  }, [quoteTokens])

  useEffect(() => {
    if (token && Object.keys(quoteTokens).length) {
      funcCall()
    }
  }, [funcCall, quoteTokens, token])

  useEffect(() => {
    // sending unsubscribed by making it -1, so that i will not sent again
    if (Object.keys(subsUnsubsTokens).length) {
      dispatch(setQuoteTokens({ ...subsUnsubsTokens, source: "-ve from socket connect" }))
    }
  }, [dispatch, subsUnsubsTokens])

  // sending full token to worker (marketwatch)
  useEffect(() => {
    const { sub, unsub, obj } = getSubsUnsubsTokens(marketDepthToken)
    sendTokensToSubsUnsubs(sub, unsub, MODE.FULL)
    // sending unsubscribed by making it -1, so that i will not sent again
    setSubsUnsubsFullTokens(obj)
  }, [marketDepthToken])

  useEffect(() => {
    // sending unsubscribed by making it -1, so that i will not sent again
    if (Object.keys(subsUnsubsFullTokens).length) {
      dispatch(setFullToken({ ...subsUnsubsFullTokens, source: "from socket connect" }))
    }
  }, [dispatch, subsUnsubsFullTokens])

  // terminate worker on
  useEffect(() => {
    if (logoutStatus === SUCCESS) {
      myWorker.terminate();
    }
  }, [logoutStatus])

  return null
}

export default useSocketConnect
