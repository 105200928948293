import { Grid, Paper } from '@mantine/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import HoldingCalc from './holdingCalc';
import { RootState } from '../../redux/store';
import { selectHoldingsSockets } from '../../redux/sagas/sockets/socketSelectors';
import useDebouncedEffect from '../../hooks/common/useDebouncedEffect';
import { formatSocket, isEqual } from '../../utils';

const HoldingsSummary = () => {
  const {
    data: holdingsData,
  } = useSelector(
    (state: RootState) => state.holding.holdings
  )
  const [tokens, setTokens] = useState<string[]>([]);
  const socketsData = useSelector((state: RootState) => selectHoldingsSockets(state, tokens));

  const [totalInvestment, setTotalInvestment] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [daysPnL, setDaysPnL] = useState(0);
  const [totalPnL, setTotalPnL] = useState(0);

  useDebouncedEffect(() => {
    const holdingsTokens: string[] = [];
    let tempTotalInvestment = 0
    let tempCurrentValue = 0;
    let tempDaysPnL = 0
    let tempTotalPnL = 0
    if (holdingsData) {
      holdingsData.map((item) => {
        const { effectiveQty, avgPrice, token } = item;
        const socketHolding = socketsData[token];
        holdingsTokens.push(token);
        const { ltp = 0, pnl = 0 } = formatSocket(
          socketHolding,
          effectiveQty,
          avgPrice
        );
        tempTotalInvestment
          += Number(item.avgPrice) * Number(item.effectiveQty);
        tempCurrentValue += ltp * item.effectiveQty;
        tempDaysPnL
          += ((ltp / (100 - item.dayChg)) * 100 - ltp) * item.effectiveQty;
        tempTotalPnL += pnl;
        return null;
      });
    }
    setTotalInvestment(tempTotalInvestment)
    setCurrentValue(tempCurrentValue)
    setDaysPnL(tempDaysPnL)
    setTotalPnL(tempTotalPnL)
    if (!isEqual(tokens, holdingsTokens)) {
      setTokens(holdingsTokens)
    }
  }, [holdingsData, socketsData], 500);

  return (
    <Paper p={20} shadow="sm">
      <Grid mt={4}>
        <HoldingCalc title="Total investment" value={totalInvestment} />
        <HoldingCalc title="Current value" value={currentValue} />
        <HoldingCalc title="Day's P&L" value={daysPnL} />
        <HoldingCalc title="Total P&L" value={totalPnL} />
      </Grid>
    </Paper>

  )
}

export default HoldingsSummary;
