import { takeLatest } from "redux-saga/effects"

import {
  handleChangePassword,
  handleEnable2fa,
  handleUpdateUserPreferences,
  handleUserAddImage,
  handleUserDetails,
  handleUserLimits,
  handleUserPreferences,
  handleVerify2fa,
} from "./usersHandlers"

import {
  updateUserPreferences,
  addUserImage,
  getUserDetails,
  getUserLimits,
  getUserPreferences,
  enable2Fa,
  verify2Fa,
  changePassword,
} from "./usersSlice"

export function* userDetailsSaga() {
  yield takeLatest(getUserDetails.type, handleUserDetails)
}

export function* userPositionsSaga() {
  yield takeLatest(getUserLimits.type, handleUserLimits)
}

export function* userPreferencesSaga() {
  yield takeLatest(getUserPreferences.type, handleUserPreferences)
}

export function* updateUserPreferencesSaga() {
  yield takeLatest(updateUserPreferences.type, handleUpdateUserPreferences)
}

export function* userAddImageSaga() {
  yield takeLatest(addUserImage.type, handleUserAddImage)
}

export function* enable2faSaga() {
  yield takeLatest(enable2Fa.type, handleEnable2fa)
}

export function* verify2faSaga() {
  yield takeLatest(verify2Fa.type, handleVerify2fa)
}

export function* changePasswordSaga() {
  yield takeLatest(changePassword.type, handleChangePassword)
}
