import { Dispatch, SetStateAction } from "react"
import {
  Badge, Box, createStyles, Group, NumberInput, Radio, Text,
} from "@mantine/core"
import { ArrowNarrowRight } from "tabler-icons-react";
import useMediaCustom from "@hooks/common/useMediaCustom";
import { getTypographyStyles } from "@theme/themeConfig";
import Flex from "../../../atoms/flex/flex";

interface TriggerCncLimitPriceProps {
  ltpPercent: number;
  setLtpPercent: Dispatch<SetStateAction<number>>;
  gttPrice: number;
  setGttPrice: Dispatch<SetStateAction<number>>;
  gttQuantity: number;
  setGttQuantity: Dispatch<SetStateAction<number>>;
  triggerPrice: number;
  setTriggerPrice: Dispatch<SetStateAction<number>>;
  badgeValue: string;
}

const useStyles = createStyles(() => ({
  triggerInput: {
    width: '33px',
    fontSize: 10,
    borderBottom: "1px solid #ddd",
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    paddingLeft: 4,
    paddingRight: 2,
  },
  numberInputlabel: {
    ...getTypographyStyles('content_xs_regular'),
    paddingBottom: 4,
  },
}));

const TriggerCncLimitPrice = ({
  ltpPercent,
  setLtpPercent,
  gttPrice,
  setGttPrice,
  gttQuantity,
  setGttQuantity,
  triggerPrice,
  setTriggerPrice,
  badgeValue,
}: TriggerCncLimitPriceProps) => {
  const { classes } = useStyles()
  const { isMobile } = useMediaCustom()

  return (
    <Group position="apart" py="md" px="lg">
      <Box w={isMobile ? "100%" : "22%"} mt={badgeValue ? "10px" : "44px"}>
        {badgeValue && <Badge radius="xs" size="xs" color="indigo" mb={14}>{badgeValue}</Badge>}

        <NumberInput
          type="number"
          label="Trigger price"
          value={triggerPrice}
          classNames={{
            label: classes.numberInputlabel,
          }}
          onChange={(val: number) => setTriggerPrice(val)}
        />
        <Flex mt={6}>
          <input
            type="number"
            value={ltpPercent}
            onChange={(e) => setLtpPercent(Number(e.target.value))}
            className={classes.triggerInput}
          />
          <Text size={10}>% of LTP</Text>
        </Flex>
      </Box>

      {!isMobile && (
        <Group w="22%" spacing="xs" mt="46px">
          <Text size={10}>Places order</Text>
          <ArrowNarrowRight size={14} />
        </Group>
      )}

      <Box sx={{ width: isMobile ? "100%" : "48%" }}>
        <Flex position="space-between">
          <Radio
            size="xs"
            disabled
            defaultChecked
            className="radio-xxs"
            value="cnc"
            label="CNC"
          />
          <Radio
            size="xs"
            disabled
            defaultChecked
            className="radio-xxs"
            value="limit"
            label="LIMIT"
          />
        </Flex>
        <Flex mt={14}>
          <NumberInput
            type="number"
            min={1}
            step={1}
            label="Qty"
            value={gttQuantity}
            onChange={(val: number) => setGttQuantity(val)}
            classNames={{
              label: classes.numberInputlabel,
            }}
          />
          <NumberInput
            type="number"
            min={0.01}
            step={0.05}
            precision={2}
            ml="md"
            label="Price"
            value={gttPrice}
            onChange={(val: number) => setGttPrice(val)}
            classNames={{
              label: classes.numberInputlabel,
            }}
          />
        </Flex>
      </Box>
    </Group>
  )
}
export default TriggerCncLimitPrice
