import { call, put } from "redux-saga/effects"
import {
  requestCoverOrder,
  requestOrderMargin,
  requestRegularOrder,
  requestEditRegularOrder,
  requestEditCoverOrder,
  requestConvertPostionUrl,
} from "./tradesRequests"
import {
  convertPostionOrderFailure,
  convertPostionOrderSuccess,
  coverOrderFailure,
  coverOrderSuccess,
  editCoverOrderFailure,
  editCoverOrderSuccess,
  editRegularOrderFailure,
  editRegularOrderSuccess,
  orderMarginFailure,
  orderMarginSuccess,
  regularOrderFailure,
  regularOrderSuccess,
} from "./tradesSlice"

// handle regular trade
export function* handleRegularOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestRegularOrder, action.payload)
    yield put(regularOrderSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      regularOrderFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// edit regular order
export function* handleEditRegularOrder(action: any): Generator<any, any, any> {
  const { orderId, data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditRegularOrder, orderId, payloadData)
    yield put(editRegularOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(editRegularOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle cover order
export function* handleCoverOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCoverOrder, action.payload)
    yield put(coverOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(coverOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// edit cover order
export function* handleEditCoverOrder(action: any): Generator<any, any, any> {
  const { orderId, data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditCoverOrder, orderId, payloadData)
    yield put(editCoverOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(editCoverOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// show trade margin
export function* handleOrderMargin(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOrderMargin, action.payload)
    yield put(orderMarginSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(orderMarginFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// show trade margin
export function* handleConvertPostion(action: any): Generator<any, any, any> {
  const { data: payloadData } = action.payload
  try {
    const { data: { data, status } } = yield call(requestConvertPostionUrl, payloadData);
    yield put(convertPostionOrderSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(convertPostionOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}
