import { Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion'
import usePageShortcut from 'src/hooks/events/usePageShortcut';
import { checkLogin, isEqual } from 'src/utils';
import usePrevious from 'src/hooks/common/usePrevious';
import { selectOrderUpdate } from '@redux/sagas/sockets/socketSelectors';
import { setOrderUpdate } from '@redux/sagas/sockets/socketsSlice';
import type { RootState } from '@redux/store';
import ErrorBoundary from 'src/ErrorBoundary';
import { SUCCESS } from "@utils/constant";
import MarketDepthModal from '../modals/marketDepth';
import GttModal from '../modals/order/gttModal';
import TradeContent from '../watchlist/tradeModal/tradeContent';
import useSocketConnect from '../socket/useSocketConnect';
import TradeCharges from '../modals/order/tradeCharges';

const RenderGlobalComp = () => {
  const isLoggedIn = checkLogin();
  const dispatch = useDispatch();

  const [, setValue] = useLocalStorage({
    key: "userId",
    defaultValue: '',
  })

  const {
    gttModalInfo: { showGttModal, gttOrderDetails },
    marketDepthInfo: { showMarketDepthModal, marketDepthDetails },
  } = useSelector((state: RootState) => state.misc);

  const {
    tradeModalInfo: { showTradeModal },
    chargesModal,
  } = useSelector((state: RootState) => state.trade);

  const { validate2fa } = useSelector((state: RootState) => state.auth);

  const prevProps = usePrevious({ validate2fa })

  // responsible for subs & unsub tokens to websocket
  useSocketConnect()

  const orderUpdate = useSelector(selectOrderUpdate);
  const prevOrderUpdate = usePrevious(orderUpdate)

  // event page shortcut
  usePageShortcut();

  // handle validate msg
  useEffect(() => {
    if (
      !isEqual(prevProps.validate2fa.status, validate2fa.status)
      && validate2fa.status === SUCCESS
      && validate2fa.data
    ) {
      setValue(validate2fa.data.user.id || "");
    }
  }, [prevProps, validate2fa])

  useEffect(() => {
    if (orderUpdate.id !== prevOrderUpdate.id && Object.keys(prevOrderUpdate || {})?.length === 0) {
      dispatch(setOrderUpdate({}))
      if (orderUpdate.status === "REJECTED") {
        showNotification({
          color: "red",
          title: (
            <Text color="red" my={6} fz="md">
              {orderUpdate.status}
            </Text>
          ),
          message: (
            <>
              <Text>
                {orderUpdate.transactionType === "B" ? "BUY" : "SELL"}
                {' '}
                {orderUpdate.qty}
                {' '}
                x
                {' '}
                {orderUpdate.symbol}
                {' '}
                is rejected.
              </Text>
              <Text>
                {orderUpdate.reason}
              </Text>
              <Text>
                #
                {orderUpdate.id}
              </Text>
            </>
          ),
        });
      }
    }
  }, [dispatch, orderUpdate, prevOrderUpdate])

  if (!isLoggedIn) {
    return null
  }

  return (
    <div>
      {/* trade modal */}
      <ErrorBoundary>
        <AnimatePresence>
          {
            showTradeModal ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.15 }}
              >
                <TradeContent />
              </motion.div>
            ) : null
          }
        </AnimatePresence>
      </ErrorBoundary>
      {/* trade charges */}
      {chargesModal.showModal ? <TradeCharges isVisible={chargesModal.showModal} /> : null}

      {/*  show market depth modal */}
      {showMarketDepthModal ? (
        <MarketDepthModal
          selectedOrderDetails={marketDepthDetails}
          isVisible={showMarketDepthModal}
        />
      ) : null}
      {/* gtt modal */}
      {showGttModal ? (
        <GttModal
          selectedOrderDetails={gttOrderDetails}
          isVisible={showGttModal}
        />
      ) : null}
    </div>
  )
}

export default RenderGlobalComp
