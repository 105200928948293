import axios, { AxiosResponse } from "axios"
import { usersEndPoints } from "../../api/apiEndpoints"

export function requestUserDetails(): Promise<AxiosResponse> {
  return axios.request({
    method: "get",
    url: usersEndPoints.userDetailsUrl,
  })
}

export function requestUserLimits() {
  return axios.request({
    method: "get",
    url: usersEndPoints.userLimitsUrl,
  })
}

export function requestUserPreferences() {
  return axios.request({
    method: "get",
    url: usersEndPoints.userPreferencesUrl,
  })
}

export function requestUpdateUserPreferences(data: any) {
  return axios.request({
    method: "post",
    url: usersEndPoints.userPreferencesUrl,
    data,
  })
}

export function requestUserAddImage(data: any) {
  return axios.request({
    method: "post",
    url: usersEndPoints.userAddImageUrl,
    data,
  })
}

export function requestEnable2fa() {
  return axios.request({
    method: "post",
    url: usersEndPoints.enable2faUrl,
  })
}

export function requestVerify2fa(data: any) {
  return axios.request({
    method: "post",
    url: usersEndPoints.verify2faUrl,
    data,
  })
}

export function requestChangePassword(data: any) {
  return axios.request({
    method: "post",
    url: usersEndPoints.changePasswordUrl,
    data,
  })
}
