import { useEffect, useRef } from "react";
import { useRoute, useLocation } from "wouter";
import useGetSocketVal from "@hooks/common/useGetSocketVal";
import { useMantineColorScheme } from "@mantine/core";
import { getCookie, isEqual, setTitle } from "../../utils"

const Charts = () => {
  const [, params] = useRoute("/charts/:exchange/:ticker/:token");
  const mantineTheme = useMantineColorScheme();
  const theme = mantineTheme.colorScheme
  const [, setLocation] = useLocation();

  const token = params?.token
  const accessToken = getCookie("token")
  const session = getCookie("session")
  const { ltp } = useGetSocketVal(Number(token), true)

  const iframeRef = useRef<HTMLIFrameElement>();
  const pathParams = useRef(params);

  useEffect(() => {
    if (!isEqual(params, pathParams.current)) {
      pathParams.current = params;
    }
  }, [params])

  useEffect(() => {
    const iframe = document.getElementById("chart-iframe") as HTMLIFrameElement;
    if (iframe) {
      iframeRef.current = iframe;
    }
  }, [iframeRef])

  useEffect(() => {
    if (pathParams.current) {
      const ticker = decodeURI(pathParams.current.ticker as string)
      const { exchange } = pathParams.current

      setTitle({ title: `${ticker} (${exchange}) ${ltp || ''}` })

      const baseUrl = process.env.REACT_APP_CHART_URL || ""
      if (!iframeRef.current) {
        iframeRef.current = document.createElement('iframe');
        iframeRef.current.id = "chart-iframe"
        iframeRef.current.width = "100%"
        iframeRef.current.height = "100%"
        iframeRef.current.src = `${baseUrl}
/${exchange}/${ticker}?accessToken=${accessToken}&session=${session}&theme=${theme}`

        const currentDiv = document.getElementById("chart-container");
        currentDiv?.appendChild(iframeRef.current);
      }

      const data = `${exchange}:${ticker}`
      iframeRef.current.contentWindow?.postMessage(data, baseUrl);

      window.addEventListener('message', (event) => {
        if (event.origin === baseUrl) {
          const dataReceived = event.data.split(":")
          setLocation(`/charts/${dataReceived[0]}/${dataReceived[1]}/${dataReceived[2]}`);
        }
      });
    }
  }, [pathParams.current, accessToken, session, theme])

  return <div id="chart-container" />
}

export default Charts
