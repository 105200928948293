import {
  Badge, Box, Button, Divider, Group, Modal,
} from "@mantine/core"
import { buySellConst, orderStatusColor, productType } from "src/utils/constant"
import { memo } from "react"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { IUserOrder } from "@redux/sagas/orders"
import { getNiceName } from "@utils/index"

interface OrderDetailsModalProps {
  isVisible: boolean
  handleOrderDetailsCloseModal: () => void
  handleOrderHistoryModal: () => void
  selectedOrderDetails: Partial<IUserOrder>
}

interface OrderDetailListItemProps {
  title: string
  value: string | number | undefined
}

const OrderDetailListItem = ({ title, value }: OrderDetailListItemProps) => (
  <Group mb="sm" position="apart" align="center">
    <p className="content_md_regular text_fg_muted">
      {title}
    </p>
    <p className="content_md_regular text_fg_default">{value || '0.00'}</p>
  </Group>
)

const OrderDetailsModal = ({
  isVisible,
  handleOrderDetailsCloseModal,
  handleOrderHistoryModal,
  selectedOrderDetails,
}: OrderDetailsModalProps) => {
  const { isMobile } = useMediaCustom();

  // const isMobile = false
  const {
    price,
    quantity,
    averagePrice,
    order,
    product,
    retention,
    id,
    exchangeOrderID,
    timeStamp,
    exchangeUpdateTime,
    accountID,
    rejectReason,
    orderStatus,
    exchange,
    symbol,
    transactionType,
    token,
  } = selectedOrderDetails

  const instrumentName = getNiceName(token, exchange, symbol)

  return (
    <Modal
      onClose={handleOrderDetailsCloseModal}
      opened={isVisible}
      transitionProps={{ transition: 'fade', duration: 500, timingFunction: 'linear' }}
      size="lg"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Group position="apart" mb="sm">
        <Group position="apart">
          <Badge color={transactionType === "B" ? "blue" : "red"} radius="xs" variant="light">
            {buySellConst[transactionType!]}
          </Badge>
          <p className="heading_sm_semibold">{instrumentName || ''}</p>
          <p className="text_fg_muted content_2xs_regular">{exchange}</p>
        </Group>
        <Badge color={orderStatusColor[orderStatus! || "COMPLETE"]} radius="xs" variant="light">
          {orderStatus || 'Complete'}
        </Badge>
      </Group>
      <Divider />

      {/* modal data */}
      <Group position="apart" align="flex-start" mt="xl" mb="xl">
        <Box className="w-third">
          <OrderDetailListItem title="Quantity" value={quantity} />
          <OrderDetailListItem title="Price" value={price} />
          <OrderDetailListItem title="Avg. Price" value={averagePrice} />
          <OrderDetailListItem title="Trigger Price" value={0} />
          <OrderDetailListItem title="Order Type" value={order} />
          <OrderDetailListItem title="Product" value={productType[product!]} />
          <OrderDetailListItem title="Validity" value={retention} />
        </Box>
        <Box className="w-55-per">
          <OrderDetailListItem title="Order ID" value={id} />
          <OrderDetailListItem title="Exchange order ID" value={exchangeOrderID} />
          <OrderDetailListItem title="Time" value={timeStamp?.slice(0, 8)} />
          <OrderDetailListItem title="Date" value={timeStamp?.slice(8)} />
          <OrderDetailListItem title="Exchange time" value={exchangeUpdateTime} />
          <OrderDetailListItem title="Placed by" value={accountID} />
          <p className="content_md_regular text_error_default">{rejectReason}</p>
        </Box>
      </Group>

      {/* theme/error/default */}
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button variant="outline" onClick={handleOrderHistoryModal}>
          View History
        </Button>
        <Button
          variant="outline"
          color="gray"
          onClick={handleOrderDetailsCloseModal}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default memo(OrderDetailsModal)
