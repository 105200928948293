import { createSlice } from "@reduxjs/toolkit"
import type { IWatchlistsSlice } from "."

const initialState: IWatchlistsSlice = {
  userWatchList: {
    loading: false,
    isError: false,
    errMessage: "",
    data: [],
    status: null,
  },
  removeWLItem: {
    loading: false,
    isError: false,
    errMessage: "",
    status: null,
  },
  addWLItem: {
    loading: false,
    isError: false,
    errMessage: "",
    status: null,
    token: null,
  },
  updateSequence: {
    loading: false,
    isError: false,
    errMessage: "",
    status: null,
  },
  searchWatchlists: [],
  selectedWLIndex: 0,
}

export const watchlistsSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    getWatchlists: (state) => {
      state.userWatchList.loading = true
    },
    getWatchlistsSuccess: (state, action) => {
      state.userWatchList.loading = false
      state.userWatchList.data = action.payload.data
    },
    getWatchlistsFailure: (state, action) => {
      state.userWatchList.loading = false
      state.userWatchList.isError = action.payload.isError
      state.userWatchList.errMessage = action.payload.error
    },
    removeWlItem: (state, action) => {
      state.removeWLItem.loading = true
    },
    removeWlItemFailure: (state, action) => {
      state.removeWLItem.loading = false
      state.removeWLItem.isError = action.payload.isError
      state.removeWLItem.errMessage = action.payload.error
    },
    addWLItem: (state, action) => {
      state.addWLItem.loading = true
      state.addWLItem.status = null
      state.addWLItem.isError = false
      state.addWLItem.token = null
    },
    addWLItemFailure: (state, action) => {
      state.addWLItem.loading = false
      state.addWLItem.isError = action.payload.isError
      state.addWLItem.errMessage = action.payload.error
      state.addWLItem.token = action.payload.token
    },
    setSelectedWatchlistIndex: (state, action) => ({
      ...state,
      selectedWLIndex: action.payload,
    }),
    updateSequence: (state, action) => {
      state.updateSequence.loading = true
    },
    updateSequenceFailure: (state, action) => {
      state.updateSequence.loading = false
      state.updateSequence.isError = action.payload.isError
      state.updateSequence.errMessage = action.payload.error
    },
    // searchWatchlists: () => { },
    setSearchWatchlists: (state, action) => ({
      ...state,
      searchWatchlists: action.payload,
    }),
    resetWatchlistsSlice: () => initialState,
  },
})

// Action
export const {
  // watchlists,
  // setWatchlists,
  getWatchlists,
  getWatchlistsSuccess,
  getWatchlistsFailure,
  // searchWatchlists,
  setSearchWatchlists,
  addWLItem,
  addWLItemFailure,
  removeWlItem,
  removeWlItemFailure,
  setSelectedWatchlistIndex,
  updateSequence,
  updateSequenceFailure,

  resetWatchlistsSlice,
} = watchlistsSlice.actions

export default watchlistsSlice.reducer
