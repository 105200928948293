import { call, put } from "redux-saga/effects"
import {
  isDevelopment, removeCookiesOnLogout, setCookie,
} from "@utils/index"
import { clearLocalStorage } from "@redux/api/localStorage"
import type { IActionHandleLogin, IActionAppLoginGenToken } from "@hooks/handleApiCall"
import { SUCCESS } from "@utils/constant"
import {
  requestCaptcha,
  requestForgotPassword,
  requestLogin,
  requestLogoutUser,
  requestReloadCaptcha,
  requestResetPassword,
  requestValidateReset,
  requestValidate2fa,
  requestResendOtp,
  requestCreatePassword,
  requestSetup2fa,
  requestAppLoginGenerateToken,
} from "./authRequests"
import {
  loginUserFailure,
  loginUserSuccess,
  validate2faSuccess,
  validate2faFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  getCaptchaSuccess,
  getCaptchaFailure,
  reloadCaptchaSuccess,
  reloadCaptchaFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  validateResetSuccess,
  validateResetFailure,
  resendOtpSuccess,
  resendOtpFailure,
  logoutUserSuccess,
  logoutUserFailure,
  createPasswordSuccess,
  createPasswordFailure,
  setup2faSuccess,
  setup2faFailure,
  appLoginGenerateTokenSuccess,
  appLoginGenerateTokenFailure,
} from "./authSlice"

const setLoginCookie = (data: any) => {
  setCookie("session", data.session)
  setCookie("token", data.token)
}

// login user
export function* handleLogin(action: IActionHandleLogin): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestLogin, action.payload)
    if (isDevelopment()) {
      setLoginCookie(data)
    }
    yield put(loginUserSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      loginUserFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// resend otp
export function* handleResendOtp(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestResendOtp, action.payload)
    yield put(resendOtpSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      resendOtpFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// validate 2fa
export function* handleValidate2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestValidate2fa, action.payload)
    if (data.redirectUrl) {
      window.location.href = data.redirectUrl
      return
    }
    if (isDevelopment()) {
      setLoginCookie(data)
    }
    yield put(validate2faSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      validate2faFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// forgot user
export function* handleForgotPassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestForgotPassword, action.payload)
    yield put(forgotPasswordSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      forgotPasswordFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// get captch
export function* handleCaptcha(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCaptcha)
    yield put(getCaptchaSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getCaptchaFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle reload captcha
export function* handleReloadCaptcha(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestReloadCaptcha, { id: action.payload })
    yield put(reloadCaptchaSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(reloadCaptchaFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle reset password
export function* handleResetPassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestResetPassword, action.payload)
    yield put(resetPasswordSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      resetPasswordFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// handle validate reset
export function* handleValidateReset(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestValidateReset, action.payload)
    yield put(
      validateResetSuccess({ data, status })
    )
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      validateResetFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// logout user
export function* handleLogoutUser(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestLogoutUser, action.payload)
    removeCookiesOnLogout()
    clearLocalStorage()
    yield put(logoutUserSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      logoutUserFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// create password
export function* handleCreatePassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCreatePassword, action.payload)
    yield put(createPasswordSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      createPasswordFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

//  2fa setup
export function* handleSetup2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSetup2fa, action.payload)
    yield put(setup2faSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      setup2faFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

export function* handleAppLoginGenerateToken(action: IActionAppLoginGenToken): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestAppLoginGenerateToken, action.payload)
    if (status === SUCCESS) {
      yield put(appLoginGenerateTokenSuccess({ data, status }))
    }
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(appLoginGenerateTokenFailure({
      errorCode, message, status, errorData,
    }))
  }
}
