import { createSlice } from "@reduxjs/toolkit"
import { ActionSuccess, ActionFailure } from "@redux/types";
import {
  IChangePasswordRes,
  IEnable2faRes,
  IUserDetails,
  IUserLimits,
  IUserPreferences,
  IVerify2FaRes,
  UsersSlice,
} from "."

const initialState: UsersSlice = {
  details: {
    loading: false,
    status: '',
    data: null,
  },
  preferences: {
    loading: false,
    isError: false,
    errMessage: "",
    data: {
      chartType: "tradingview",
      instantOrderUpdates: true,
      pinnedInstruments: [{
        exchange: "NSE", segment: "", token: 26000, symbol: "NIFTY 50", expiry: 0,
      }, {
        exchange: "NSE", segment: "", token: 26009, symbol: "NIFTY BANK", expiry: 0,
      }],
      stickyOrderWindow: true,
      theme: "on",
    } as IUserPreferences,
    status: null,
  },
  limits: {
    loading: false,
    status: '',
    data: null,
  },
  updatePreferences: {
    loading: false,
    status: '',
    data: null,
  },
  addUserImage: {
    loading: false,
    status: '',
    data: null,
  },
  enable2Fa: {
    loading: false,
    status: '',
    data: null,
  },
  changePassword: {
    loading: false,
    status: '',
    data: null,
  },
  verify2Fa: {
    loading: false,
    status: '',
    data: null,
  },
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserDetails: (state) => {
      state.details.loading = true;
      state.details.status = "";
    },
    getUserDetailsSuccess: (state, action:ActionSuccess<IUserDetails>) => {
      const { payload } = action;
      state.details = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    getUserDetailsFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.details = {
        ...state.details,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    getUserLimits: (state) => {
      state.limits.loading = true;
      state.limits.status = "";
    },
    getUserLimitsSuccess: (state, action:ActionSuccess<IUserLimits>) => {
      const { payload } = action;
      state.limits = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    getUserLimitsFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.limits = {
        ...state.limits,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    // TODO this has to be changed as per the new types
    getUserPreferences: (state) => {
      state.preferences.loading = true
      state.preferences.isError = false
    },
    getUserPreferencesSuccess: (state, action) => {
      state.preferences.loading = false
      state.preferences.data = action.payload.data
      state.preferences.status = action.payload.status
    },
    getUserPreferencesFailure: (state, action) => {
      state.preferences.loading = false
      state.preferences.isError = action.payload.isError
      state.preferences.errMessage = action.payload.error
      state.preferences.status = action.payload.status
    },
    // TODO have to add success and failure functions here
    updateUserPreferences: (state, action) => {
      state.updatePreferences.loading = true;
      state.details.status = "";
    },
    // TODO have to add success and failure functions here
    addUserImage: (state, action) => {
      state.addUserImage.loading = true;
    },
    enable2Fa: (state, action) => {
      state.enable2Fa.loading = true;
      state.enable2Fa.status = "";
    },
    enable2FaSuccess: (state, action:ActionSuccess<IEnable2faRes>) => {
      const { payload } = action;
      state.enable2Fa = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    enable2FaFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.enable2Fa = {
        ...state.enable2Fa,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    verify2Fa: (state, action) => {
      state.verify2Fa.loading = true;
      state.details.status = "";
    },
    verify2FaSuccess: (state, action:ActionSuccess<IVerify2FaRes>) => {
      const { payload } = action;
      state.verify2Fa = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    verify2FaFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.verify2Fa = {
        ...state.verify2Fa,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    changePassword: (state, action) => {
      state.changePassword.loading = true;
      state.changePassword.status = "";
    },
    changePasswordSuccess: (state, action:ActionSuccess<IChangePasswordRes>) => {
      const { payload } = action;
      state.changePassword = {
        loading: false,
        status: payload.status,
        data: payload.data,
      };
    },
    changePasswordFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.changePassword = {
        ...state.changePassword,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },
    resetUsersSlice: () => initialState,
  },
});

// Action
export const {
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  getUserLimits,
  getUserLimitsSuccess,
  getUserLimitsFailure,
  getUserPreferences,
  getUserPreferencesSuccess,
  getUserPreferencesFailure,
  updateUserPreferences,
  addUserImage,

  enable2Fa,
  enable2FaSuccess,
  enable2FaFailure,
  verify2Fa,
  verify2FaSuccess,
  verify2FaFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,

  resetUsersSlice,
} = userSlice.actions

export default userSlice.reducer
