import { Box } from "@mantine/core"
import OrdersZeroState from "@components/zeroState/ordersZeroState"
import Loading from "@components/loading/loading"
import ErrorElement from "@components/errorElement"
import { tableType } from "@utils/constant"
import { memo } from "react"
import useRouteChange from "@hooks/common/useRouteChange"
import ErrorBoundary from "../../../ErrorBoundary"
import OrderTableList from "../executed/orderTableList"
import OpenOrderTable from "./openOrderTable"
import TradesTableList from "../trades/tradesTableList"

type OpenOrderTabProps = {
  ordersLoading: boolean;
  filteredOrdersData: any[];
  openOrdersData: any[];
  ordersIsError: boolean;
  ordersErrMessage: string;
  handleOrderDetailsModal: (item: any) => void;
  handleCancelOrder: (item: any) => void;
}

const OpenOrderTab = memo(({
  ordersLoading,
  filteredOrdersData,
  openOrdersData,
  ordersIsError,
  ordersErrMessage,
  handleOrderDetailsModal,
  handleCancelOrder,
}: OpenOrderTabProps) => {
  const { isNavigateLoading } = useRouteChange()
  const secondLoading = ordersLoading && isNavigateLoading && openOrdersData.length
    && filteredOrdersData.length

  if (secondLoading === 0 && ordersLoading
    && !openOrdersData.length
    && !filteredOrdersData.length) {
    return (
      <>
        <OrdersZeroState />
        <Loading />
      </>
    )
  }

  // if (ordersLoading && !filteredOrdersData.length && !openOrdersData.length) {
  //   return <Loading />
  // }
  if (ordersIsError && !openOrdersData.length && !filteredOrdersData.length) {
    return <ErrorElement component="Orders" message={ordersErrMessage} />
  }

  if (!openOrdersData?.length
    && !filteredOrdersData?.length
    && !ordersIsError
    && !ordersLoading) {
    return <OrdersZeroState />
  }
  // TODO handle partially executed case here
  const isAnyOrderCompleted = filteredOrdersData.some((item) => item.orderStatus === "COMPLETE" || item.fillShares > 0)

  return (
    (
      <Box>
        {openOrdersData.length ? (
          <ErrorBoundary>
            <OpenOrderTable
              title="Open Orders"
              data={openOrdersData}
              handleOrderDetailsModal={handleOrderDetailsModal}
              handleCancelOrder={handleCancelOrder}
              loading={Boolean(ordersLoading && openOrdersData.length)}
              hydratedData={[]}
              isNoInternet={!!(ordersIsError && openOrdersData.length)}
            />
          </ErrorBoundary>
        ) : null}

        {filteredOrdersData.length ? (
          <ErrorBoundary>
            <Box>
              <OrderTableList
                type={tableType.EXECUTED}
                title="Executed Orders"
                loading={ordersLoading && filteredOrdersData.length}
                data={filteredOrdersData}
                handleOrderDetailsModal={handleOrderDetailsModal}
                isNoInternet={!!(ordersIsError && filteredOrdersData.length)}
              />
            </Box>
          </ErrorBoundary>
        ) : null}

        {
          filteredOrdersData.length && isAnyOrderCompleted ? (
            <ErrorBoundary>
              <TradesTableList
                title="Trades"
                isCollapsible
              />
            </ErrorBoundary>
          ) : null
        }

      </Box>
    )

  )
})
export default OpenOrderTab
