import { Box } from "@mantine/core"
import {
  memo, useEffect, useRef, useState,
} from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { useDispatch, useSelector } from "react-redux"
import { setQuoteTokens } from "src/redux/sagas/sockets/socketsSlice"
import { IWatchlist, IWatchlistItem } from "src/redux/sagas/watchlists"
// import usePrevious from "@hooks/common/usePrevious"
// import { isEqual } from "@utils/index"
// import { showNotification } from "@mantine/notifications"
import { AppDispatch } from "@redux/store"
import { updateSequence, getWatchlistsSuccess } from "@redux/sagas/watchlists/watchlistsSlice"
import useWLEvents from "@hooks/events/useWLEvents"
import {
  selectSelectedWLIndex,
  // selectWLSearchData,
  selectWatchlistData,
} from "@redux/sagas/watchlists/watchlistSelectors"
import { selectFullSocket } from "@redux/sagas/sockets/socketSelectors"
import WatchlistHeader from "./watchlistHeader"
import WatchlistFooter from "./watchlistFooter"
import useStyles from "./styled"
import WatchlistItemContainer from "./watchlistItemContainer"
import WatchlistZeroState from "../zeroState/watchlistZeroState"

const Watchlist = () => {
  const { classes } = useStyles()
  const dispatch: AppDispatch = useDispatch()
  const searchRef = useRef<HTMLInputElement>(null)

  const container = document.createElement("div")
  document.body.appendChild(container)

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)
  const marketDepth = useSelector(selectFullSocket)
  // const { addWLItem: addWLItemData } = useSelector((state: RootState) => state.watchlist);
  // const searchWatchlists = useSelector(selectWLSearchData);

  // const prevProps = usePrevious({ addWLItemData })

  const selectedWatchlist = watchlistsData[selectedWLIndex || 0]
  // const [localWLsData, setLocalWLsData] = useState(watchlistsData)

  const handleFocusNull = () => {
    searchRef.current?.blur();
  }
  const selectedWLItemIndexRef = useRef(-1)

  const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1)

  const handleWLItemIndex = (value: number) => {
    setSelectedWLItemIndex(value)
    selectedWLItemIndexRef.current = value
  }

  // handling the keyboard click events
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useWLEvents({
    selectedWatchlist,
    handleWLItemIndex,
    selectedWLItemIndexRef,
  })

  // useEffect(() => {
  //   if (watchlistsData.length) {
  //     setLocalWLsData(watchlistsData)
  //   }
  // }, [watchlistsData])

  useEffect(() => {
    const subsObj: Record<string, number> = {};
    const unSubObj: Record<string, number> = {};

    watchlistsData[selectedWLIndex]?.items?.forEach((item: IWatchlistItem) => {
      // if (Date.now() - (item.expiry + 86400) * 1000 < 0 || item.expiry === 0) {
      subsObj[item.token] = 1
      unSubObj[item.token] = 0 // }
    })
    if (Object.keys(subsObj).length) {
      dispatch(setQuoteTokens(subsObj))
      // todo: this causing re-rendering of the whole page
    }
    return () => {
      dispatch(setQuoteTokens(unSubObj))
    }
  }, [watchlistsData, selectedWLIndex, dispatch])

  // useEffect(() => {
  //   if (!isEqual(prevProps?.addWLItemData.isError, addWLItemData.isError) && addWLItemData.isError) {
  //     showNotification({
  //       color: "red",
  //       title: addWLItemData.errMessage,
  //       // message: 'Watchlist Cannot add more than 50 instruments to a marketwatch.',
  //       message: 'Adding watchlist failed.',
  //     })
  //   }
  // }, [prevProps, addWLItemData])

  const handleAddInstrument = () => {
    searchRef?.current?.focus()
  }

  const watchlistItems = watchlistsData?.map((watchlistItem: IWatchlist) => {
    if (watchlistItem?.id === selectedWatchlist?.id) {
      return watchlistItem.items.length > 0 ? (
        <Box key={watchlistItem.id} className={classes.watchlistItems}>
          {watchlistItem.items?.map((item: IWatchlistItem, index: number) => (
            <WatchlistItemContainer
              key={item.token}
              item={item}
              index={index}
              selectedWLItemIndex={selectedWLItemIndex}
              marketDepth={marketDepth?.token === item.token ? marketDepth : {}}
            />
          ))}
        </Box>
      ) : (
        <WatchlistZeroState key={watchlistItem.id} handleAddInstrument={handleAddInstrument} />
      )
    }
    return null
  })

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragOrder = (destination: any, source: any) => {
    const reorderedSubItems = reorder(
      selectedWatchlist?.items,
      source.index,
      destination?.index
    )
    const apiData = reorderedSubItems.reduce((acc: any, elem: any, index) => {
      acc[elem?.id] = index + 1 // or what ever object you want inside
      return acc
    }, {})
    dispatch(
      updateSequence({
        data: apiData,
        mwId: watchlistsData[selectedWLIndex]?.id,
      })
    )
    let newItems = watchlistsData
    newItems = newItems.map((item: any) => {
      const objCopy = { ...item }
      if (item.id === selectedWatchlist?.id) {
        objCopy.items = reorderedSubItems
      }
      return objCopy
    })
    // setLocalWLsData(newItems)
    dispatch(getWatchlistsSuccess({ data: newItems }))
  }

  return (
    <Box
      id="wl-container"
      className={classes.watchlistContainer}
    >
      <WatchlistHeader
        ref={searchRef}
        handleFocusNull={handleFocusNull}
        handleWLItemIndex={handleWLItemIndex}
      />
      <div
        className={classes.scrollY}
      >
        <DragDropContext
          onDragEnd={({ destination, source }) => handleDragOrder(destination, source)}
        >
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {watchlistItems}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <WatchlistFooter />
    </Box>
  );
}

export default memo(Watchlist)
