import { Box } from "@mantine/core"
import { ReactNode, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setQuoteTokens } from "src/redux/sagas/sockets/socketsSlice"
import { bounceAnimation } from "src/utils"
import { RootState } from "src/redux/store"
import useRouteChange from "@hooks/common/useRouteChange"
import PositionZeroState from "@components/zeroState/positionZeroState"
import ErrorElement from "@components/errorElement"
import Loading from "@components/loading/loading"
import { getUserPositions } from "@redux/sagas/positions/positionsSlice"
import useMediaCustom from "@hooks/common/useMediaCustom"
import PositionDetails from "@components/modals/position/positionDetails"
import { IExtendedPositionItem } from "@redux/sagas/positions"
import ConvertPostion from "@components/modals/position/convertPosition"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import { FAILURE } from "@utils/constant"
import PositionTableList from "./positionTableList"

const PositionContainer = ({ children, loading = false }: { loading?: boolean, children: ReactNode }) => {
  const { isLargeScreen } = useMediaCustom();
  return <Box sx={loading ? bounceAnimation : {}} p={isLargeScreen ? "lg" : "sm"}>{children}</Box>
}

const Positions = () => {
  const dispatch = useDispatch()
  const { isNavigateLoading } = useRouteChange()

  const [showPostionDetails, setShowPostionDetails] = useState(false)
  const [showConvertPosition, setShowConvertPosition] = useState(false)
  const [selectedPositionDetails, setSelectedPositionDetails] = useState<IExtendedPositionItem>(
    {} as IExtendedPositionItem
  )

  const positionsState = useSelector((state: RootState) => state.position.positions)
  const { status, data, loading } = positionsState
  useEffect(() => {
    dispatch(getUserPositions())
  }, [])

  useEffect(() => {
    const subsObj: Record<string, number> = {};
    const unSubObj: Record<string, number> = {};
    if (data) {
      data.forEach((item) => {
        subsObj[item.token] = 1
        unSubObj[item.token] = 0
      })
    }
    dispatch(setQuoteTokens(subsObj))
    return () => {
      dispatch(setQuoteTokens(unSubObj))
    }
  }, [data])

  const handlePositionDetails = (item: IExtendedPositionItem) => {
    setShowPostionDetails(true)
    setSelectedPositionDetails(item)
  }

  const handlePositionDetailsClose = () => {
    setShowPostionDetails(false)
  }

  const handleConvertPosition = (item: IExtendedPositionItem) => {
    setSelectedPositionDetails(item)
    setShowConvertPosition(true);
  }

  const handleCloseConvertPosition = () => {
    setShowConvertPosition(false);
  }

  const secondLoading = (loading && isNavigateLoading && data && data.length)

  return (
    <RenderBoundary
      loading={loading}
      status={status}
      data={data}
      errorState={(
        <PositionContainer>
          <ErrorElement component="Positions" message={status === FAILURE && positionsState.message} />
        </PositionContainer>
      )}
      customLoader={(
        <PositionContainer>
          <Loading />
        </PositionContainer>
      )}
    >
      {
        data && data.length === 0 ? (
          // zero state
          <PositionContainer>
            <PositionZeroState title="You don't have any positions yet" />
            {loading && <Loading />}
          </PositionContainer>
        ) : (
          // data state
          <PositionContainer loading={Boolean(secondLoading)}>
            <PositionTableList
              title="Positions"
              data={data!}
              loading={secondLoading || false}
              hydratedData={[]}
              isNoInternet={false}
          // isNoInternet={!!(positionsIsError && positionsData.length)}
              handlePositionDetails={handlePositionDetails}
              handleConvertPosition={handleConvertPosition}
              showDisabledState
            />
            <Box mt={45}>
              <PositionTableList
                hideCheckbox
                title="Day's history"
                data={data!}
                loading={secondLoading || false}
                hydratedData={[]}
                isNoInternet={false}
            // isNoInternet={!!(positionsIsError && positionsData.length)}
                handlePositionDetails={handlePositionDetails}
                handleConvertPosition={handleConvertPosition}
                showDisabledState={false}
              />
            </Box>
            {showPostionDetails ? (
              <PositionDetails
                isVisible={showPostionDetails}
                handlePositionDetailsClose={handlePositionDetailsClose}
                selectedPositionDetails={selectedPositionDetails}
                handleConvertPosition={handleConvertPosition}
              />
            ) : null}

            {showConvertPosition ? (
              <ConvertPostion
                isVisible={showConvertPosition}
                handleCloseConvertPosition={handleCloseConvertPosition}
                selectedPositionDetails={selectedPositionDetails}
              />
            ) : null}
          </PositionContainer>
        )
      }

    </RenderBoundary>

  )
}

export default Positions
