import { createStyles, Grid, Text } from '@mantine/core'
import { formatNumber } from 'src/utils';
import Flex from '@components/atoms/flex/flex'

const useStyles = createStyles((theme) => ({
  greenColor: {
    color: theme.colorScheme === "dark" ? theme.colors?.colorSuccess[0] : theme.colors.colorSuccess[1],
  },
  redColor: {
    color: theme.colorScheme === "dark" ? theme.colors.colorDanger[0] : theme.colors.colorDanger[1],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const HoldingCalc = ({ title, value }: { title: string, value: number }) => {
  const { classes } = useStyles()
  let className = "color-grey-2"
  if (Number(value) === 0) {
    className = "color-grey-2"
  } else if (value > 0) {
    className = classes.greenColor
  } else {
    className = classes.redColor
  }
  return (
    <Grid.Col md={6} lg={3} className={classes.wrapper}>
      <Text c="dimmed" fz="xs">{title}</Text>
      <Flex align="baseline">
        <Text
          fz={28}
          className={className}
        >
          {formatNumber(value)}
        </Text>
        {/* <Text fz="xs">.00</Text> */}
      </Flex>
    </Grid.Col>
  )
}

export default HoldingCalc
