import { showNotification } from "@mantine/notifications"
import { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { RootState } from "src/redux/store"
import { isEqual } from "src/utils"
import { ErrorFallbackMsg, FAILURE, SUCCESS } from "src/utils/constant"
import {
  forgotPassword,
  getCaptcha,
} from "@redux/sagas/auth/authSlice"
import usePrevious from "../common/usePrevious"

const useForgotPassword = (form: any, otpType: string) => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const { forgot } = useSelector(
    (state: RootState) => state.auth
  )

  const prevProps = usePrevious({ forgot })

  const handleForgotPassword = (payload) => {
    dispatch(forgotPassword(payload))
  }

  const handleResetCaptcha = useCallback(
    () => {
      form.setValues({ captchaValue: '' });
      if (forgot.status === FAILURE) {
        if (forgot.message === "user not found") {
          form.setValues({ userId: '' });
        }
        if (forgot.message === "PAN is incorrect") {
          form.setValues({ pan: '' });
        }
      }
    },
    [form]
  )

  useEffect(() => {
    if (!isEqual(prevProps.forgot.status, forgot.status) && forgot.status === FAILURE) {
      dispatch(getCaptcha())
      showNotification({
        color: "red",
        title: forgot.message || ErrorFallbackMsg,
        message: "",
      })
      handleResetCaptcha()
    } else if (!isEqual(prevProps.forgot.status, forgot.status) && forgot.status === SUCCESS) {
      setLocation(`/validate?requestId=${forgot.data && forgot.data.requestID}&otpType=${otpType}`)
    }
  }, [prevProps, forgot])

  return { handleForgotPassword }
}

export default useForgotPassword
