// watchlistheader.tsx
import {
  useCallback, useEffect, useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchWLItemProps } from "src/components/watchlist/index.d";
import { IWatchlist } from "src/redux/sagas/watchlists";
import { selectSelectedWLIndex, selectWatchlistData } from "src/redux/sagas/watchlists/watchlistSelectors";
import { addWLItem, setSearchWatchlists } from "src/redux/sagas/watchlists/watchlistsSlice";
import type { RootState } from "src/redux/store";

const useWLSearch = (
  handleSearch: (val: string) => void,
  handleFocusNull: () => void,
  handleWLItemIndex: (value: number) => void,
  selectedSearchWLIndexRef: React.MutableRefObject<number>,
  handleSearchWLItemIndex: (value: number) => void
) => {
  const dispatch = useDispatch();

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)
  const { searchWatchlists } = useSelector((state: RootState) => state.watchlist);

  const selectedWatchlist = watchlistsData[selectedWLIndex || 0]

  // const selectedSearchWLIndexRef = useRef(-1);
  const tempSearchWatchlists = useRef<SearchWLItemProps[] | IWatchlist[]>([]);
  const selectedWatchlistRef = useRef(selectedWatchlist)

  // const [selectedWLItemIndex, setSelectedWLItemIndex] = useState(-1)

  // const handleSearchWLItemIndex = (value: number) => {
  //   setSelectedWLItemIndex(value);
  //   selectedSearchWLIndexRef.current = value;
  // };

  const handleAddWatchlistItem = async (assetItem) => {
    const payload = {
      exchange: assetItem.exchange,
      segment: assetItem.segment,
      token: assetItem.exchangeToken,
      symbol: assetItem.tradingSymbol,
      sequence: selectedWatchlistRef.current.items.length + 1,
      // expiry: assetItem.expiry,
    };
    await dispatch(
      addWLItem({
        data: payload,
        mwId: selectedWatchlistRef?.current.id,
      })
    );
    dispatch(setSearchWatchlists([]));
    handleSearch("")
    handleFocusNull()

    // resetting wl item index
    handleWLItemIndex(0)
  };

  // handling watchlist search result keyboard shortcut
  const memoized = useCallback(
    () => {
      document.addEventListener(
        "keydown",
        (event) => {
          const name = event.key;
          const tempSelectedWlIndex = selectedSearchWLIndexRef?.current;

          switch (name) {
            case "Escape":
              handleSearch("")
              return;
            case "ArrowDown":
              if (
                tempSelectedWlIndex
                === tempSearchWatchlists.current.length - 1
              ) {
                handleSearchWLItemIndex(0);
              } else {
                handleSearchWLItemIndex(tempSelectedWlIndex + 1);
              }
              return;
            case "ArrowUp":
              if (tempSelectedWlIndex === 0) {
                handleSearchWLItemIndex(tempSearchWatchlists.current.length - 1);
              } else {
                handleSearchWLItemIndex(tempSelectedWlIndex - 1);
              }
              return;
            case "Enter": {
              const tempSelectedWLData = tempSearchWatchlists.current[tempSelectedWlIndex] || {};
              if (Object.keys(tempSelectedWLData)?.length) {
                handleAddWatchlistItem(tempSelectedWLData);
              }
              break;
            }
            default:
              break;
          }
        },
        false
      );
    },
    []
  );

  useEffect(() => {
    memoized();
  }, []);

  // to hold the search result data to use in events
  useEffect(() => {
    tempSearchWatchlists.current = searchWatchlists;
  }, [searchWatchlists]);

  useEffect(() => {
    selectedWatchlistRef.current = selectedWatchlist
  }, [selectedWatchlist])

  return { unusedVal: 9 }
}

export default useWLSearch
