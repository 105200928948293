/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
} from "@mantine/core"
import { tableUseStyles } from "src/components/table/styled"
import {
  Table, SortDirection, AutoSizer, WindowScroller,
} from 'react-virtualized';
import {
  useCallback, useEffect, useRef, useState,
} from "react";
import { HoldingItem } from "@redux/sagas/holdings/holdingsTypes";
import { formatSocket } from "@utils/index";
import PnLCell from "@components/table/pnLCell";
import useCSVDownload from "@hooks/common/useCSVDownload";
import TitleHeading from "@components/atoms/titleHeading";
import NotFoundState from "@components/zeroState/NotFoundZeroState";
// import usePrevious from '@hooks/common/usePrevious';
import { RootState } from "@redux/store";
import { connect } from "react-redux";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import { tableContainer } from "@utils/constant";
import HoldingIntrument from "./holdingIntrument";

type HydratedData = HoldingItem & {
  ltp: number,
  currentVal: number
  pnl: number
  netChg: number
  dayChg: number
};

type IHoldingTableList = {
  title: string;
  data: HoldingItem[];
  loading: boolean
  hydratedData: HydratedData[]
  isNoInternet: boolean
};

const HoldingTableList = ({
  title,
  loading,
  hydratedData,
  isNoInternet,
}: Omit<IHoldingTableList, "data">) => {
  const { theme } = tableUseStyles();

  const [sortedData, setSortedData] = useState<HoldingItem[]>(hydratedData);
  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [searchVal, setSearchVal] = useState("");
  // const prevProps = usePrevious({ data });

  // useEffect(() => {
  //   if ((sortedData.length === 0 && hydratedData.length) || !isEqual(hydratedData, prevProps.data)) {
  //     if (sortInfo.sortBy !== '') {
  //       sortData();
  //     } else {
  //       setSortedData(Array.from(hydratedData));
  //     }
  //   }
  // }, [hydratedData]);

  const { downloadCSV } = useCSVDownload(
    ["symbol", "effectiveQty", "avgPrice", "ltp", "currentVal", "pnl", "netChg", "dayChg"],
    "holdings"
  );

  const handleSearchChange = useCallback((e) => {
    const { value } = e.target;
    setSearchVal(value);
  }, []);

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = hydratedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
      setSortedData(tempSearchData)
    } else {
      setSortedData(hydratedData)
    }
  }, [hydratedData, searchVal])

  const tableClassName = sortedData.length < 10 ? "table-min-height" : ""
  const tableData = useRef<HoldingItem[]>([]);

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    tableData.current = tempSortedData
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  if (searchVal.length) {
    tableData.current = sortedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
  } else if (!sortInfo.sortBy) {
    tableData.current = sortedData
  }

  // Return the data for a given row index
  const rowGetter = ({ index }) => tableData.current[index];

  const headerRowRenderer = () => (
    <section className="table-header-row sticky">
      <TableHeaderItem
        width={200}
        label="Instruments"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
        className="pl-10"
      />
      <TableHeaderItem
        width={105}
        label="Qty"
        value="effectiveQty"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={130}
        label="Avg. Cost"
        value="avgPrice"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={120}
        label="Cur. Val"
        value="currentVal"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={120}
        label="P&L"
        value="pnl"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="Net Chg."
        value="netChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={105}
        label="Day Chg."
        value="dayChg"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = tableData.current[index];
    if (row) {
      return (
        <div key={key} style={style} className="table-row">
          <HoldingIntrument row={row} />
          <TableItem width={105} value={row.effectiveQty} />
          <TableItem width={130} value={row.avgPrice.toFixed(2)} />
          <TableItem width={105} value={row.ltp.toFixed(2)} />
          <TableItem width={120} value={row.currentVal.toFixed(2)} />
          <TableItem
            width={120}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.pnl}
                value={row.pnl.toFixed(2)}
              />
            )}
          />
          <TableItem
            width={105}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.netChg}
                value={row.netChg.toFixed(2)}
              />
            )}
          />
          <TableItem
            width={105}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.dayChg}
                value={row.dayChg.toFixed(2)}
                showPercentage
              />
            )}
            className="pr-10"
          />
        </div>
      );
    }
    return null
  };

  return (
    <Box mt={12} mb={32} sx={{ minHeight: 350 }}>
      <TitleHeading
        title={title}
        length={sortedData.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={Boolean(loading)}
        secondaryTitle="Authorization"
        isNoInternet={isNoInternet}
      />

      {tableData.current.length ? (
        <section>
          <WindowScroller>
            {({
              height, isScrolling, onChildScroll, scrollTop,
            }) => (
              <AutoSizer style={tableContainer} disableHeight>
                {({ width }) => (
                  <Table
                    className={tableClassName}
                    autoHeight
                    width={width}
                    height={height}
                    rowCount={tableData.current.length}
                    rowGetter={rowGetter}
                    rowHeight={50}
                    rowRenderer={rowRenderer}
                    headerRowRenderer={headerRowRenderer}
                    scrollTop={scrollTop}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        </section>
      ) : <NotFoundState />}
    </Box>
  )
}

const mapStateToProps = (state: RootState, ownProps: IHoldingTableList) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    const {
      effectiveQty, avgPrice,
    } = item;
    const {
      ltp = 0,
      pnl = 0,
      netChg = 0,
      currentVal = 0,
    } = formatSocket(data, effectiveQty, avgPrice)
    return {
      ...item,
      ltp,
      currentVal,
      pnl,
      netChg,
      dayChg: Number(data.netChange || 0.00),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(HoldingTableList)
