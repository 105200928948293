import {
  TextInput,
  Button,
  Group,
  Anchor,
  Radio,
  Text,
  createStyles,
  Box,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "wouter"
import { useLocalStorage } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { RootState } from "src/redux/store"
import usePrevious from "src/hooks/common/usePrevious"
import useForgotPassword from "@hooks/handleApiCall/useForgotPassword"
import { OTP_PREFERENCE, FAILURE, SUCCESS } from "@utils/constant"
import {
  capitalizeFirstLetter, isEqual, validatePan,
} from "@utils/index"
import Logo from "@components/logo"
import CaptchaContainer from "./CaptchaContainer"

const onboardingUrl = process.env.REACT_APP_ONBOARDING_URL

const useStyles = createStyles(() => ({
  upperCasing: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  outerBox: {
    display: "flex",
    margin: "20px auto 10px auto",
    width: "65%",
    height: '65px',
  },
  innerBox: {
    width: "100%",
  },
}))

const Forgot = () => {
  const { classes } = useStyles()

  const [value] = useLocalStorage({
    key: "userId",
  })

  const captchaData = useSelector(
    (state: RootState) => state.auth.captcha
  )
  const { loading } = useSelector(
    (state: RootState) => state.auth.forgot
  )

  const prevProps = usePrevious({ captchaData })

  const [otpType, setOtpType] = useState(OTP_PREFERENCE.EMAIL)

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      userId: "",
      pan: "",
      captchaId: "",
      captchaValue: "",
      otpPreference: "",
    },

    validate: {
      userId: (val) => (val.length < 6 ? "User Id must have at least 6 letters" : null),
      pan: (val) => validatePan(val),
      captchaValue: (val) => (val.length === 6 ? null : "Enter 6 digit captcha"),
    },
  })

  useEffect(() => {
    form.setFieldValue('userId', value || '');
  }, [value])

  useEffect(() => {
    if (!isEqual(prevProps.captchaData.status, captchaData.status) && captchaData.status === FAILURE) {
      showNotification({
        color: "orange",
        title: capitalizeFirstLetter(captchaData.message),
        message: '',
      })
    }
  }, [captchaData, prevProps])

  const { handleForgotPassword } = useForgotPassword(form, otpType.toLowerCase())

  const handleSubmit = async (val: any) => {
    let captchaId = ''
    if (captchaData.status === SUCCESS && captchaData.data) {
      captchaId = captchaData.data.id;
    }
    const payload = { ...val, captchaId, otpPreference: otpType };
    handleForgotPassword(payload)
  }

  const handleInput = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9]+$/;
    const inputValue = event.currentTarget.value;
    if (!regex.test(inputValue)) {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.value = inputValue.replace(/[^A-Za-z0-9]/g, "");
    }
  }, []);

  // useEffect(() => {
  //   dispatch(getCaptcha())
  // }, [dispatch])

  // const handleReloadCaptcha = () => {
  //   dispatch(reloadCaptcha(captchaData.data.id))
  // }

  // const handleCaptchaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (allowOnlyNumber(e.target.value)) {
  //     form.setFieldValue("captchaValue", e.target.value)
  //   }
  // }
  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Forgot Password</h2>
      </div>
      <Box p={16}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="USER ID"
            className={classes.upperCasing}
            placeholder="AB1234"
            {...form.getInputProps("userId")}
            maxLength={6}
            onInput={handleInput}
          />
          <TextInput
            className={classes.upperCasing}
            label="PAN"
            placeholder="PAN"
            maxLength={10}
            mt="md"
            onInput={handleInput}
            {...form.getInputProps("pan")}
          />

          <Radio.Group
            mt="md"
            value={otpType}
            onChange={setOtpType}
            mb="md"
            size="xs"
            className="forgot-radio"
          >
            <Text>Receive on</Text>
            <Group mt="xs">
              <Radio value={OTP_PREFERENCE.EMAIL} label="E-mail" />
              <Radio value={OTP_PREFERENCE.SMS} label="SMS" />
            </Group>
          </Radio.Group>

          <CaptchaContainer form={form} />

          <Button
            aria-label="reset"
            fullWidth
            mt="xl"
            type="submit"
            loading={loading}
          >
            Reset
          </Button>

          <Group position="center" mt="lg">
            <Anchor component={Link} to="/" size="sm">
              Back to login
            </Anchor>
          </Group>
        </form>
      </Box>
      <Group position="center" mt="lg">
        <Anchor<"a"> target="_blank" href={onboardingUrl} size="sm">
          Don&apos;t have an account? Signup now!
        </Anchor>
      </Group>
    </div>
  )
}

export default Forgot
