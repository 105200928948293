import {
  Box,
  Group,
  Radio,
  Switch,
  Text,
  createStyles,
} from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import { memo, useEffect, useState } from "react"
import useGetSocketVal from "src/hooks/common/useGetSocketVal"
import { RootState } from "src/redux/store"
import { ICurrentAsset } from "src/redux/sagas/trades"
import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice"
import Flex from "@components/atoms/flex/flex"
import { exchanges } from "../../../utils/constant"
import FormattedName from "../formattedName"

const useStyles = createStyles(() => ({
  qtyStyle: {
    minWidth: 8,
    display: "inline-block",
    marginLeft: 4,
    marginRight: 4,
  },
  headerInner: {
    height: 24,
    paddingTop: 6,
  },
}))

interface ITradeHeader {
  currentAssetItem: ICurrentAsset
  selectedExchange: string
  setSelectedExchange: React.Dispatch<React.SetStateAction<string>>
  quantity: number
  setLtp: React.Dispatch<React.SetStateAction<number>>
  ltpVal: number
}

const TradeHeader = ({
  currentAssetItem,
  selectedExchange,
  setSelectedExchange,
  quantity,
  setLtp,
  ltpVal,
}: ITradeHeader) => {
  const { classes } = useStyles()

  const dispatch = useDispatch();

  const {
    tradeModalInfo,
  } = useSelector((state: RootState) => state.trade);

  const { buySellToggle } = tradeModalInfo

  const { ltp } = useGetSocketVal(currentAssetItem?.token || 0)

  const [firstTimeLtp, setFirstTimeLtp] = useState(false);

  useEffect(() => {
    if (!Number.isNaN(ltp) && !firstTimeLtp && ltp !== 0 && ltp !== ltpVal) {
      setFirstTimeLtp(true)
      setLtp(ltp)
    }
  }, [setLtp, ltp])

  // useEffect(() => {
  //   if (!Number.isNaN(ltp) && ltp !== 0) {
  //     setLtp(ltp)
  //   } else {
  //     setLtp(0)
  //   }
  // }, [setLtp, ltp])

  const handleBuySellToggle = (isBuy: boolean) => {
    dispatch(
      setTradeModalInfo({
        ...tradeModalInfo,
        buySellToggle: isBuy,
      })
    );
  }

  return (
    <Group position="apart" align="center">
      <Box className="heading_sm_semibold">
        <Flex align="baseline" className="heading_2xs_semibold">
          {buySellToggle ? 'Sell' : 'Buy'}
          {' '}
          <div className="mx-1">
            <FormattedName
              name={currentAssetItem.niceName}
              expiryDay={currentAssetItem.expiryDay}
              isWeekly={currentAssetItem.isWeekly}
              symbol={currentAssetItem.symbol}
            />
          </div>
          {' '}
          x
          {' '}
          <span className={classes.qtyStyle}>
            {quantity}
          </span>
          {' '}
          Qty
        </Flex>

        <div className={classes.headerInner}>
          {currentAssetItem.exchange !== exchanges.NFO ? (
            <Radio.Group
              label=""
              size="xs"
              value={selectedExchange}
              onChange={setSelectedExchange}
              className="trade-header"
            >
              <Radio
                value={exchanges.NSE}
                label={(
                  <span>
                    {currentAssetItem.exchange || exchanges.NSE}
                    {' '}
                    ₹
                    <span className="changing-text">{(ltp).toFixed(2)}</span>
                  </span>
                )}
                color={buySellToggle ? "red" : 'blue'}
              />
              {/* <Radio
            value={exchanges.BSE}
            label={<span>BSE ₹<span className="changing-text">{((ltp + 0.75)).toFixed(2)}</span></span>}
            color={buySellToggle ? "red" : 'blue'}
          /> */}
            </Radio.Group>
          ) : <Text className="content_sm_bold">N/A</Text>}
        </div>
      </Box>
      <Switch
        radius="md"
        className="buy-sell-toggle"
        checked={buySellToggle}
        onChange={(event) => handleBuySellToggle(event.currentTarget.checked)}
      />
    </Group>
  )
}

export default memo(TradeHeader)
