import {
  Box, Button, CopyButton, createStyles, Text, TextInput, Tooltip,
} from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Flex from '@components/atoms/flex/flex';
import { setup2fa } from '@redux/sagas/auth/authSlice';
import { RootState } from '@redux/store';
import { showNotification } from "@mantine/notifications"
import { useLocation } from "wouter"
import Logo from "@components/logo"
import { capitalizeFirstLetter } from '@utils/index';
import { SUCCESS } from "@utils/constant";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    dataWrapper: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    barcodeImage: {
      height: 200,
      width: 200,
      margin: 'auto',
    },
    inputDetailsWrapper: {
      width: '100%',
      marginTop: 16,
    },
    secretInput: {
      background: colors.bg.muted,
      padding: "5px 10px",
      marginTop: "10px",
      fontSize: '10px',
      border: 'none',
      outline: 'none',
      width: '100%',
    },
  }
});

const Setup2Fa = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const { location } = window
  const searchParams = new URLSearchParams(location.search)
  const requestId = searchParams.get("requestId")
  const setup2faState = useSelector((state: RootState) => state.auth.setup2fa);
  // API Response from create password
  const createPasswordData = useSelector((state: RootState) => state.auth.createPassword);
  // API Response from reset password
  const resetPasswordData = useSelector((state: RootState) => state.auth.reset);
  let enable2FaData : any = requestId
    ? resetPasswordData
    : createPasswordData.data;
  const [totpVal, setTotpVal] = useState('');
  if (
    createPasswordData.status === SUCCESS
    || resetPasswordData.status === SUCCESS
  ) {
    enable2FaData = requestId ? resetPasswordData.data : createPasswordData.data;
  }
  const handleVerifyTOTP = () => {
    let requestIdDerived;
    if (enable2FaData) {
      requestIdDerived = enable2FaData;
    }
    dispatch(
      setup2fa({
        totp: totpVal,
        requestId: requestId || requestIdDerived,
      })
    );
  }
  const handleTOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTotpVal(value);
  }
  useEffect(() => {
    if (setup2faState.status === SUCCESS) {
      setLocation('/')
      showNotification({
        color: "green",
        title: 'TOTP set successfully',
        message: '',
      })
    }
    if (setup2faState.status === 'failure') {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(setup2faState.message) || 'Something went wrong',
        message: '',
      })
    }
  }, [setup2faState.status])

  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Enable 2Factor Authentication</h2>
      </div>
      <div>
        <p className="text_fg_subtle content_sm_regular">
          Scan the QR code on your authenticator app, Once scanned, the app should give you a 6 digit TOTP.
          Enter it here. Need help?
        </p>
        <div className={classes.dataWrapper}>
          {
            enable2FaData ? (
              <div>
                <img className={classes.barcodeImage} src={`data:image/png;base64,${enable2FaData.image}`} alt="doc" />
                <Box>
                  <CopyButton value={enable2FaData.key} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <Text
                          align="center"
                          size={12}
                          className="text_primary_default"
                          onClick={copy}
                          sx={{ cursor: 'pointer' }}
                          pt={6}
                        >
                          Can&apos;t scan? Copy the key.
                        </Text>
                      </Tooltip>
                    )}
                  </CopyButton>
                  <input className={classes.secretInput} value={enable2FaData.key} />
                </Box>
              </div>
              // TODO add a state here incase of no data or user refresh
            ) : null
          }
          <div className={classes.inputDetailsWrapper}>
            <TextInput
              label="Enter 6 digit TOTP"
              id="totp-input"
              withAsterisk
              type="text"
              placeholder="6 digit TOTP"
              maxLength={6}
              value={totpVal}
              inputMode="numeric"
              pattern="[0-9]*"
              width="100%"
              onChange={handleTOTPChange}
            />
            <Flex position="space-between">
              <Button fullWidth onClick={handleVerifyTOTP} mt={10} mb={10} loading={setup2faState.loading}>
                Enable
              </Button>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setup2Fa
