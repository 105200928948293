import { showNotification } from "@mantine/notifications"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { RootState } from "src/redux/store"
import { isEqual } from "src/utils"
import { validateReset } from "@redux/sagas/auth/authSlice"
import { SUCCESS } from "@utils/constant"
import usePrevious from "../common/usePrevious"

interface OtpPayloadProps {
  otp: string
  requestId: string
}

const useValidateOtp = () => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const validateResetData = useSelector(
    (state: RootState) => state.auth.validateReset
  )

  const prevProps = usePrevious({ validateResetData })

  const handleValidateOtp = (payload: OtpPayloadProps) => {
    dispatch(validateReset(payload))
  }

  useEffect(() => {
    if (!isEqual(prevProps.validateResetData.status, validateResetData.status)
        && validateResetData.status === 'failure') {
      showNotification({
        color: "red",
        title: validateResetData.message || "Something went wrong",
        message: "",
      })
    } else if (
      !isEqual(prevProps.validateResetData.status, validateResetData.status) && validateResetData.status === SUCCESS
    ) {
      showNotification({
        color: "green",
        title: "OTP validated successfully",
        message: "",
      })
      setLocation(`/reset?requestId=${validateResetData.data && validateResetData.data.requestID}`)
    }
  }, [prevProps, validateResetData])

  return { handleValidateOtp }
}

export default useValidateOtp
