import { takeLatest } from "redux-saga/effects"

import {
  handleCancelRegularOrder,
  handleDeleteGttOrder,
  handleEditOcoOrder,
  handleEditSingleOrder,
  handleGttOcoOrder,
  handleGttOrderDetails,
  handleGttSingleOrder,
  handleOrderHistory,
  handleUserOrders,
  handleUserTrades,
} from "./ordersHandlers"

import {
  cancelRegularOrder,
  editGttOcoOrder,
  editGttSingleOrder,
  gttDetails,
  gttOcoOrder,
  gttSingleOrder,
  getOrderHistory,
  getUserOrders,
  getUserTrades,
  deleteGttOrder,
} from "./ordersSlice"

export function* userOrdersSaga() {
  yield takeLatest(getUserOrders.type, handleUserOrders)
}

export function* orderHistorySaga() {
  yield takeLatest(getOrderHistory.type, handleOrderHistory)
}

export function* cancelRegularOrderSaga() {
  yield takeLatest(cancelRegularOrder.type, handleCancelRegularOrder)
}

export function* userTradesSaga() {
  yield takeLatest(getUserTrades.type, handleUserTrades)
}

export function* gttOcoOrderSaga() {
  yield takeLatest(gttOcoOrder.type, handleGttOcoOrder)
}

export function* editGttOcoOrderSaga() {
  yield takeLatest(editGttOcoOrder.type, handleEditOcoOrder)
}

export function* gttSingleOrderSaga() {
  yield takeLatest(gttSingleOrder.type, handleGttSingleOrder)
}

export function* editGttSingleOrderSaga() {
  yield takeLatest(editGttSingleOrder.type, handleEditSingleOrder)
}

export function* gttOrderDetailsSaga() {
  yield takeLatest(gttDetails.type, handleGttOrderDetails)
}

export function* deleteGttOrderSaga() {
  yield takeLatest(deleteGttOrder.type, handleDeleteGttOrder)
}
