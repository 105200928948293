import {
  Group,
  Anchor,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFocusTrap, useLocalStorage } from "@mantine/hooks";
import {
  useEffect, useRef, useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "wouter";
import useHandleLogin from "@hooks/handleApiCall/useHandleLogin";
import type { RootState } from '@redux/store';
import usePrevious from "@hooks/common/usePrevious";
import { getCaptcha } from "@redux/sagas/auth/authSlice";
import Logo from "@components/logo"
import { FAILURE, SUCCESS } from "@utils/constant";
import {
  isDevelopment, isEqual, parseQuery,
} from "../../utils";
import AccountBlocked from "./accountBlocked";
import TotpInput from "./totpInput";
import LoginInput from "./loginInput";

const Login = () => {
  const focusTrapRef = useFocusTrap();
  const dispatch = useDispatch();
  const { login } = useSelector((state: RootState) => state.auth);
  const captchaData = useSelector(
    (state: RootState) => state.auth.captcha
  )
  const prevProps = usePrevious({ login })

  const [validate2faRequestId, setValidate2faRequestId] = useState('');
  const [existedUser, setExistedUser] = useState("")

  const [userId, setUserId, removeUserId] = useLocalStorage({
    key: "userId",
  })
  const [, setUserName, removeUserName] = useLocalStorage({
    key: "name",
  });
  const [userImg, setUserImg, removeUserImg] = useLocalStorage({
    key: "userImg",
  })
  const formLogin = useForm({
    initialValues: { userId: userId || '', password: "", captchaValue: "" },
    validate: {
      userId: (value) => {
        const userIdRegex = /^[A-Za-z0-9]+$/;
        if (userIdRegex.test(value) === false || value?.length < 6) {
          return "User Id is invalid"
        }
        return null
      },
      password: (value) => (value?.length < 6 ? "Password must have at least 6 letters" : null),
      // captchaValue: (val) => (val.length === 6 ? null : "Enter 6 digit captcha"),
    },
  });

  const formValidate = useForm({
    initialValues: { code: "" },
    validate: {
      code: (value) => (value?.length < 6 ? "TOTP must have at least 6 digits" : null),
    },
  });

  const {
    handleLogin,
    handleValidate2fa,
    isAccountBlocked,
  } = useHandleLogin(formLogin, formValidate, setValidate2faRequestId);

  useEffect(() => {
    if (userId) {
      formLogin.setFieldValue("userId", userId)
    }
  }, [userId])

  useEffect(() => {
    if (login.status === SUCCESS) {
      const { data } = login
      setValidate2faRequestId(data.requestId)
      setUserId(data.user.id);
      setUserName(data.user.name);
      setUserImg(data.user.image);
      formLogin.reset();
    } else {
      setValidate2faRequestId('')
    }
  }, [login]);

  useEffect(() => {
    if (!isEqual(prevProps.login.status, login.status) && login.status === FAILURE && login.data) {
      if (login.data.captchaRequired) {
        dispatch(getCaptcha());
        formLogin.setValues({ captchaValue: "" });
      }
    }
  }, [prevProps, login]);

  const validateApiCalled = useRef(false);

  const handleSubmit = (val: any) => {
    if (login.status === SUCCESS && validate2faRequestId) {
      const payload = {
        ...val,
        requestId: validate2faRequestId,
        userId: login.data.user.id,
      };
      validateApiCalled.current = true;
      handleValidate2fa(payload);
      return;
    }

    const params = {
      ...val,
      userId: val.userId.toUpperCase(),
      captchaId: captchaData.data && captchaData.data.id,
    }
    // App login
    const isAppLogin = window.location.pathname === "/app/login";
    if (isAppLogin) {
      const searchParams = parseQuery(window.location.search);
      if (searchParams.appId) {
        params.appId = searchParams.appId;
        params.isAppLogin = true
      }
    }
    if (formLogin.isValid()) {
      handleLogin(params);
    }
  };

  const handle2FAValidation = (value: { code: string }) => {
    const payload = {
      ...value,
      requestId: validate2faRequestId,
      userId: login.data.user.id,
    };
    validateApiCalled.current = true;
    handleValidate2fa(payload);
  }

  // useEffect(() => {
  //   if (formValidate.values.code.length === 6
  //     && validate2faRequestId
  //     && !validateApiCalled.current) {
  //     handleSubmit(formValidate.values)
  //   }
  // }, [validate2faRequestId, formValidate]);

  // const handleTOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   if (allowOnlyNumber(value)) {
  //     validateApiCalled.current = false;
  //     formValidate.setFieldValue('code', value);
  //   }
  // }

  const handleChangeUser = () => {
    setValidate2faRequestId("")
    removeUserId()
    removeUserName()
    removeUserImg()
    setExistedUser("")
    formLogin.setFieldValue("userId", "")
    formValidate.setFieldValue('code', "");
  }
  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Login To Tiqs</h2>
      </div>
      {isAccountBlocked ? (
        <AccountBlocked />
      ) : (
        <>
          <Box p={16}>
            {
              validate2faRequestId ? (
                <TotpInput
                  existedUser={existedUser}
                  userId={userId}
                  userImg={userImg}
                  handleChangeUser={handleChangeUser}
                  validate2faRequestId={validate2faRequestId}
                  handleSubmit={handle2FAValidation}
                  formLogin={formLogin}
                  validateApiCalled={validateApiCalled}
                />
              ) : (
                <LoginInput
                  existedUser={existedUser}
                  userId={userId}
                  userImg={userImg}
                  handleChangeUser={handleChangeUser}
                  validate2faRequestId={validate2faRequestId}
                  focusTrapRef={focusTrapRef}
                  handleSubmit={handleSubmit}
                  formLogin={formLogin}
                />
              )
            }
            <Group position="center" mt="lg">
              <Anchor component={Link} to="/forgot" size="sm">
                Forgot password?
              </Anchor>
            </Group>
          </Box>
          {!validate2faRequestId && (
            <Group position="center" mt="lg">
              <Anchor<"a">
                target="_blank"
                href={
                  isDevelopment()
                    ? "https://onboarding.dev.tiqs.in/"
                    : "https://onboarding.tiqs.in"
                }
                size="sm"
              >
                Don&apos;t have an account? Signup now!
              </Anchor>
            </Group>
          )}
        </>
      )}
    </div>
  );
};
export default Login;
