import {
  Box,
  Divider,
  Grid,
  NumberInput,
  Radio,
  Select,
  Text,
} from "@mantine/core"
import { ValidityRetention, varietyValues } from "../../../utils/constant"

interface IValidityOptions {
  activeTab: string
  validityVal: string
  setValidityVal: React.Dispatch<React.SetStateAction<string>>
  disclosedQty: number
  setDisclosedQty: React.Dispatch<React.SetStateAction<number>>
}

const ValidityOptions = ({
  activeTab,
  validityVal,
  setValidityVal,
  disclosedQty,
  setDisclosedQty,
}: IValidityOptions) => (
  <Box>
    <Divider my={10} />
    <Grid>
      <Grid.Col span={4}>
        <Text size="sm">Validity</Text>
        <Radio.Group
          label=""
          size="xs"
          value={validityVal}
          onChange={setValidityVal}
        >
          <Radio
            mt="xs"
            value={ValidityRetention.DAY}
            label="Day"
            color="blue"
          />
          <Radio
            mt="xs"
            disabled={activeTab === varietyValues.COVER}
            value={ValidityRetention.IOC}
            label="Immediate"
            color="blue"
          />
          <Radio
            mt="xs"
            disabled={activeTab === varietyValues.COVER}
            value="minutes"
            label="Minutes"
            color="blue"
          />
        </Radio.Group>
      </Grid.Col>
      <Grid.Col span={4}>
        <Select
          allowDeselect
          defaultValue="1m"
          description="Select time"
          disabled={
            validityVal === ValidityRetention.DAY || validityVal === "immediate"
          }
          data={[
            { value: "1m", label: "1 Minute" },
            { value: "5m", label: "5 Minute" },
            {
              value: "10m",
              label: "10 Minute",
            },
          ]}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <NumberInput
          type="number"
          mt="xl"
          id="disclosed-qty"
          placeholder="Disclosed Qty"
          description="Disclosed Qty"
          disabled={
            validityVal === "immediate" || activeTab === varietyValues.COVER
          }
          min={1}
          value={disclosedQty}
          onChange={(val: number) => setDisclosedQty(val)}
        />
      </Grid.Col>
    </Grid>
  </Box>
)

export default ValidityOptions
