import {
  Box,
  Space,
  Switch,
  Text,
  useMantineColorScheme,
  useMantineTheme,
  Grid,
  Badge,
  Button,
} from "@mantine/core"
import {
  ReactNode, useEffect, useState,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectUserPreferences } from "src/redux/sagas/users/userSelectors"
import { IUserDetails, IUserPreferences } from "src/redux/sagas/users"
import ContainerCard from "@components/cards/containerCard"
import Flex from "@components/atoms/flex/flex"
import AvatarEdit from "@components/atoms/avatarEdit"
import ZeroState from "@components/zeroState"
import ErrorElement from "@components/errorElement"
import Loading from "@components/loading/loading"
import useMediaCustom from "@hooks/common/useMediaCustom"
import {
  updateUserPreferences,
  addUserImage,
  getUserDetails,
} from "@redux/sagas/users/usersSlice"
import { ArrowRight } from "tabler-icons-react"
import { useLocation } from "wouter"
import { RootState } from "@redux/store"
import { FAILURE } from "@utils/constant"
import ErrorBoundary from "../../ErrorBoundary"
import HdfcLogo from "../../assets/logo/hdfc.png"
import useStyles from "./styles"

interface ProfileListItemProps {
  title: string
  element: string | React.ReactElement
}

interface IbankDetailsProps {
  accountNumber: string
  accountType: string
  bankName: string
  vpa: string
}

interface IProfileItem {
  title: string
  value: ReactNode
}

const ProfileItem = ({ title, value }: IProfileItem) => {
  const { cx, classes } = useStyles();
  return (
    <Box className={classes.profileItem}>
      <p className={cx('content_sm_regular text_fg_muted', classes.profileItemText)}>{title}</p>
      {typeof value === "string" ? <p className="content_lg_medium text_fg_default">{value}</p> : value}
    </Box>
  )
}

const ProfileListItem = ({ title, element }: ProfileListItemProps) => (
  <Flex position="space-between">
    <Text className="content_md_medium">
      {title}
    </Text>
    <Box>{element}</Box>
  </Flex>
)

const BankAccountCard = ({ title, value }: IProfileItem) => {
  const { classes, cx } = useStyles();
  return (
    <Box className={cx(classes.bankAccountCardContainer)}>
      <img src={HdfcLogo} alt="bank-logo" />
      <Box>
        <p className="content_xs_regular text_fg_muted">
          {title}
        </p>
        <p className="content_md_medium text_fg_default">{value}</p>
      </Box>
    </Box>
  )
}

const AccountSettings = (
  preferencesLoading: boolean,
  preferencesIsError: boolean,
  preferencesData: IUserPreferences,
  handleOrderUpdates: (data: boolean | string, type: string) => void
) => {
  if (preferencesLoading) {
    return <Loading />
  }
  if (preferencesIsError) {
    return <ErrorElement />
  }
  if (!preferencesData) {
    return <ZeroState title="You don't have any data yet" />
  }

  return (
    <ErrorBoundary>
      <ContainerCard headerTitle="Account Settings">
        <ProfileListItem
          title="Instant order updates"
          element={(
            <Switch
              radius="md"
              checked={preferencesData.instantOrderUpdates}
              onChange={(event) => handleOrderUpdates(
                event.currentTarget.checked,
                "instantOrderUpdates"
              )}
            />
          )}
        />
        <Box mt="sm" />
        <ProfileListItem
          title="Sticky order window"
          element={(
            <Switch
              radius="md"
              checked={preferencesData.stickyOrderWindow}
              onChange={(event) => handleOrderUpdates(
                event.currentTarget.checked,
                "stickyOrderWindow"
              )}
            />
          )}
        />
      </ContainerCard>
    </ErrorBoundary>
  )
}
// Main component
const Profile = () => {
  const { isMobile, isLargeScreen } = useMediaCustom();

  const theme = useMantineTheme()
  const { colors } = theme.other

  const { toggleColorScheme } = useMantineColorScheme()
  const dispatch = useDispatch()

  const [showAvatar, setShowAvatar] = useState(false);

  const [, setLocation] = useLocation()

  const { classes } = useStyles();

  const {
    data: preferencesData,
    loading: preferencesLoading,
    isError: preferencesIsError,
  } = useSelector(selectUserPreferences)

  const detailsState = useSelector((state: RootState) => state.user.details);

  const {
    data: detailsData,
    loading: detailsLoading,
    status: detailsStatus,
  } = detailsState;

  useEffect(() => {
    dispatch(getUserDetails())
  }, [])

  const handleOrderUpdates = (data: boolean | string, type: string) => {
    if (type === "theme") {
      toggleColorScheme()
    }
    dispatch(updateUserPreferences({ ...preferencesData, [type]: data }))
  }

  const handleUserImage = (file: any) => {
    const formData = new FormData();
    formData.append('image', file);
    dispatch(addUserImage(formData))
  }

  if (detailsLoading) {
    return <Loading />
  }
  if (detailsStatus === FAILURE) {
    return (
      <Box px="md" py="md">
        <ErrorElement message={detailsState.message} />
      </Box>
    );
  }
  if (!detailsData) {
    return <ZeroState title="You don't have any data yet" />
  }
  const {
    image,
    email,
    phone,
    pan,
    name,
    exchanges,
    bankDetails,
    totpEnabled,
  } = detailsData as IUserDetails;
  const handleClickUpdate = () => {
    setLocation("/edit-profile")
  }

  return (
    <ErrorBoundary>
      <Box px={isLargeScreen ? "xl" : "sm"} py="xl">
        <ContainerCard headerTitle="Personal Details">
          <Flex className={classes.avatarWrapper} flexWrap={isMobile ? "wrap" : "nowrap"}>
            <AvatarEdit
              imageUrl={image}
              showAvatar={showAvatar}
              userName={name}
              setShowAvatar={setShowAvatar}
              handleUserImage={handleUserImage}
            />

            <Flex
              flexWrap="wrap"
              position="space-between"
              className={classes.profileDetailsWrapper}
            >
              <ProfileItem title="Name" value={name} />
              <ProfileItem title="Email" value={email} />
              <ProfileItem title="Phone" value={phone} />
              <ProfileItem title="Pan" value={pan} />
            </Flex>
          </Flex>
        </ContainerCard>

        <Grid>
          <Grid.Col md={12} lg={8}>
            <ContainerCard headerTitle="Demat Account Details">
              <Flex
                flexWrap="wrap"
                sx={{ width: "100%" }}
                position="space-between"
              >
                <ProfileItem title="Demat (bo)" value={name} />
                <ProfileItem title="Demat Authorization" value="eDis" />
                <ProfileItem
                  title="Segments"
                  value={(
                    <Box>
                      {exchanges?.map((item) => (
                        <Badge
                          bg={colors.primary.muted}
                          color={colors.primary.muted}
                          radius="xs"
                          mr={6}
                        >
                          {item}
                        </Badge>
                      ))}
                    </Box>
                  )}
                />
              </Flex>
            </ContainerCard>
          </Grid.Col>
          <Grid.Col md={12} lg={4}>
            {AccountSettings(
              preferencesLoading,
              preferencesIsError,
              preferencesData,
              handleOrderUpdates
            )}
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col md={12} lg={8}>
            <ContainerCard
              headerTitle="Linked Bank Accounts"
              secondaryTitle="MANAGE"
              linkUrl="/manage"
            >
              <Flex className={classes.bankAccountCardWrapper} position="space-between">
                {bankDetails?.map((item: IbankDetailsProps) => (
                  <BankAccountCard
                    title={item.bankName}
                    value={item.accountNumber}
                  />
                ))}
              </Flex>
            </ContainerCard>
          </Grid.Col>
          <Grid.Col md={12} lg={4}>
            <ContainerCard
              headerTitle="Password & Security"
              // secondaryTitle="Update"
              // linkUrl="/edit-profile"
            >
              <ProfileListItem
                title="2-Factor Authentication"
                element={(
                  <p className="content_md_regular text_fg_muted">
                    {
                      totpEnabled ? "Enabled" : "Disabled"
                    }
                  </p>
                )}
              />
              <Space h="lg" />
              <Space h="lg" />
              <Button onClick={handleClickUpdate} mt={10} fullWidth rightIcon={<ArrowRight size="1rem" />}>
                Update
              </Button>
            </ContainerCard>
          </Grid.Col>
        </Grid>
      </Box>
    </ErrorBoundary>
  );
}

export default Profile
