import {
  Box, Button, Title, useMantineTheme,
} from "@mantine/core"
import { ReactElement } from "react"
import { Book2 } from "tabler-icons-react"

interface EmptyStateProps {
  title?: string
  icon?: ReactElement
  marginTop?: number
}

const ZeroState = ({
  title = "",
  icon,
  marginTop,
}: EmptyStateProps) => {
  const theme = useMantineTheme()

  return (
    <Box sx={{ textAlign: "center", marginTop: marginTop ?? 60 }}>
      {icon || <Book2 size={60} color={theme.colors.colorPrimary[0]} />}
      {/* <Title weight={500} size={18}>Nothing here</Title> */}
      <Title mt={10} weight={400} size={16}>
        {title}
      </Title>
      <Box mb={10}>
        <Button mt={20}>Get Started</Button>
      </Box>
      {/* <Button variant="white">View Data</Button> */}
    </Box>
  )
}

export default ZeroState
