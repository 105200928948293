import PlainCard from "@components/plainCard";
import { Flex, Loader } from "@mantine/core";
import { memo } from "react"
import ErrorBoundary from "src/ErrorBoundary";
import { formatNumber } from "@utils/index";
import ReloadIcons from "@components/atoms/ReloadIcons";
import useStyles from "./styles";
import rupeeIcon from "../../assets/icons/rupee.svg";

const ContentSummary = ({
  investedValue,
  isMobile,
}: {
  investedValue: number;
  isMobile: boolean;
}) => {
  const { classes } = useStyles();
  const keyStyles = "text_fg_muted heading_xs_regular";
  return (
    <div className={classes.investmentWrapper}>
      <div className={classes.accountValues}>
        <Flex justify="center" align="center">
          {!isMobile ? (
            <span className={classes.rupeeIconWrapper}>
              <img src={rupeeIcon} alt="Rupee Icon" />
            </span>
          ) : null}
          <div className={isMobile ? classes.otherValuesItem : ''}>
            <p className={keyStyles}>Today&apos;s P&L</p>
            <p className="text_fg_default content_xl_semibold">0.00</p>
          </div>
        </Flex>
      </div>
      <div className={classes.otherValues}>
        <div className={classes.otherValuesItem}>
          <p className={keyStyles}>Current Value</p>
          <p className="text_fg_default content_md_semibold">0.00</p>
        </div>
        <div className={classes.otherValuesItem}>
          <p className={keyStyles}>Invested value</p>
          <p className="text_fg_default content_md_semibold">
            {formatNumber(investedValue)}
          </p>
        </div>
      </div>
    </div>
  );
};

type SummaryProps = {
  loading: boolean;
  isError: boolean;
  // errMessage: string;
  loadingCheck: boolean | number;
  investedValue: number;
  isMobile: boolean;
  // data: any;
}

const Summary = memo((
  {
    loading,
    isError,
    loadingCheck,
    investedValue,
    isMobile,
  }: SummaryProps
) => {
  const { classes } = useStyles();

  return (
    <ErrorBoundary>
      <PlainCard
        className={classes.summaryCardWrapper}
        leftContent={(
          <Flex align="center">
            <p className="cardTitle"> Summary</p>
            {loading || loadingCheck ? <Loader size="sm" ml="md" /> : null}
            {isError ? <ReloadIcons /> : null}
          </Flex>
        )}
        mainContent={(
          <ContentSummary
            investedValue={investedValue}
            isMobile={isMobile}
          />
        )}
      />
    </ErrorBoundary>
  )
})
export default Summary
