import ErrorElement from '@components/errorElement';
import Loading from '@components/loading/loading';
import ZeroState from '@components/zeroState';
import { memo } from 'react'
import useRouteChange from '@hooks/common/useRouteChange';
import { Box } from '@mantine/core';
import GttTableList from './gttTableList';

type GttOrderTabProps = {
  gttLoading: boolean;
  gttDetailsData: any[];
  gttIsError: boolean;
  gttErrMessage: string;
  handleGttDetailsModal: (item: any) => void;
}

const GttOrderTab = memo((
  {
    gttLoading,
    gttDetailsData,
    gttIsError,
    gttErrMessage,
    handleGttDetailsModal,
  }: GttOrderTabProps
) => {
  const { isNavigateLoading } = useRouteChange()

  const secondLoading = gttLoading && isNavigateLoading && gttDetailsData.length

  if (secondLoading === 0 && gttLoading) {
    return (
      <>
        <ZeroState title="You don't have any GTT orders yet" />
        <Loading />
      </>
    )
  }

  if (gttLoading && !gttDetailsData.length) {
    return <Loading />
  }

  if (gttIsError && !gttDetailsData.length) {
    return <ErrorElement component="GTT Orders" message={gttErrMessage} />
  }

  if (!gttDetailsData?.length) {
    return <ZeroState title="You don't have any GTT orders yet" />
  }

  return (
    <Box>
      {
        gttDetailsData.length > 0 ? (
          <GttTableList
            title="GTT Order"
            data={gttDetailsData}
            loading={gttLoading && gttDetailsData.length}
            handleGttDetailsModal={handleGttDetailsModal}
            hydratedData={[]}
            isNoInternet={!!(gttIsError && gttDetailsData.length)}
          />
        ) : null
      }
    </Box>
  )
})

export default GttOrderTab
