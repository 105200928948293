import Flex from '@components/atoms/flex/flex';
import { Text, useMantineTheme } from '@mantine/core';
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders';
import { getSegment, instrumentSearch } from '@utils/index';
import { IPositionItem } from '@redux/sagas/positions';
import FormattedName from '@components/watchlist/formattedName';
import TradesDropdown from './tradesDropdown';

interface ITableInstrumentItem {
  row: HoldingItem | IUserOrder | IPositionItem | IGttOrderDetail,
  width?: number
}

const TradesInstrument = ({
  row,
  width = 200,
}: ITableInstrumentItem) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const segment = getSegment(symbol, exchange);
  const instrumentName = instrumentSearch.tokenMap[row.token]
  const parsedSymbolObj = instrumentSearch.get(instrumentName, segment, exchange)

  return (
    <div
      style={{ width: `${width}px` }}
      className="table-intrument"
    >
      <Flex align="baseline">
        {/* <Text size="sm">{removeEQFromSymbol(symbol)}</Text> */}
        <FormattedName
          name={parsedSymbolObj.niceName}
          expiryDay={parsedSymbolObj.expiryDay}
          isWeekly={parsedSymbolObj.isWeekly}
        />
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <TradesDropdown
        row={row}
      />
    </div>
  )
}

export default TradesInstrument
