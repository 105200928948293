import { memo } from 'react'
import {
  Loader,
} from "@mantine/core"
import { IUserLimits } from "src/redux/sagas/users"
import PlainCard from "@components/plainCard"
import Flex from "@components/atoms/flex/flex"
import ReloadIcons from '@components/atoms/ReloadIcons'
import ErrorBoundary from "../../ErrorBoundary"
import { formatNumber } from "../../utils"

const RenderMarginCard = memo(({
  limitsLoading,
  limitsData,
  limitsIsError,
  // limitsErrMessage,
  classes,
  loadingCheck,
}: {
  limitsLoading: boolean;
  limitsData: IUserLimits;
  // limitsErrMessage: string;
  limitsIsError: boolean;
  classes: any
  loadingCheck: boolean | number;
}) => {
  const marginAvailable = limitsData.length > 0 ? Number(limitsData[0]?.cash) - Number(limitsData[0]?.marginUsed) : 0
  const marginUsed = limitsData.length > 0 ? Number(limitsData[0]?.marginUsed) : 0
  const totalCash = limitsData.length > 0 ? Number(limitsData[0]?.cash) : 0

  return (
    <ErrorBoundary>
      <PlainCard
        className={classes.marginCardWrapper}
        leftContent={(
          <Flex align="center">
            <p className="heading_md_semibold"> Margin Available</p>
            {loadingCheck || limitsLoading ? <Loader size="sm" ml="md" /> : null}
            {limitsIsError ? (
              <ReloadIcons />
            ) : null}
          </Flex>
        )}
        rightContent={<p className="heading_md_semibold">{formatNumber(marginAvailable)}</p>}
        mainContent={(
          <Flex direction="column">
            <Flex className="fullWidth" position="space-between" mb={10}>
              <p className="heading_xs_regular">Margin Used</p>
              <p className="heading_xs_regular">{formatNumber(marginUsed)}</p>
            </Flex>
            <Flex className="fullWidth" position="space-between">
              <p className="heading_xs_regular">Total Funds</p>
              <p className="heading_xs_regular">{formatNumber(totalCash)}</p>
            </Flex>
          </Flex>
        )}
      />
    </ErrorBoundary>
  );
})

export default RenderMarginCard
