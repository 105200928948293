import { createSlice } from "@reduxjs/toolkit"
import type { ActionSuccess, ActionFailure } from "@redux/types"
import { HoldingsSlice, HoldingItem } from './holdingsTypes'

const initialState: HoldingsSlice = {
  holdings: {
    loading: false,
    status: '',
    data: null,
  },
}

export const holdingsSlice = createSlice({
  name: "holding",
  initialState,
  reducers: {
    getHoldings: (state, action) => {
      state.holdings.loading = true
      state.holdings.status = ""
    },
    getHoldingsSuccess: (state, action:ActionSuccess<HoldingItem[]>) => {
      const { payload } = action
      state.holdings = {
        loading: false,
        status: payload.status,
        data: payload.data,
      }
    },
    getHoldingsFailure: (state, action:ActionFailure<{}>) => {
      const { payload } = action;
      state.holdings = {
        ...state.holdings,
        loading: false,
        status: payload.status,
        errorData: payload.errorData,
        message: payload.message,
        errorCode: payload.errorCode,
      };
    },

    resetHoldingsSlice: () => initialState,
  },
})

// Action
export const {
  getHoldings,
  getHoldingsSuccess,
  getHoldingsFailure,

  resetHoldingsSlice,
} = holdingsSlice.actions

export default holdingsSlice.reducer
