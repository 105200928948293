/* eslint-disable @typescript-eslint/no-shadow */
import {
  Box, createStyles, Divider, Group, Text,
} from "@mantine/core"
import { memo } from "react"
import { useSelector } from "react-redux"
import {
  formatDate, getCookie,
} from "src/utils"
import { Bid, IFullSocketData } from "src/redux/sagas/sockets"
import { INDICES } from "src/utils/constant"
import { selectFullSocket } from "@redux/sagas/sockets/socketSelectors"
import Flex from "../atoms/flex/flex"

const defaultArr = Array(5).fill({ price: 0, quantity: 0, orders: 0 });

interface IMarketDepth {
  selectedToken: number;
  item: {
    exchange: "NSE" | "NFO" | string;
    segment: string;
    symbol: string;
  };
}

interface MarketDepthInfoProps {
  title: string
  value: number | string
}

interface MarketTextColor {
  color?: string;
}

const useStyles = createStyles((theme, { color }: MarketTextColor) => ({
  marketDepthContainer: {
    marginTop: "10px",
    padding: "10px 6px 0 6px",
    animationDuration: "300ms",
    animationName: "fadeInDown",
    animationTimingFunction: "ease-in-out",
    "@keyframes fadeInDown": {
      "0%": {
        opacity: 0,
        transform: "translateY(-10px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  marketDepthInner: {
    display: "flex",
    justifyContent: "space-between",
  },
  marketDepthTable: {
    width: "48%",
  },
  marketDepthTableTitle: {
    fontSize: ".625rem",
    color: "#666",
    fontWeight: 600,
    marginBottom: 4,
    paddingBottom: 9,
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      // justifyContent: "flex-start",
    },
  },
  marketDepthTableText: {
    fontSize: ".8125rem",
    fontWeight: 500,
    color: color || "#666",
    fontVariantNumeric: "tabular-nums",
  },
  minTableWidth: {
    width: '37%',
    paddingBottom: 3,
  },
  minTableOrders: {
    width: '26%',
    textAlign: "right",
    paddingBottom: 3,
  },
  minTableWidthAlign: {
    paddingBottom: 3,
    position: "relative",
    width: '37%',
    textAlign: "right",
    height: "100%",
  },
  marketQuantity: {
    zIndex: 2,
    width: "100%",
  },
}))

const MarketDepthTableTitle = ({ title }: { title: string }) => {
  const { cx, classes } = useStyles({})
  return (
    <Flex className={classes.marketDepthTableTitle} position="space-between">
      <Text className={cx(classes.minTableWidth, "content_2xs_semibold")}>{title}</Text>
      <Text className={classes.minTableOrders}>ORDERS</Text>
      <Text className={classes.minTableWidthAlign}>QTY.</Text>
    </Flex>
  )
}

const MarketDepthTableText = ({
  color, item, lightBar, darkBar, barWidth,
}: { color: string, item: Bid, lightBar: string, darkBar: string, barWidth: number }) => {
  const { orders, price, quantity } = item
  const { classes } = useStyles({ color })
  const theme = getCookie("tiqs-theme")
  const barColor = theme === "light" ? lightBar : darkBar
  const opacity = theme === "light" ? 1 : 0.4

  return (
    <Flex className={classes.marketDepthTableText} position="space-between">
      <Text className={classes.minTableWidth} size={13}>{(price / 100).toFixed(2)}</Text>
      <Text className={classes.minTableOrders} size={13}>{orders}</Text>
      <Flex className={classes.minTableWidthAlign}>
        <Box pos="absolute" right={0} w={`${barWidth}%`} h="100%" bg={barColor} opacity={opacity} />
        <Text className={classes.marketQuantity} size={13}>{quantity}</Text>
      </Flex>
    </Flex>
  )
}

const MarketDepthTableBottom = ({ total, color }: { total: number, color: string }) => {
  const { classes } = useStyles({})
  return (
    <Box mb={10}>
      <Group mt={6} className={classes.marketDepthTableText} position="apart">
        <Text color={color}>Total</Text>
        <Text color={color}>{total}</Text>
      </Group>
      <Divider mt="xs" />
    </Box>
  );
}

const MarketDepthInfo = ({ title, value }: MarketDepthInfoProps) => (
  <Flex mb={8} position="space-between">
    <Text color="#444" size={14} weight={500}>
      {title}
    </Text>
    <Text color="#9b9b9b" size={14}>
      {value || "N/A"}
    </Text>
  </Flex>
);

const MarketDepth = ({ selectedToken, item }: IMarketDepth) => {
  const { classes } = useStyles({});

  const marketDepth = useSelector(selectFullSocket)
  const { segment, exchange } = item
  const selectedMD: IFullSocketData = marketDepth[selectedToken] || {}
  const {
    bids,
    asks,
    high,
    low,
    ltq,
    close,
    ltt,
    open,
    volume,
    avgPrice,
    totalBuyQuantity,
    totalSellQuantity,
    lowerLimit,
    upperLimit,
    oi,

  } = selectedMD
  const updatedLtt = new Date((315513000 + ltt) * 1000);
  const dividedBy = 100;
  const totalBidQuantity = bids?.reduce((acc: number, item: Bid) => acc + item.quantity, 0)
  const totalAskQuantity = asks?.reduce((acc: number, item: Bid) => acc + item.quantity, 0)
  const upperCircuit = (upperLimit / 100).toFixed(2)
  const lowerCircuit = (lowerLimit / 100).toFixed(2)

  return (
    <Box className={classes.marketDepthContainer}>
      <Box className={classes.marketDepthInner}>
        <Box className={classes.marketDepthTable}>
          {segment !== INDICES ? (
            <>
              <MarketDepthTableTitle title="BID" />
              {(bids || defaultArr).map((item: Bid) => (
                <MarketDepthTableText
                  color="#4184f3"
                  lightBar="#EFF8FF"
                  darkBar="#175CD3"
                  key={crypto.randomUUID()}
                  item={item}
                  barWidth={(item.quantity / totalBidQuantity) * 100}
                />
              ))}
              <MarketDepthTableBottom
                color="#4184f3"
                total={totalBuyQuantity || 0}
              />
            </>
          ) : null}

          <MarketDepthInfo title="Open" value={(open / dividedBy).toFixed(2)} />
          <MarketDepthInfo title="Low" value={(low / dividedBy).toFixed(2)} />
          {segment !== INDICES ? (
            <>
              <MarketDepthInfo title="Volume" value={volume} />
              <MarketDepthInfo title="LTQ" value={ltq} />
              <MarketDepthInfo title="Lower circuit" value={lowerCircuit} />
            </>
          ) : null}
          {exchange === "NFO" ? (
            <MarketDepthInfo title="Expiry" value="N/A" />
          ) : null}
        </Box>

        <Box className={classes.marketDepthTable}>
          {segment !== INDICES ? (
            <>
              <MarketDepthTableTitle title="OFFER" />
              {(asks || defaultArr).map((item: Bid) => (
                <MarketDepthTableText
                  color="#fa5252"
                  lightBar="#FEE4E2"
                  darkBar="#B42318"
                  key={crypto.randomUUID()}
                  item={item}
                  barWidth={(item.quantity / totalAskQuantity) * 100}
                />
              ))}
              <MarketDepthTableBottom
                color="#fa5252"
                total={totalSellQuantity || 0}
              />
            </>
          ) : null}
          <MarketDepthInfo title="High" value={(high / dividedBy).toFixed(2)} />
          <MarketDepthInfo
            title="Prev. Close"
            value={(close / dividedBy).toFixed(2)}
          />
          {segment !== INDICES ? (
            <>
              <MarketDepthInfo
                title="Avg. Price"
                value={(avgPrice / dividedBy).toFixed(2)}
              />
              <MarketDepthInfo
                title="LTT"
                value={ltt ? formatDate(updatedLtt) : ""}
              />
              <MarketDepthInfo title="Upper circuit" value={upperCircuit} />
            </>
          ) : null}
          {exchange === "NFO" ? (
            <MarketDepthInfo title="OI" value={oi} />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MarketDepth)
