import {
  Switch, Route, Redirect,
} from "wouter";
import {
  AppShell,
  Box,
  createStyles,
  Flex,
  Loader,
  Navbar,
  rem,
  useMantineTheme,
} from "@mantine/core";
import Home from "@containers/home";
import Forgot from "@containers/forgot";
import Login from "@containers/login";
import Positions from "@containers/positions";
import Orders from "@containers/orders";
import Funds from "@containers/funds";
import Holdings from "@containers/holdings";
import ResetPassword from "@containers/reset-password";
import ValidateOtp from "@containers/validate-otp";
import Charts from "@containers/charts";
import EditProfile from "@containers/profile/edit-profile";
import Profile from "@containers/profile";
import AccountSetup from "@containers/create-password";
import useMediaCustom from "@hooks/common/useMediaCustom";
import Setup2Fa from "@containers/setup-2fa";
import AppLogin from "@containers/appLogin";
import { useLayoutEffect, useEffect } from "react";
import DisclaimerModal from "@containers/home/DisclaimerModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { getUserPreferences, getUserDetails } from "@redux/sagas/users/usersSlice";
import { getWatchlists } from "@redux/sagas/watchlists/watchlistsSlice";
import { SUCCESS } from "@utils/constant";
import {
  checkLogin, checkRedirect, childSites, navigateToSite,
} from "./utils";
import Watchlist from "./components/watchlist";
import AppBar from "./components/appBar";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    mainContainer: {
      maxWidth: rem(theme.other.screenWidth),
      margin: "0 auto",
      boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: colors.bg.default,
    },
    routeWrapper: {
      backgroundColor: colors.bg.default,
      height: '100vh',
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
});

const FUNDS_URL = process.env.REACT_APP_FUNDS_URL;

const AppRoutes = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { isLargeScreen } = useMediaCustom();
  const dispatch = useDispatch();
  const {
    logout,
  } = useSelector((state: RootState) => state.auth);
  const isLoggedIn = checkLogin();
  const [redirectTarget, redirectTo, redirectTargetRoute] = logout.status === SUCCESS
    ? [false, false, false]
    : checkRedirect();
  const isChildSite = isLoggedIn && typeof redirectTarget === "string" && childSites.includes(redirectTarget)
  useLayoutEffect(() => {
    if (isChildSite) {
      navigateToSite(redirectTarget, redirectTargetRoute)
    }
  }, [isLoggedIn])

  // Redirect to add-funds page if user is from there
  useEffect(() => {
    if (isLoggedIn) {
      const isFundsLogin = window.location.pathname === "/login/add-funds";
      if (isFundsLogin && FUNDS_URL) {
        window.location.href = FUNDS_URL;
      }
    }
  }, [isLoggedIn]);

  if (isChildSite) {
    return (
      <Flex h="100vh" w="100%" align="center" justify="center">
        <Loader />
      </Flex>
    )
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserPreferences());
      dispatch(getUserDetails());
      dispatch(getWatchlists());
    }
  }, [isLoggedIn]);

  return (
    <Box>
      {isLoggedIn ? (
        <Box className={classes.mainContainer}>
          <AppShell
            fixed
            padding={0}
            header={<AppBar />}
            navbar={
              isLargeScreen ? (
                <>
                  <Navbar width={{ base: rem(theme.other.watchlistWidth) }}>
                    <Watchlist />
                  </Navbar>
                  <DisclaimerModal />
                </>
              ) : (
                <span />
              )
            }
          >
            <Switch>
              <Route path="/">
                <Redirect to={redirectTarget && !isChildSite ? `/${redirectTarget}` : "/home"} />
              </Route>
              <Route path="/home" component={Home} />
              <Route path="/positions" component={Positions} />
              <Route path="/profile" component={Profile} />
              <Route
                path="/edit-profile"
                component={EditProfile}
              />
              <Route path="/watchlist" component={Watchlist} />
              <Route path="/orders" component={Orders} />
              <Route path="/funds" component={Funds} />
              <Route path="/holdings" component={Holdings} />
              <Route path="/charts" component={Charts} />
              <Route
                path="/charts/:exchange/:ticker/:token"
                component={Charts}
              />
              <Route path="/app/login" component={AppLogin} />
              <Route path="*" component={Home} />
            </Switch>
          </AppShell>
        </Box>
      ) : (
        <div className={classes.routeWrapper}>
          <Switch>
            <Route path="/forgot" component={Forgot} />
            <Route path="/reset" component={ResetPassword} />
            <Route path="/validate" component={ValidateOtp} />
            <Route path="/setup-account" component={AccountSetup} />
            <Route path="/setup-2fa" component={Setup2Fa} />
            <Route path="/login/:rest*" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/app/login" component={Login} />
            <Route path="/" component={Login} />
            <Route>
              <Redirect to={redirectTo ? `/?redirect=${redirectTo}` : "/"} />
            </Route>
          </Switch>
        </div>
      )}
    </Box>
  );
};

export default AppRoutes;
