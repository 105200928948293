import Flex from '@components/atoms/flex/flex'
import { useMantineTheme } from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { tableUseStyles } from './styled';

interface ITableHeaderItem {
  handleSort: ({ sortBy, sortDirection }: {
    sortBy: any;
    sortDirection: any;
  }) => void
  sortInfo: {
    sortBy: string;
    sortDirection: string;
  }
  label: string
  value: string
  rightAlign?: boolean
  className?: string
  width: number,
  columnBorder?: null | "left" | "right"
}

const TableHeaderItem = ({
  handleSort, sortInfo, label, value, rightAlign = true, className = "", width = 100, columnBorder = null,
}: ITableHeaderItem) => {
  const theme = useMantineTheme();
  const { classes, cx } = tableUseStyles();
  const color = theme.other.colors.fg.default

  let columnBorderClass : string | null = null;
  if (columnBorder !== null) {
    if (columnBorder === 'right') {
      columnBorderClass = classes.columnBorderRight;
    } else if (columnBorder === 'left') {
      columnBorderClass = classes.columnBorderLeft;
    }
  }
  return (
    <div
      aria-hidden="true"
      className={cx(
        className,
        "tableHeaderCell",
        columnBorderClass,
        sortInfo.sortBy === value ? 'tableHeaderCellActive' : null
      )}
      onClick={() => handleSort({
        sortBy: value,
        sortDirection:
          sortInfo.sortBy === value && sortInfo.sortDirection === "ASC" ? "DESC" : "ASC",
      })}
      style={{ width: `${width}px` }}
    >
      <Flex
        sx={{
          margin: 'auto 0', width: '100%',
        }}
        position="space-between"
        direction={rightAlign ? "row-reverse" : "row"}
      >
        <p className={rightAlign ? "textRight" : "textLeft"}>
          {label}
        </p>
        {
          sortInfo.sortBy === value
          && (sortInfo.sortDirection === "ASC" ? (
            <ChevronUp size={16} color={color} />
          ) : (
            <ChevronDown size={16} color={color} />
          ))
        }
      </Flex>
    </div>
  )
};
export default TableHeaderItem
