import { useSelector, useDispatch } from "react-redux"
import { coverOrder, editRegularOrder, regularOrder } from "@redux/sagas/trades/tradesSlice"
import type { RootState } from '@redux/store'
import { OrderTypeML, OrderTypeSLM, varietyValues } from "../../utils/constant"

interface IOrderProps {
  orderTypeMLVal: string
  priceField: number | undefined
  activeTab: string
  triggerPriceField: number | undefined
}

interface IHandleOrderProps {
  token: number | undefined
  selectedExchange: string
  quantity: number
  disclosedQty: number
  productValue: string
  buySellToggle: boolean
  orderTypeMLValue: string
  validityVal: string
}

const useOrderWL = ({
  orderTypeMLVal,
  priceField,
  activeTab,
  triggerPriceField,
}: IOrderProps) => {
  const dispatch = useDispatch()
  const {
    tradeModalInfo: { editOrderId = "" },
  } = useSelector((state: RootState) => state.trade);

  const handlePriceVal = () => {
    if (
      orderTypeMLVal === OrderTypeML[0].value
      || orderTypeMLVal === OrderTypeSLM[1].value
    ) {
      return 0
    }
    return Number(priceField)
  }

  const handleTriggerPriceVal = () => {
    if (activeTab === varietyValues.COVER) {
      return triggerPriceField
    } if (
      orderTypeMLVal === OrderTypeML[0]?.value
      || orderTypeMLVal === OrderTypeML[1]?.value
    ) {
      return 0
    }
    return Number(triggerPriceField)
  }

  const handleOrder = ({
    token,
    selectedExchange,
    quantity,
    disclosedQty,
    productValue,
    buySellToggle,
    orderTypeMLValue,
    validityVal,
  }: IHandleOrderProps) => {
    const payload = {
      exchange: selectedExchange,
      // symbol: currentAssetItem?.symbol + "-EQ",
      token: token?.toString(),
      quantity: Math.abs(quantity)?.toString(),
      disclosedQty: disclosedQty?.toString(),
      product: productValue,
      transactionType: buySellToggle ? "S" : "B",
      order: orderTypeMLValue,
      price: handlePriceVal()?.toString(),
      validity: validityVal,
      amo: activeTab === varietyValues?.AMO,
      triggerPrice: handleTriggerPriceVal()?.toString(),
      tags: "",
    }

    if (editOrderId) {
      dispatch(
        editRegularOrder({
          data: {
            ...payload,
            bookLossPrice: "0",
            bookProfitPrice: "0",
          },
          orderId: editOrderId,
        })
      )
    } else if (activeTab === varietyValues.COVER) {
      delete payload.triggerPrice
      dispatch(
        coverOrder({
          ...payload,
          bookLossPrice: handleTriggerPriceVal()?.toString(),
        })
      )
    } else {
      dispatch(regularOrder(payload))
    }
  }

  return { handleOrder, handlePriceVal, handleTriggerPriceVal }
}

export default useOrderWL
