import {
  Menu, Portal, createStyles, Box,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Copy, Dots, Edit, InfoCircle, X,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import useGetSocketVal from 'src/hooks/common/useGetSocketVal'
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders'
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice'
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { IPositionItem } from '@redux/sagas/positions'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { getDropdownCoordinates } from '@utils/index'

export const useStyles = createStyles((theme, { showMenu }: { showMenu:boolean }) => {
  const { colors } = theme.other
  return {
    menuWrapper: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 3,
      padding: '0 2px',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: showMenu ? 'visible' : 'hidden',
      paddingTop: '5px',
    },
    menuPopupWrapper: {
      position: "absolute",
      zIndex: 500,
      height: 'min-content',
    },
  }
});

interface ITableDropdown {
  row: IUserOrder | HoldingItem | IGttOrderDetail | IPositionItem,
  handleOrderDetailsModal?: (orderId: IUserOrder) => void;
  handleShowCancel?: (item: IUserOrder) => void;
}

const OpenDropdown = ({
  row,
  handleOrderDetailsModal = () => { },
  handleShowCancel = () => { },
}: ITableDropdown) => {
  const [showMenu, setShowMenu] = useState(false);

  const { classes, cx } = useStyles({ showMenu });
  const dispatch = useDispatch();
  const { ref, focused } = useFocusWithin();

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }
  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  const handleModifyOrder = (item: IUserOrder) => {
    const {
      expiry, symbol, token, exchange, quantity, transactionType, id,
    } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          expiry: expiry || 0, symbol, token, exchange,
        },
        buySellToggle: transactionType !== "B",
        showTradeModal: true,
        editOrderId: id,
        quantity,
      })
    );
  }

  const { ltp } = useGetSocketVal(Number(row.token), true)

  const handleRepeatOrder = (item: IUserOrder) => {
    const {
      expiry, symbol, token, exchange, quantity, transactionType, order, amo, product, price,
    } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          expiry: expiry || 0, symbol, token, exchange, order, amo, product, ltp, price: Number(price),
        },
        buySellToggle: transactionType !== "B",
        showTradeModal: true,
        quantity,
      })
    );
  }

  return (
    <span ref={ref} className={cx(!showMenu ? 'dots' : null, classes.menuWrapper)}>
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-8}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Box onMouseLeave={() => handleShowMenuDropdown(false)}>
          <Menu.Target>
            <span>
              <Dots cursor="pointer" />
            </span>
          </Menu.Target>
          <Portal>
            <Menu.Dropdown
              sx={{
                top: getDropdownCoordinates().top,
                right: getDropdownCoordinates().right,
                bottom: getDropdownCoordinates().bottom,
                left: getDropdownCoordinates().left,
              }}
              className={cx(classes.menuPopupWrapper, 'tableDropDowns')}
            >
              <Menu.Item
                onClick={() => handleShowCancel(row as IUserOrder)}
                icon={<X size={14} />}
              >
                Cancel
              </Menu.Item>
              <Menu.Item
                onClick={() => handleModifyOrder(row as IUserOrder)}
                icon={<Edit size={14} />}
              >
                Modify
              </Menu.Item>
              <Menu.Item
                icon={<Copy size={14} />}
                onClick={() => handleRepeatOrder(row as IUserOrder)}
              >
                Repeat
              </Menu.Item>
              <Menu.Item
                icon={<InfoCircle size={14} />}
                onClick={() => handleOrderDetailsModal(row as IUserOrder)}
              >
                Info
              </Menu.Item>
              <CommonTableDropdown row={row} />
            </Menu.Dropdown>
          </Portal>
        </Box>
      </Menu>
    </span>
  )
}

export default OpenDropdown
