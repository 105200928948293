import React from "react";
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    wrapper: {
      background: colors.base.white,
      padding: 24,
      borderRadius: 8,
      width: "100%",
      boxShadow: theme.other.shadow.xs,
      border: `1px solid ${colors.disabled.muted}`,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
    },
    headerBorder: {
      borderBottom: `1px solid ${colors.disabled.muted}`,
    },
    mainContent: {
      paddingTop: 16,
    },
  };
});

interface PlainCardProps {
  leftContent: React.ReactNode;
  rightContent?: React.ReactNode;
  mainContent: React.ReactNode;
  className?: string;
  headerBorder?: boolean;
  headerClassName?: string
}

const PlainCard = ({
  leftContent,
  rightContent,
  mainContent,
  className,
  headerBorder = true,
  headerClassName,
}: PlainCardProps) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.wrapper, className)}>
      {leftContent || rightContent ? (
        <div
          className={cx(
            classes.header,
            headerClassName,
            headerBorder ? classes.headerBorder : ""
          )}
        >
          {leftContent}
          {rightContent}
        </div>
      ) : null}
      <div className={classes.mainContent}>{mainContent}</div>
    </div>
  );
};

PlainCard.defaultProps = {
  rightContent: null,
  className: "",
  headerBorder: true,
};

export default PlainCard;
