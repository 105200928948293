import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    profileItem: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        width: '100%',
        marginBottom: '1.25rem',
      },
    },
    profileItemText: {
      marginBottom: '0.625rem',
      textTransform: 'uppercase',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        marginBottom: '0.25rem',
      },
    },
    avatarWrapper: {
      '& > :first-child': {
        [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
          marginBottom: '1rem',
        },
      },
    },
    bankAccountCardContainer: {
      padding: '0.75rem 1rem',
      background: colors.bg.muted,
      width: '100%',
      borderRadius: 8,
    },
    bankAccountCardWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    profileDetailsWrapper: {
      width: '100%',
      margin: '0 1.875rem',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        margin: '0 ',
      },
    },
  }
});

export default useStyles;
