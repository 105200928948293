import { showNotification } from "@mantine/notifications"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { RootState } from "src/redux/store"
import { capitalizeFirstLetter, isEqual } from "src/utils"
import { ErrorFallbackMsg, SUCCESS } from "src/utils/constant"
import { resetPassword } from "@redux/sagas/auth/authSlice"
import usePrevious from "../common/usePrevious"

interface PayloadProps {
  newPassword: string
  requestId: string
}
const useResetPassword = () => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()

  const resetData = useSelector(
    (state: RootState) => state.auth.reset
  )

  const prevProps = usePrevious({ resetData })

  const handleResetPassword = (payload: PayloadProps) => {
    dispatch(resetPassword(payload))
  }

  useEffect(() => {
    if (!isEqual(prevProps.resetData.status, resetData.status)
    && resetData.status === 'failure') {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(resetData.message) || ErrorFallbackMsg,
        message: "",
      })
    } else if (!isEqual(prevProps.resetData.status, resetData.status) && resetData.status === SUCCESS) {
      showNotification({
        color: "green",
        title: "Password validation successful",
        message: "",
      })
      setLocation(`/setup-2fa?requestId=${resetData.data && resetData.data.requestId}`)
    }
  }, [prevProps, resetData])

  return { handleResetPassword }
}

export default useResetPassword
