import { createStyles, rem } from "@mantine/core";
import { getTypographyStyles } from "@theme/themeConfig";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other;
  return {
    divider: {
      height: "100px",
    },
    wrapper: {
      padding: 24,
    },
    cardWrapper: {
    },
    summaryCardWrapper: {
      // paddingTop: '12px !important',
    },
    marginCardWrapper: {
      height: '100%',
    },
    investmentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'column',
      },
    },
    accountValues: {
    },
    otherValues: {
      display: 'flex',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'column',
      },
    },
    otherValuesItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '0 36px',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding: '0',
        marginBottom: '12px',
      },
    },
    rupeeIconWrapper: {
      background: colors.primary.muted,
      borderRadius: 12,
      height: 58,
      width: 58,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 16,
    },
    // Investment Summary
    rightTitleText: {
      color: colors.fg.subtle,
      ...getTypographyStyles('content_xs_regular'),
    },
    // Option chain card
    optionChainCard: {
      background: colors.base.white,
      padding: 24,
      borderRadius: 8,
      width: "100%",
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      height: '100%',
      border: `1px solid ${colors.disabled.muted}`,
    },
    // Summary Card Tabs
    summaryCardHeader: {
      paddingBottom: 0,
    },
    link: {
      all: 'unset',
      display: 'flex',
      padding: `0 ${rem(12)}`,
      textDecoration: 'none',
      cursor: 'pointer',
      color: colors.fg.muted,
      height: 52,
      justifyContent: 'center',
      alignItems: 'center',
      ...getTypographyStyles('heading_xs_semibold'),
      '&:hover': {
      },
      '&:[data-active]': {
        backgroundColor: 'red',
      },
    },
    linkActive: {
      color: colors.primary.default,
      borderBottom: `2px solid ${colors.primary.default}`,
    },
    rowTwo: {
      minHeight: 220,
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: 'column-reverse',
      },
    },
    optinChainImage: {
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        display: 'none',
      },
    },
  }
})

export default useStyles;
