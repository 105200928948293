import {
  Avatar, Box, FileButton, createStyles, useMantineTheme,
} from '@mantine/core'
import { getFirstLetterOfWord } from '@utils/index';
import { Pencil } from 'tabler-icons-react';

interface IAvatarEdit {
  imageUrl: string
  showAvatar: boolean
  userName: string
  setShowAvatar: React.Dispatch<React.SetStateAction<boolean>>
  handleUserImage: (file: any) => void
}

const useStyles = createStyles((theme) => ({
  avatarContainer: {
    position: 'relative',
  },
  avatarImageContainer: {
    border: `2px solid ${theme.other.colors.disabled.muted}`,
    borderRadius: "100%",
    width: 120,
    height: 120,
    padding: 3,
  },
  avatarImage: {
    borderRadius: '50%',
    objectFit: 'cover',
  },
  avatarIconContainer: {
    background: theme.other.colors.primary.muted,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 151,
    position: "absolute",
    right: 0,
    bottom: 4,
    cursor: "pointer",
  },
}));

const AvatarEdit = ({
  imageUrl, showAvatar, userName, setShowAvatar, handleUserImage,
}: IAvatarEdit) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <Box className={classes.avatarContainer}>
      {
        (imageUrl && !showAvatar) ? (
          <Box className={classes.avatarImageContainer}>
            <img
              width={110}
              height={110}
              alt="user"
              onError={() => setShowAvatar(true)}
              className={classes.avatarImage}
              src={imageUrl}
            />
          </Box>
        ) : (
          <Avatar
            color={theme.other.colors.primary.muted}
            size={120}
            radius={120}
          >
            {getFirstLetterOfWord(userName)}
          </Avatar>
        )
      }
      <FileButton onChange={handleUserImage} accept="image/png,image/jpeg">
        {(props) => (
          <Box {...props} className={classes.avatarIconContainer}>
            <Pencil size={16} className="text_primary_default" />
          </Box>
        )}
      </FileButton>
    </Box>
  )
}

export default AvatarEdit
