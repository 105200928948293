import { call, put } from "redux-saga/effects"
import { errorHandler } from "src/redux/api/handler"
import {
  requestChangePassword,
  requestEnable2fa,
  requestUpdateUserPreferences,
  requestUserAddImage,
  requestUserDetails,
  requestUserLimits,
  requestUserPreferences,
  requestVerify2fa,
} from "./usersRequests"
import {
  getUserDetailsFailure,
  getUserDetailsSuccess,
  getUserPreferencesFailure,
  getUserPreferencesSuccess,
  getUserLimitsSuccess,
  getUserLimitsFailure,
  changePasswordSuccess,
  changePasswordFailure,
  enable2FaSuccess,
  enable2FaFailure,
  verify2FaSuccess,
  verify2FaFailure,
  // setChangePassword,
} from "./usersSlice"

// get user details
export function* handleUserDetails(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserDetails)
    yield put(getUserDetailsSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getUserDetailsFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle user limits
export function* handleUserLimits(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserLimits);
    yield put(getUserLimitsSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getUserLimitsFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle user preferences
export function* handleUserPreferences(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserPreferences)
    yield put(getUserPreferencesSuccess({ data, status }))
  } catch (error: any) {
    yield put(
      getUserPreferencesFailure({
        error: errorHandler(error).message, status: errorHandler(error).status, isError: true,
      })
    )
  }
}

// handle update user preferences
export function* handleUpdateUserPreferences(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUpdateUserPreferences, action.payload)
    yield put(getUserPreferencesSuccess({ data, status, loading: false }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      getUserPreferencesFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// handle upload user image
export function* handleUserAddImage(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserAddImage, action.payload)
    yield put(getUserDetailsSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getUserDetailsFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// enable 2 factor
export function* handleEnable2fa(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestEnable2fa)
    yield put(enable2FaSuccess({ data, status }))
  } catch (error: any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      enable2FaFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// verify 2 factor
export function* handleVerify2fa(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestVerify2fa, action.payload)
    yield put(verify2FaSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      verify2FaFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}

// change password
export function* handleChangePassword(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestChangePassword, action.payload)
    yield put(changePasswordSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(
      changePasswordFailure({
        errorCode, message, status, errorData,
      })
    )
  }
}
