import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    totalContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: 45,
      alignItems: 'center',
      borderBottom: `1px solid ${colors.disabled.muted}`,
      fontSize: 14,
    },
    totalText: {
      width: 100,
      textAlign: 'right',
      marginRight: 5,
    },
    totalValue: {
      marginRight: 135,
      width: 120,
      textAlign: 'right',
    },
    tableColumnBgBlue: {
      background: "#F5FAFF",
    },
  }
});

export default useStyles;
