import {
  Badge,
  Box,
  createStyles,
  getStylesRef,
  Group,
  Menu,
  Text,
} from "@mantine/core"
import { useFocusWithin } from "@mantine/hooks"
import {
  AlignJustified,
  Dots,
  Pin,
  Plus,
  ShoppingBag,
  Trash,
  TrendingUp,
} from "tabler-icons-react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { useEffect, useState } from "react"
import useGetSocketVal from "src/hooks/common/useGetSocketVal"
import { selectUserPreferences } from "src/redux/sagas/users/userSelectors"
import type { IWatchlistItem } from "src/redux/sagas/watchlists"
import {
  addWLItem,
  removeWlItem,
} from "@redux/sagas/watchlists/watchlistsSlice"
import { colors, INDICES } from "@utils/constant"
import {
  selectSelectedWLIndex,
  selectWatchlistData,
} from "@redux/sagas/watchlists/watchlistSelectors"
import { updateUserPreferences } from "@redux/sagas/users/usersSlice"
import { setGttModalInfo } from "@redux/sagas/misc/miscSlice"
import { setTradeModalInfo } from "@redux/sagas/trades/tradesSlice"
import { removeEQFromSymbol } from "@utils/index"
import Flex from "../atoms/flex/flex"
import { SearchWLItemProps } from "./index.d"
import SymbolButton from './symbolButton'

type ClickHandlerEventType = React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>;

type StyleParams = {
  color?: string;
  isDisabled?: boolean;
  isSearch?: boolean;
}

const useStyles = createStyles<string, StyleParams>((theme) => ({
  symbolItemContainer: {
    position: "absolute",
    right: 0,
    top: '0.5rem',
    display: "none",
    background:
      theme.colorScheme === "dark" ? theme.colors.secondaryBackground : "#fefefe",
    ref: getStylesRef("mg"),
    justifyContent: "end",
    animationDuration: "100ms",
    animationTimingFunction: "ease-in",
    animationName: "appearIn",
    "@keyframes appearIn": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 0.25,
      },
      "100%": {
        opacity: 1,
      },
    },
  },
  menuContainer: {
    backgroundColor: theme.other.colors.bg.default,
  },
}))

type SymbolItemProps = {
  item: IWatchlistItem | SearchWLItemProps;
  isAdd: boolean;
  token?: number | undefined;
  isSearch?: boolean | undefined;
  parsedSymbolObj?: {} | undefined;
  handleMarketDepth?: ((selectedAssetItem: any) => void) | undefined;
  handleSearch?: (val: string) => void,
}

const SymbolItem = ({
  item,
  isAdd,
  token = 0,
  isSearch = false,
  parsedSymbolObj = {},
  handleMarketDepth = () => { },
  handleSearch = () => { },
}: SymbolItemProps) => {
  // console.log(isSearch, item)
  const { classes, cx } = useStyles({ isSearch })
  const dispatch = useDispatch()
  const [, setLocation] = useLocation()
  const { ref, focused } = useFocusWithin()

  const { data: preferencesData } = useSelector(selectUserPreferences)

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)

  const selectedWatchlist = watchlistsData[selectedWLIndex]

  const [showMenu, setShowMenu] = useState(false)

  const { ltp } = useGetSocketVal(token, true)

  const handleSelectStock = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    assetItem: IWatchlistItem | SearchWLItemProps,
    parsedObj: Record<string, string>,
    isSell: boolean
  ) => {
    event.stopPropagation();
    dispatch(
      setTradeModalInfo({
        currentAssetItem: { ...assetItem, ...parsedObj, ltp },
        buySellToggle: isSell,
        showTradeModal: true,
      })
    )
    handleSearch("")
  }

  const handleRemoveWatchlistItem = (event: ClickHandlerEventType, watchlistId: number) => {
    event.stopPropagation();
    if (watchlistId && selectedWatchlist?.id) {
      dispatch(
        removeWlItem({
          mwId: selectedWatchlist.id,
          watchlistItemId: watchlistId,
        })
      )
    }
  }

  const handleAddWatchlistItem = (event: ClickHandlerEventType, assetItem: Record<string, any>) => {
    event.stopPropagation();
    const payload = {
      exchange: assetItem.exchange === INDICES ? "NSE" : assetItem.exchange,
      segment: assetItem.segment,
      token: assetItem.exchangeToken,
      symbol: assetItem.fullName,
      sequence: Number(selectedWatchlist?.items?.length) + 1,
    }
    dispatch(
      addWLItem({
        data: payload,
        mwId: selectedWatchlist?.id,
      })
    )
  }

  const handleChart = (event: ClickHandlerEventType, selectedItem: Record<string, any>) => {
    event.stopPropagation();
    const trimmedSymbol = isSearch ? selectedItem.tradingSymbol : removeEQFromSymbol(selectedItem.symbol);
    setLocation(`/charts/${selectedItem.exchange}/${trimmedSymbol}/${selectedItem.token}`)
  }

  const handlePinItem = (currentIndex: number, assetItem: Record<string, any>) => {
    const payload = {
      exchange: assetItem.exchange,
      token: assetItem.token,
      symbol: removeEQFromSymbol(assetItem.symbol),
      expiry: assetItem.expiry,
    }
    const tempArray = preferencesData.pinnedInstruments
    const updatedPreferences = [
      ...tempArray.slice(0, currentIndex),
      payload,
      ...tempArray.slice(currentIndex + 1),
    ]

    dispatch(
      updateUserPreferences({
        ...preferencesData,
        pinnedInstruments: updatedPreferences,
      })
    )
  }

  const handleMarketDepthHandler = (event: ClickHandlerEventType, assetItem: Record<string, any>) => {
    event.stopPropagation();
    handleMarketDepth(assetItem)
  }

  const handleGttModal = (selectedOrderItem: Record<string, any>) => {
    if (selectedOrderItem) {
      dispatch(
        setGttModalInfo({ showGttModal: true, gttOrderDetails: selectedOrderItem })
      )
    }
  }

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }

  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  return (
    <section className={cx(classes.symbolItemContainer)}>
      {item.segment === INDICES ? null : (
        <>
          <SymbolButton
            color={colors.primaryBlue}
            title="B"
            onClick={(event) => handleSelectStock(event, item, parsedSymbolObj, false)}
            hoverText="Buy"
            isSearch={isSearch}
          />
          <SymbolButton
            color={colors.primaryRed}
            title="S"
            onClick={(event) => handleSelectStock(event, item, parsedSymbolObj, true)}
            hoverText="Sell"
            isSearch={isSearch}
          />
        </>
      )}
      <SymbolButton
        title={<AlignJustified size={12} />}
        hoverText="Market Depth"
        onClick={(event) => handleMarketDepthHandler(event, item)}
        isSearch={isSearch}
      />
      <SymbolButton
        title={<TrendingUp size={12} />}
        hoverText="Chart"
        onClick={(event) => handleChart(event, item)}
        isSearch={isSearch}
      />
      {isAdd ? (
        <SymbolButton
          title={<Plus size={12} />}
          onClick={(event) => handleAddWatchlistItem(event, item)}
          hoverText="Add"
          isSearch={isSearch}
        />
      ) : (
        <SymbolButton
          title={<Trash size={16} />}
          isDisabled={isSearch}
          onClick={(event) => handleRemoveWatchlistItem(event, (item as IWatchlistItem).id)}
          hoverText="Delete"
          isSearch={isSearch}
        />
      )}

      <div ref={ref}>
        <Menu
          offset={2}
          shadow="md"
          width={200}
          transitionProps={{ duration: 150 }}
          withArrow
          opened={showMenu}
          onChange={() => handleShowMenuDropdown(!showMenu)}
        >
          {!isSearch && (
            <Menu.Target>
              <span>
                <SymbolButton
                  title={<Dots size={12} />}
                  hoverText="More"
                  onClick={() => {}}
                  isSearch={false}
                />
              </span>
            </Menu.Target>
          )}

          <Menu.Dropdown className={classes.menuContainer}>
            <Box px="sm" py="5px">
              <Flex>
                <Pin size={14} />
                <Group w="100%" position="apart">
                  <Text ml="xs" size="sm">
                    Pin
                  </Text>
                  <Flex>
                    <Badge
                      onClick={() => handlePinItem(0, item)}
                      radius="xs"
                      size="sm"
                      color="blue"
                      variant="light"
                      mr={5}
                      className="pinned-item"
                    >
                      1
                    </Badge>
                    <Badge
                      onClick={() => handlePinItem(1, item)}
                      radius="xs"
                      size="sm"
                      color="blue"
                      variant="light"
                      className="pinned-item"
                    >
                      2
                    </Badge>
                  </Flex>
                </Group>
              </Flex>
            </Box>
            {item.segment !== INDICES ? (
              <Menu.Item
                icon={<ShoppingBag size={12} />}
                onClick={() => handleGttModal(item)}
              >
                Create GTT
              </Menu.Item>
            ) : null}
            <Menu.Item
              icon={<TrendingUp size={12} />}
              onClick={(event) => handleChart(event, item)}
            >
              Charts
            </Menu.Item>
            {/* <Divider />
            <Menu.Item icon={<Settings size={14} />}>Technicals</Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </div>
    </section>
  );
}

export default SymbolItem
