/* eslint-disable react/no-unstable-nested-components */
import {
  Badge,
  Box,
  Text,
} from "@mantine/core"
import { tableUseStyles } from "src/components/table/styled"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
import {
  useEffect, useRef, useState,
} from "react";
import useCSVDownload from "@hooks/common/useCSVDownload";
import TitleHeading from "@components/atoms/titleHeading";
import Flex from "@components/atoms/flex/flex"
import { IGttOrderDetail, IGttOrderDetails } from "@redux/sagas/orders"
import PnLCell from "@components/table/pnLCell";
import { RootState } from "@redux/store";
import { connect, useSelector } from "react-redux";
import NotFoundState from "@components/zeroState/NotFoundZeroState";
import TableHeaderItem from "@components/table/tableHeaderItem";
import TableItem from "@components/table/tableItem";
import {
  buySellConst, orderStatusColor, tableContainer,
} from "@utils/constant"
import ConfirmDelete from "@components/modals/position/confirmDelete";
import GttIntrument from "./gttIntrument";

type HydratedData = IGttOrderDetail & {
  ltp: number,
};

interface IGttTableProps {
  title: string;
  data: IGttOrderDetails
  hydratedData: HydratedData[];
  loading: number | boolean
  handleGttDetailsModal: (item: any) => void,
  isNoInternet: boolean
}

const GttTableList = ({
  title,
  hydratedData,
  loading,
  handleGttDetailsModal,
  isNoInternet,
}: Omit<IGttTableProps, "data">) => {
  const { theme } = tableUseStyles();

  const {
    deleteGttModalInfo: { showDeleteGttModal },
  } = useSelector((state: RootState) => state.misc);

  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [sortedData, setSortedData] = useState<IGttOrderDetails>(hydratedData);
  const [searchVal, setSearchVal] = useState("");

  const { downloadCSV } = useCSVDownload(
    ["timeStamp", "transactionType", "symbol", "product", "quantity"],
    "orders"
  );

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = hydratedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
      setSortedData(tempSearchData)
    } else {
      setSortedData(hydratedData)
    }
  }, [hydratedData, searchVal])

  const tableData = useRef<IGttOrderDetail[]>([]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchVal(value)
  }

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    tableData.current = tempSortedData
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  if (searchVal.length) {
    tableData.current = sortedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
  } else if (!sortInfo.sortBy) {
    tableData.current = sortedData
  }

  const tableClassName = sortedData.length < 10 ? "table-min-height" : ""

  // Return the data for a given row index
  const rowGetter = ({ index }) => tableData.current[index];

  const headerRowRenderer = () => (
    <section className="table-header-row sticky">
      <TableHeaderItem
        width={120}
        label="Time"
        value="createdAt"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
        className="pl-10"
      />
      <TableHeaderItem
        width={230}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={140}
        label="Type"
        value="transactionType"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={110}
        label="Trigger"
        value="orderParams1"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={110}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={110}
        label="Qty"
        value="quantity"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={140}
        label="Status"
        value="status"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
        columnBorder="left"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = tableData.current[index];
    if (row) {
      return (
        <div key={key} style={style} className="table-row">
          <TableItem
            width={120}
            value={row.createdAt.slice(0, 9)}
            rightAlign={false}
            className="pl-10"
          />
          <GttIntrument
            width={230}
            row={row}
            handleOrderDetailsModal={handleGttDetailsModal}
          />
          <TableItem
            width={140}
            value={(
              <Flex align="center">
                <Badge radius="xs" color="gray">
                  {row.oiVariable.length === 2 ? 'OCO' : 'Single'}
                </Badge>
                <Badge radius="xs" color={row.transactionType === "B" ? "blue" : "red"}>
                  {buySellConst[row.transactionType]}
                </Badge>
              </Flex>
            )}
          />
          <TableItem
            width={110}
            value={(
              <div>
                <Flex direction="row-reverse" align="baseline">
                  <Text ml="xs" size={10}>
                    {
                      Number(row.ltp) !== 0
                        ? (
                          ((Number(row.orderParamsLeg1.price) - Number(row.ltp))
                            / Number(row.ltp))
                          * 100
                        ).toFixed(2)
                        : 0
                    }
                    %
                  </Text>
                  <Text>{row.orderParamsLeg1.price}</Text>
                </Flex>
                {row.oiVariable.length === 2 ? (
                  <Flex mt={6} direction="row-reverse" align="baseline">
                    <Text ml="xs" size={10}>
                      {
                        Number(row.ltp) !== 0
                          ? (
                            ((Number(row.orderParamsLeg2.price) - Number(row.ltp))
                              / Number(row.ltp))
                            * 100
                          ).toFixed(2)
                          : 0
                      }
                      %
                    </Text>
                    <Text>{row.orderParamsLeg2.price}</Text>
                  </Flex>
                ) : null}
              </div>
            )}
          />
          <TableItem
            width={110}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.ltp}
                value={row.ltp.toFixed(2) || 0.00}
              />
            )}
          />
          <TableItem
            width={110}
            value={row.quantity}
          />
          <TableItem
            width={140}
            value={(
              <Badge
                radius="xs"
                color={orderStatusColor.COMPLETE}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGttDetailsModal(row)}
              >
                Active
              </Badge>
            )}
            className="pr-10"
            columnBorder="left"
          />
        </div>
      );
    }
    return null
  };

  return (
    <Box mt={12} mb={20}>
      <TitleHeading
        title={title}
        length={sortedData.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={Boolean(loading)}
        isNoInternet={isNoInternet}
      />
      {tableData.current.length ? (
        <WindowScroller>
          {({
            height, isScrolling, onChildScroll, scrollTop,
          }) => (
            <AutoSizer style={tableContainer} disableHeight>
              {({ width }) => (
                <Table
                  className={tableClassName}
                  autoHeight
                  width={width}
                  height={height}
                  rowCount={tableData.current.length}
                  rowGetter={rowGetter}
                  rowHeight={50}
                  rowRenderer={rowRenderer}
                  headerRowRenderer={headerRowRenderer}
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      ) : <NotFoundState />}

      {showDeleteGttModal
        ? (
          <ConfirmDelete
            isVisible={showDeleteGttModal}
          />
        ) : null}
    </Box>
  )
}

const mapStateToProps = (state: RootState, ownProps: IGttTableProps) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    return {
      ...item,
      ltp: ((data.ltp || 0) / 100),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(GttTableList)
