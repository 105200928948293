import {
  Box, Menu, Portal, createStyles,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dots, Edit, InfoCircle, X,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import { setGttDeleteModalInfo, setGttModalInfo } from '@redux/sagas/misc/miscSlice'
import { IGttOrderDetail } from '@redux/sagas/orders'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { getDropdownCoordinates } from '@utils/index'

export const useStyles = createStyles((theme, { showMenu }: { showMenu:boolean }) => {
  const { colors } = theme.other
  return {
    menuWrapper: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 3,
      padding: '0 2px',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: showMenu ? 'visible' : 'hidden',
      paddingTop: '5px',
    },
    menuPopupWrapper: {
      position: "absolute",
      zIndex: 500,
      height: 'min-content',
    },
  }
});

interface ITableDropdown {
  row: IGttOrderDetail,
  handleOrderDetailsModal: (orderId: IGttOrderDetail) => void;
}

const GttDropdown = ({
  row,
  handleOrderDetailsModal = () => { },
}: ITableDropdown) => {
  const [showMenu, setShowMenu] = useState(false);
  const { classes, cx } = useStyles({ showMenu });
  const dispatch = useDispatch();
  const { ref, focused } = useFocusWithin();

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }
  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  const handleEditGttOrder = (item: IGttOrderDetail) => {
    if (item) {
      dispatch(setGttModalInfo({ isEdit: true, showGttModal: true, gttOrderDetails: item }))
    }
  };

  const handleDeleteGttOrder = (currentItem: IGttOrderDetail) => {
    if (currentItem) {
      // dispatch(deleteGttOrder(alertId))
      dispatch(setGttDeleteModalInfo({
        alertId: currentItem.alertID,
        showDeleteGttModal: true,
        gttOrderDetails: currentItem,
      }))
    }
  };

  return (
    <span ref={ref} className={cx(!showMenu ? 'dots' : null, classes.menuWrapper)}>
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-8}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Box onMouseLeave={() => handleShowMenuDropdown(false)}>
          <Menu.Target>
            <span>
              <Dots cursor="pointer" />
            </span>
          </Menu.Target>
          <Portal>
            <Menu.Dropdown
              sx={{
                top: getDropdownCoordinates().top,
                right: getDropdownCoordinates().right,
                bottom: getDropdownCoordinates().bottom,
                left: getDropdownCoordinates().left,
              }}
              className={cx(classes.menuPopupWrapper, 'tableDropDowns')}
            >
              <Menu.Item
                icon={<Edit size={14} />}
                onClick={() => handleEditGttOrder((row as IGttOrderDetail))}
              >
                Edit
              </Menu.Item>

              <Menu.Item
                icon={<X size={14} />}
                onClick={() => handleDeleteGttOrder(row)}
              >
                Delete
              </Menu.Item>
              <Menu.Item
                icon={<InfoCircle size={14} />}
                onClick={() => handleOrderDetailsModal(row)}
              >
                Info
              </Menu.Item>
              <CommonTableDropdown row={row} />
            </Menu.Dropdown>
          </Portal>
        </Box>
      </Menu>
    </span>
  )
}

export default GttDropdown
