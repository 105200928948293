import Flex from '@components/atoms/flex/flex';
import {
  Box, Button, Divider, Group, Modal, Radio,
} from '@mantine/core'
import { IPositionItem } from '@redux/sagas/positions';
import { convertPostionOrder } from '@redux/sagas/trades/tradesSlice';
import { exchanges, productType } from '@utils/constant';
import { getNiceName } from '@utils/index';
import { useDispatch } from 'react-redux';
import useMediaCustom from 'src/hooks/common/useMediaCustom';
import { ArrowNarrowRight } from 'tabler-icons-react';

interface IConfirmCancelSingle {
  isVisible: boolean
  handleCloseConvertPosition: () => void;
  selectedPositionDetails: IPositionItem
}

const ConvertPostion = ({
  isVisible,
  handleCloseConvertPosition,
  selectedPositionDetails,
}: IConfirmCancelSingle) => {
  const { isMobile } = useMediaCustom();
  const dispatch = useDispatch();

  const { exchange, product } = selectedPositionDetails
  const { symbol, token, qty } = selectedPositionDetails
  const instrumentName = getNiceName(token, exchange, symbol)

  let convertValue = "";
  let selectedProduct = "";
  if (exchange === exchanges.NSE && product === "C") {
    selectedProduct = "C"
    convertValue = "MIS"
  } else if (exchange === exchanges.NFO && (product === "M")) {
    selectedProduct = "M"
    convertValue = "MIS"
  } else if (product === "I") {
    selectedProduct = exchange === exchanges.NFO ? "M" : "C"
    convertValue = exchange === exchanges.NFO ? "NRML" : "CNC"
  }

  const handleConvertPosition = () => {
    dispatch(convertPostionOrder({
      data: {
        exchange,
        symbol,
        quantity: Math.abs(qty),
        product: selectedProduct,
        previousProduct: product,
        transactionType: qty > 0 ? "B" : "S",
        positionType: "Day",
      },
    }))
  }

  return (
    <Modal
      onClose={() => null}
      opened={isVisible}
      size="md"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Box mb={10}>
        <Flex align="baseline">
          <p className="heading_sm_semibold mr-10">
            {instrumentName}
          </p>
          <p className="content_md_regular">{selectedPositionDetails.exchange}</p>
        </Flex>
      </Box>
      <Divider />
      <Flex mt={20} mb={20} position="space-between">
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Type
          </p>
          <Radio
            label="Day"
            checked
            size="xs"
          />
        </Box>
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Product
          </p>
          <Flex>
            <p className="content_sm_regular text_fg_default">
              {productType[product]}
            </p>
            <p className="px-5"><ArrowNarrowRight size={14} /></p>
            <p className="content_sm_regular text_fg_default">
              {convertValue}
            </p>
          </Flex>
        </Box>
        <Box>
          <p className="content_sm_regular text_fg_muted mb-4">
            Quantity
          </p>
          <p className="content_sm_regular text_fg_default">{Math.abs(selectedPositionDetails.qty)}</p>
        </Box>
      </Flex>
      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">

        <Button
          onClick={handleConvertPosition}
        >
          Convert
        </Button>
        <Button
          variant="outline"
          onClick={handleCloseConvertPosition}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default ConvertPostion
