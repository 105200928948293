/* eslint-disable no-param-reassign */
import { createStyles } from "@mantine/core";
import {
  useEffect, useState, ReactNode, useCallback,
} from "react";

const useStyles = createStyles((theme) => ({
  customDraggableContainer: {
    position: "fixed",
    left: "50%",
    bottom: 0,
    transform: "translate(-50%, 0)",
    zIndex: 101,
    borderRadius: 12,
    minWidth: 540,
    maxWidth: 540,
    backgroundColor: theme.other.colors.bg.subtle,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      minWidth: "100%",
      maxWidth: "100%",
      overflowY: "scroll",
      borderRadius: 0,
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      height: "100%",
    },
  },
}));

type CustomDraggableProps = {
  buySellToggle: boolean,
  headerContent: ReactNode,
  children: ReactNode,
  // manageTop: number
};

const CustomDraggable = ({
  buySellToggle,
  headerContent,
  children,
  // manageTop
}: CustomDraggableProps) => {
  const { classes } = useStyles();

  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentHeight, setCurrentHeight] = useState(0);

  const dragElement = useCallback(
    (elmnt) => {
      /* function body */
      setCurrentWidth(elmnt.offsetWidth);
      setCurrentHeight(elmnt.offsetHeight);
      let pos1 = 0;
      let pos2 = 0;
      let pos3 = 0;
      let pos4 = 0;
      const tempDocument = document.getElementById(`${elmnt.id}Header`);
      const closeDragElement = () => {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      };

      const elementDrag = (ev) => {
        ev = ev || window.event;
        ev.preventDefault();
        // calculate the new cursor position:

        pos1 = pos3 - ev.clientX;
        pos2 = pos4 - ev.clientY;
        pos3 = ev.clientX;
        pos4 = ev.clientY;
        // checking the right end screen and bottom end screen
        const tempCurrentWidth = elmnt.offsetLeft - pos1 + currentWidth - currentWidth / 2;
        if (tempCurrentWidth > 540
          && tempCurrentWidth < window.innerWidth
          && elmnt.offsetTop - pos2 + currentHeight
          < (window.screen.availHeight - 90)) {
          if (elmnt.offsetTop - pos2 > 0) {
            elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
            elmnt.style.bottom = 0;
          }
          if (elmnt.offsetLeft - pos1 > 0) {
            elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
            elmnt.style.bottom = "auto";
          }
        }
      };

      const dragMouseDown = (ev) => {
        ev = ev || window.event;
        ev.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = ev.clientX;
        pos4 = ev.clientY;
        window.document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      };

      if (tempDocument) {
        // if present, the header is where you move the DIV from:
        tempDocument.onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }
    },
    [currentHeight, currentWidth]
  );

  useEffect(() => {
    const elementTop = document.getElementById("draggableDiv");
    dragElement(elementTop);
  }, [dragElement]);

  return (
    <div id="draggableDiv" className={classes.customDraggableContainer}>
      <section
        id="draggableDivHeader"
        className={`${buySellToggle ? "bg-red" : "bg-blue"} trader-modal-header`}
      >
        {headerContent}
      </section>
      {children}
    </div>
  );
};

export default CustomDraggable;
