import { createStyles } from "@mantine/core"
import thm from '../../theme/theme'

const useStyles = createStyles(() => ({
  sortIcon: {
    opacity: 1,
  },
  th: {
    padding: "0 !important",
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}))

export default useStyles

export const pnlStyles = createStyles({
  greenColor_dark: {
    color: thm.colors?.colorSuccess?.[0],
  },
  greenColor_light: {
    color: thm.colors?.colorSuccess?.[0],
  },
  redColor_dark: {
    color: thm.colors?.colorDanger?.[0],
  },
  redColor_light: {
    color: thm.colors?.colorDanger?.[0],
  },
  text: {
    textAlign: 'right',
    fontSize: 14,
  },
  percent: {
    fontSize: 11,
  },
})

export const tableUseStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    greenColor: {
      color: colors.success.default,
    },
    redColor: {
      color: colors.error.default,
    },
    // react-table styling
    tableHeader: {
      fontWeight: 500,
      color: 'red',
      width: "100%",
      fontSize: 12,
      textTransform: "capitalize",
    },
    textLeft: {
      textAlign: 'left',
    },
    textRight: {
      textAlign: 'right',
      width: "100%",
    },
    hTD: {
      textAlign: 'right',
      fontSize: 14,
    },
    tableItem: {
      fontSize: 14,
    },
    columnBorderRight: {
      borderRight: `1px solid ${colors.disabled.muted}`,
    },
    columnBorderLeft: {
      borderLeft: `1px solid ${colors.disabled.muted}`,
    },
  }
})
