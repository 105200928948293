import {
  useMantineTheme,
  Grid,
  ActionIcon,
  Box,
} from "@mantine/core"
import { ExternalLink } from "tabler-icons-react"
import {
  memo, useEffect,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import useMediaCustom from "@hooks/common/useMediaCustom"
import useRouteChange from "@hooks/common/useRouteChange"
import { getUserLimits } from "@redux/sagas/users/usersSlice"
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice"
import Flex from "@components/atoms/flex/flex"
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { FAILURE, SUCCESS } from "@utils/constant"
import { bounceAnimation } from "../../utils"
import useStyles from "./styles"
import optionChain from "../../assets/images/optionChain.png";
// import DisclaimerModal from "./DisclaimerModal"
import Summary from "./Summary"
import RenderMarginCard from "./RenderMargin"

const Home = () => {
  const theme = useMantineTheme()
  const { colors } = theme.other
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { isMobile } = useMediaCustom()
  const { isNavigateLoading } = useRouteChange()

  const userLimitsState = useSelector((state:RootState) => state.user.limits)
  const { data, loading, status } = useSelector(
    (state: RootState) => state.holding.holdings
  )

  const investedValue = data ? data.reduce(
    (a: number, b: HoldingItem) => a + Number(b.effectiveQty) * Number(b.avgPrice),
    0
  ) : 0

  useEffect(() => {
    dispatch(getUserLimits())
    dispatch(getHoldings([]))
  }, [dispatch])

  // TODO
  //  const loadingCheck = loading && isNavigateLoading && limitsData.length;
  const loadingCheck = loading && isNavigateLoading && userLimitsState.status === SUCCESS
  const limitLoadingCheck = userLimitsState.loading
    && isNavigateLoading
    && userLimitsState.status === SUCCESS;

  return (
    <Box sx={loadingCheck ? bounceAnimation : {}} className={classes.wrapper}>
      {/* Row 1 Summary Card  */}
      <Grid gutter={24} mb={12}>
        <Grid.Col span={12}>
          <Summary
            loading={loading}
            isError={status === FAILURE}
            loadingCheck={loadingCheck}
            investedValue={investedValue}
            isMobile={isMobile}
          />
        </Grid.Col>
      </Grid>

      {/* Row 2  */}
      <Grid
        gutter={24}
        className={classes.rowTwo}
        dir={isMobile ? "column" : "row"}
      >
        {/* Option Chain */}
        <Grid.Col span={isMobile ? 12 : 7}>
          <div className={classes.optionChainCard}>
            <Grid>
              <Grid.Col span={isMobile ? 12 : 9}>
                <Flex pb={16}>
                  <p className="cardTitle">Option Chain</p>
                  <ActionIcon ml={12}>
                    <ExternalLink color={colors.primary.default} />
                  </ActionIcon>
                </Flex>
                <p
                  className={
                    isMobile ? "content_md_regular" : "heading_xs_regular"
                  }
                >
                  Analyse and compare different options contracts to find the
                  one that best suits your trading strategy the one that best
                  suits your trading strategy.
                  {" "}
                </p>
              </Grid.Col>
              <Grid.Col
                className={classes.optinChainImage}
                span={3}
                p={isMobile ? 8 : 16}
                mt={isMobile ? 48 : 16}
              >
                <img src={optionChain} alt="Option Chain" />
              </Grid.Col>
            </Grid>
          </div>
        </Grid.Col>
        {/* Margin Card */}
        <Grid.Col span={isMobile ? 12 : 5}>
          <RenderMarginCard
            limitsLoading={userLimitsState.loading}
            limitsData={userLimitsState.data || []}
            // limitsErrMessage={limitsErrMessage}
            limitsIsError={userLimitsState.status === FAILURE}
            classes={classes}
            loadingCheck={limitLoadingCheck}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default memo(Home)
