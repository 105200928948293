import { showNotification } from "@mantine/notifications"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { capitalizeLetter, isEqual } from "src/utils";
import {
  loginUser, validate2fa,
} from "@redux/sagas/auth/authSlice"
import type { RootState } from '@redux/store';
import type { ILoginPayload } from "@redux/sagas/auth";
import { FAILURE } from "@utils/constant"
import usePrevious from "../common/usePrevious";

const useHandleLogin = (formLogin, formValidate, setValidate2faRequestId) => {
  const dispatch = useDispatch()

  const {
    login: loginData,
    validate2fa: validate2faData,
  } = useSelector((state: RootState) => state.auth);
  const prevProps = usePrevious({ loginData, validate2faData })
  const [isAccountBlocked, setIsAccountBlocked] = useState(false);
  const handleLogin = (payload:ILoginPayload) => {
    dispatch(loginUser(payload))
  }

  const handleValidate2fa = (payload) => {
    dispatch(validate2fa(payload))
  }

  useEffect(() => {
    if (validate2faData.status === 'failure') {
      const { errorData, message, errorCode } = validate2faData
      // session expire
      if (!isEqual(prevProps.validate2faData.status, validate2faData.status) && errorCode === 422) {
        setValidate2faRequestId("")
        formValidate.reset();
        formLogin.setFieldValue("password", "")
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: "Please enter password again",
        })
      } else if (!isEqual(prevProps.validate2faData.status, validate2faData.status) && errorCode === 403) {
        setIsAccountBlocked(true)
        showNotification({
          color: "red",
          title: 'User account blocked',
          message: "Please follow the on screen instructions",
        })
      } else if (!isEqual(prevProps.validate2faData.status, validate2faData.status) && errorData) {
        const remainingAttempts = errorData.maxAttemptsAllowed - errorData.totalWrongAttempts
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: remainingAttempts > 0 ? `${remainingAttempts}
        attempt(s) remain before the account is locked.` : 'Something went wrong',
        })
      }
    }
  }, [validate2faData, prevProps])

  useEffect(() => {
    if (!isEqual(prevProps.loginData.status, loginData.status) && loginData.status === FAILURE && loginData.errorData) {
      const { message, errorCode } = loginData
      if (message === "invalid password" || message === "wrong password") {
      // if invalid password
        showNotification({
          color: "red",
          title: "Incorrect password",
          message: "Please recheck your password",
        })
      } else if (loginData.errorData.isBlocked && errorCode === 403) {
        setIsAccountBlocked(true)
        showNotification({
          color: "red",
          title: "User account blocked",
          message: "Please follow the on screen instructions",
        })
      } else {
        // default
        showNotification({
          color: "red",
          title: capitalizeLetter(message),
          message: '',
        })
      }
    }
  }, [loginData, prevProps])

  return { handleLogin, handleValidate2fa, isAccountBlocked }
}

export default useHandleLogin
