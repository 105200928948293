import {
  Box, createStyles, Image, Loader, TextInput,
} from '@mantine/core'
import { getCaptcha, reloadCaptcha } from '@redux/sagas/auth/authSlice';
import { RootState } from '@redux/store';
import { ErrorFallbackMsg, FAILURE, SUCCESS } from '@utils/constant';
import { allowOnlyNumber } from '@utils/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RotateClockwise } from 'tabler-icons-react';

const useStyles = createStyles(() => ({
  upperCasing: {
    "& input": {
      textTransform: "uppercase",
    },
  },
  outerBox: {
    display: "flex",
    margin: "20px auto 10px auto",
    width: "75%",
  },
  innerBox: {
    width: "100%",
  },
  rotate: {
    transform: 'rotate(360deg)',
    transition: 'transform 0.5s ease-in-out',
  },
  captchaLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '78px',
  },
}))

const CaptchaContainer = ({ form, callCaptcha = true }: { form: any, callCaptcha?: boolean }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [captchaReload, setCaptchaReload] = useState(false)

  const captchaData = useSelector(
    (state: RootState) => state.auth.captcha
  )

  useEffect(() => {
    if (callCaptcha) {
      dispatch(getCaptcha())
    }
  }, [dispatch])

  const handleReloadCaptcha = () => {
    setCaptchaReload(true)
    if (captchaData.status === SUCCESS && captchaData.data) {
      dispatch(reloadCaptcha(captchaData.data.id));
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCaptchaReload(false)
    }, 500)
  }, [captchaReload])

  const handleCaptchaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (allowOnlyNumber(e.target.value)) {
      form.setFieldValue("captchaValue", e.target.value)
    }
  }

  return (
    <>
      <Box className={classes.outerBox}>
        <Box className={classes.innerBox}>
          {captchaData.status === FAILURE ? (
            <p>{captchaData.message || ErrorFallbackMsg}</p>
          ) : null}
          {captchaData.data
            ? captchaData.data.captcha && (
            <Image
              radius="md"
              src={captchaData.data.captcha}
              alt="captcha text image"
            />
            )
            : null}
          {captchaData.loading ? (
            <div className={classes.captchaLoader}>
              <Loader />
            </div>
          ) : null}
        </Box>
        <RotateClockwise
          onClick={handleReloadCaptcha}
          className={`cursor-pointer ${captchaReload ? classes.rotate : ""}`}
        />
      </Box>
      <TextInput
        label="Captcha"
        placeholder="Captcha"
        {...form.getInputProps("captchaValue")}
        maxLength={6}
        type="text"
        autoComplete="off"
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={handleCaptchaChange}
      />
    </>
  );
}

export default CaptchaContainer
