/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unstable-nested-components */
import {
  Badge,
  Box,
  Button,
  Checkbox,
} from "@mantine/core"
import {
  useEffect, useState,
} from "react"
import ConfirmCancelOrder from "@components/modals/order/confirmCancel/confirmCancelOrder"
import { tableUseStyles } from "src/components/table/styled"
import {
  Table, SortDirection, WindowScroller, AutoSizer,
} from 'react-virtualized';
// import usePrevious from "@hooks/common/usePrevious"
import useCSVDownload from "@hooks/common/useCSVDownload"
import ConfirmCancelSingle from "@components/modals/order/confirmCancel/confirmCancelSingle"
import TitleHeading from "@components/atoms/titleHeading"
import { IUserOrder } from "@redux/sagas/orders"
import PnLCell from "@components/table/pnLCell"
import { connect } from "react-redux"
import NotFoundState from "@components/zeroState/NotFoundZeroState"
import TableHeaderItem from "@components/table/tableHeaderItem"
import TableItem from "@components/table/tableItem"
import {
  buySellConst, productType, orderStatusColor, tableContainer,
} from "@utils/constant"
import { RootState } from '@redux/store'
import OpenInstrument from "./openIntrument";

type HydratedData = IUserOrder & { ltp: number };

type OpenOrderTableProps = {
  data: IUserOrder[];
  title: string;
  hydratedData: HydratedData[];
  handleOrderDetailsModal: (orderId: IUserOrder) => void;
  handleCancelOrder: (orderId: string) => void;
  loading?: boolean
  isNoInternet: boolean
};

const OpenOrderTable = ({
  title,
  hydratedData,
  handleOrderDetailsModal,
  handleCancelOrder,
  loading,
  isNoInternet,
}: Omit<OpenOrderTableProps, "data">) => {
  const { theme } = tableUseStyles();

  // const prevProps = usePrevious({ data: hydratedData })

  const [sortInfo, setSortInfo] = useState({
    sortBy: '',
    sortDirection: SortDirection.ASC,
  });
  const [sortedData, setSortedData] = useState<HydratedData[]>(hydratedData);
  const [selectAllTable, setSelectAllTable] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);

  const [showConfirmCancelSingle, setShowConfirmCancelSingle] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IUserOrder>({} as IUserOrder);
  const [searchVal, setSearchVal] = useState("");
  const [selectedOrderMap, setSelectedOrderMap] = useState({});

  const { downloadCSV } = useCSVDownload(
    ["timeStamp", "transactionType", "symbol", "product", "quantity", "ltp", "price", "orderStatus"],
    "open-orders"
  );

  const isAnyRowSelected = Object.values(selectedOrderMap).some((value) => value);

  const countRowSelected = Object.values(selectedOrderMap).filter((value) => value).length;

  const handleSelectRow = (row: IUserOrder, isCurrentVal: boolean) => {
    setSelectedOrderMap({ ...selectedOrderMap, [row.id]: !isCurrentVal });
  }

  useEffect(() => {
    if (countRowSelected === sortedData.length) {
      setSelectAllTable(true)
    } else {
      setSelectAllTable(false)
    }
  }, [countRowSelected, sortedData])

  const handleSelectAll = (isVal: Boolean) => {
    const tempOrderMap = {};
    sortedData.forEach((item) => {
      tempOrderMap[item.id] = !isVal;
    });
    setSelectedOrderMap(tempOrderMap)
    // setSelectAllTable(!isVal)
  }

  const handleCancelRow = (isBoolean: boolean) => {
    setShowConfirmCancel(isBoolean)
  }

  // handle single cancel order
  const handleConfirmCancelSingle = () => {
    handleCancelOrder(selectedRow.id)
    setShowConfirmCancelSingle(false)
    setSelectedRow({} as IUserOrder)
  }

  const handleCancelSingleRow = () => {
    setShowConfirmCancelSingle(false)
  }

  // const sortData = useCallback(() => {
  //   if (sortInfo.sortBy === '') {
  //     return;
  //   }
  //   const tempSortedData = Array.from(hydratedData).sort((a, b) => {
  //     if (a[sortInfo.sortBy] < b[sortInfo.sortBy]) {
  //       return sortInfo.sortDirection === SortDirection.ASC ? -1 : 1;
  //     }
  //     if (a[sortInfo.sortBy] > b[sortInfo.sortBy]) {
  //       return sortInfo.sortDirection === SortDirection.ASC ? 1 : -1;
  //     }
  //     return 0;
  //   });
  //   setSortedData(tempSortedData);
  // }, [sortInfo]);

  // useEffect(() => {
  //   sortData();
  // }, [sortInfo]);

  // useEffect(() => {
  //   if ((sortedData.length === 0 && hydratedData.length) || !isEqual(hydratedData, prevProps.data)) {
  //     if (sortInfo.sortBy !== '') {
  //       sortData();
  //     } else {
  //       setSortedData(Array.from(hydratedData));
  //     }
  //   }
  // }, [hydratedData]);

  const handleShowCancel = (item: IUserOrder) => {
    setSelectedRow(item)
    setShowConfirmCancelSingle(true)
  }

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchVal(value)
  };

  const selectedRows = sortedData.filter((item) => {
    const itemId = item.id.toString();
    return selectedOrderMap.hasOwnProperty(itemId) && selectedOrderMap[itemId];
  });

  const handleConfirmCancel = () => {
    selectedRows.forEach((item) => handleCancelOrder(item.id))
  }

  useEffect(() => {
    if (searchVal) {
      const tempSearchData = hydratedData.filter((item) => item.symbol.toLowerCase().includes(searchVal.toLowerCase()));
      setSortedData(tempSearchData)
    } else if (!sortInfo.sortBy) {
      setSortedData(hydratedData)
    }
  }, [hydratedData, searchVal])

  const tableClassName = sortedData.length < 10 ? "table-min-height" : "abc"

  // Handle sorting
  const handleSort = ({ sortBy: sortFieldBy, sortDirection: sortFieldDirection }) => {
    // Sort the data array based on the selected column
    const tempSortedData = Array.from(sortedData).sort((a, b) => {
      if (a[sortFieldBy] < b[sortFieldBy]) return sortFieldDirection === "ASC" ? -1 : 1;
      if (a[sortFieldBy] > b[sortFieldBy]) return sortFieldDirection === "ASC" ? 1 : -1;
      return 0;
    });
    setSortedData(tempSortedData);
    setSortInfo({
      sortBy: sortFieldBy,
      sortDirection: sortFieldDirection,
    })
  };

  // Return the data for a given row index
  const rowGetter = ({ index }) => sortedData[index];

  const validateQty = (filled, total) => {
    if (filled === total) {
      return total;
    }
    if (filled > 0 && filled < total) {
      return `${filled} / ${total}`;
    }
    return total;
  }

  const headerRowRenderer = () => (
    <section className="table-header-row">
      <div
        aria-hidden="true"
        className="tableHeaderCell w-40 pl-10"
      >
        <Checkbox checked={selectAllTable} onChange={() => handleSelectAll(selectAllTable)} />
      </div>
      <TableHeaderItem
        width={100}
        label="Time"
        value="timeStamp"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={110}
        label="Type"
        value="transactionType"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={260}
        label="Instrument"
        value="symbol"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={80}
        label="Product"
        value="product"
        handleSort={handleSort}
        sortInfo={sortInfo}
        rightAlign={false}
      />
      <TableHeaderItem
        width={90}
        label="Qty"
        value="quantity"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={115}
        label="LTP"
        value="ltp"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={110}
        label="Price"
        value="price"
        handleSort={handleSort}
        sortInfo={sortInfo}
      />
      <TableHeaderItem
        width={110}
        label="Status"
        value="orderStatus"
        handleSort={handleSort}
        sortInfo={sortInfo}
        className="pr-10"
      />
    </section>
  )

  // Render each row with multiple columns
  const rowRenderer = ({ index, key, style }) => {
    const row = sortedData[index];
    if (row) {
      return (
        <div key={key} style={style} className="table-row">
          <TableItem
            width={50}
            value={(
              <Checkbox
                checked={Boolean(selectedOrderMap[row.id])}
                onChange={() => handleSelectRow(row, Boolean(selectedOrderMap[row.id]) || false)}
              />
            )}
            rightAlign={false}
            className="pl-10"
          />
          <TableItem
            width={100}
            value={row.timeStamp.slice(0, 9)}
            rightAlign={false}
          />
          <TableItem
            width={110}
            value={(
              <Badge size="md" radius="xs" color={row.transactionType === "B" ? "blue" : "red"}>
                {buySellConst[row.transactionType]}
              </Badge>
            )}
            rightAlign={false}
          />
          <OpenInstrument
            width={260}
            row={row}
            handleOrderDetailsModal={handleOrderDetailsModal}
            handleShowCancel={handleShowCancel}
          />
          <TableItem width={80} value={productType[row.product]} rightAlign={false} />
          <TableItem width={90} value={validateQty(row.fillShares, row.quantity)} />
          <TableItem
            width={115}
            value={(
              <PnLCell
                colorScheme={theme.colorScheme}
                netChange={row.ltp === 0 ? 0 : row.ltp}
                value={row.ltp.toFixed(2) || "0.00"}
              />
            )}
          />
          <TableItem width={110} value={Number(row.price).toFixed(2)} />
          <TableItem
            width={110}
            value={(
              <Badge
                radius="xs"
                color={orderStatusColor[row.orderStatus! || "COMPLETE"]}
                onClick={() => handleOrderDetailsModal(row)}
                className="cursor-pointer"
              >
                {row.orderStatus ?? 'complete'}
              </Badge>
            )}
            className="pr-10"
          />
        </div>
      );
    }
    return null
  };
  return (
    <Box mt={12} mb={20}>
      <TitleHeading
        title={title}
        length={sortedData.length}
        searchVal={searchVal}
        handleSearchChange={handleSearchChange}
        downloadCSV={() => downloadCSV(hydratedData)}
        loading={loading}
        isNoInternet={isNoInternet}
      />
      {sortedData.length ? (
        <section>
          <WindowScroller>
            {({
              height, registerChild, isScrolling, onChildScroll, scrollTop,
            }) => (
              <AutoSizer style={tableContainer} ref={() => registerChild(null)}>
                {({ width }) => (
                  <Table
                    className={tableClassName}
                    autoHeight
                    width={width}
                    height={height}
                    rowCount={hydratedData.length}
                    rowGetter={rowGetter}
                    rowHeight={50}
                    rowRenderer={rowRenderer}
                    headerRowRenderer={headerRowRenderer}
                    scrollTop={scrollTop}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
          {
            isAnyRowSelected && (
              <Button mt="lg" variant="light" onClick={() => handleCancelRow(true)}>
                Cancel
                {' '}
                {countRowSelected}
                {' '}
                Order
              </Button>
            )
          }
        </section>
      ) : <NotFoundState />}

      {/* show confirm modal before cancel order */}
      {showConfirmCancel ? (
        <ConfirmCancelOrder
          isVisible={showConfirmCancel}
          handleCloseModal={handleCancelRow}
          handleConfirmCancel={handleConfirmCancel}
          selectedRows={selectedRows}
          handleSelectAll={handleSelectAll}
        />
      ) : null}

      {showConfirmCancelSingle
        ? (
          <ConfirmCancelSingle
            isVisible={showConfirmCancelSingle}
            handleCloseModal={handleCancelSingleRow}
            handleConfirmCancel={handleConfirmCancelSingle}
            selectedRow={selectedRow}
          />
        ) : null}

    </Box>
  )
}

const mapStateToProps = (state: RootState, ownProps: OpenOrderTableProps) => {
  const hydratedData = ownProps.data.map((item) => {
    const data = state.sockets.quoteSocketData[item.token] || {};
    return {
      ...item,
      ltp: Number((data.ltp || 0) / 100),
    }
  });
  return { hydratedData }
}

export default connect(mapStateToProps, null)(OpenOrderTable)
