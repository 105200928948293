import {
  memo, useEffect,
} from "react"
import { Box } from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import { setQuoteTokens } from "src/redux/sagas/sockets/socketsSlice"
import { bounceAnimation } from "src/utils"
import type { RootState } from "src/redux/store"
import useRouteChange from "@hooks/common/useRouteChange"
import HoldingZeroState from "@components/zeroState/holdingZeroState"
import Loading from "@components/loading/loading"
import ErrorElement from "@components/errorElement"
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { FAILURE, SUCCESS } from "@utils/constant"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import HoldingTableList from "./holdingTableList"
import HoldingsSummary from './holdingsSummary'

const Holdings = () => {
  const dispatch = useDispatch()
  const { isLargeScreen } = useMediaCustom();
  const holdingsState = useSelector((state: RootState) => state.holding.holdings);
  const { data: holdingsData, loading, status } = holdingsState
  const isHoldingsData = holdingsData && holdingsData.length > 0;
  const isError = status === FAILURE && !holdingsData
  const { isNavigateLoading } = useRouteChange()

  useEffect(() => {
    dispatch(getHoldings({}))
  }, [])

  useEffect(() => {
    const subsObj: Record<string, number> = {};
    const unSubObj: Record<string, number> = {};
    if (status === SUCCESS && holdingsData) {
      holdingsData.forEach((item) => {
        subsObj[item.token] = 1
        unSubObj[item.token] = 0
      })
      dispatch(setQuoteTokens({ ...subsObj, source: "from holding" }))
    }
    return () => {
      dispatch(setQuoteTokens({ ...unSubObj, source: "from holding unmount" }))
    }
  }, [holdingsData]);

  const secondLoading = loading && holdingsData

  return (
    <RenderBoundary
      loading={loading}
      status={status}
      data={holdingsData}
      errorState={(
        <ErrorElement component="Positions" message={isError && holdingsState.message} />
      )}
      customLoader={(
        <Loading />
      )}
    >
      {
        isHoldingsData ? (
          <Box
            sx={secondLoading && isNavigateLoading ? bounceAnimation : {}}
            p={isLargeScreen ? "lg" : "sm"}
          >
            <HoldingTableList
              title="Holdings"
              data={holdingsData}
              loading={Boolean(secondLoading)}
              hydratedData={[]}
              isNoInternet={!!(isError && !holdingsData)}
            />
            <HoldingsSummary />
          </Box>
        ) : (
          <Box p={isLargeScreen ? "lg" : "sm"}>
            <HoldingZeroState />
            {loading ? <Loading /> : null}
          </Box>
        )
      }
    </RenderBoundary>
  )
}

export default memo(Holdings)
