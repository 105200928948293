import { Box, Sx, createStyles } from "@mantine/core"
import { ReactNode } from "react"

type FlexDirection = "column" | "column-reverse" | "row" | "row-reverse" | undefined
type PositionProps = "space-between" | "space-around" | "center"
type AlignProps = "center" | "baseline" | "flex-start"
type FlexWrapProps = "nowrap" | "wrap" | "wrap-reverse"

interface FlexProps {
  align?: AlignProps
  position?: PositionProps
  direction?: FlexDirection
  children: ReactNode
  className?: string
  flexWrap?: FlexWrapProps
  sx?: Sx
  ml?: string | number
  mt?: string | number
  mb?: string | number
  mr?: string | number
  // All other props
  [x: string]: any;
}

const useStyles = createStyles(() => ({
  flexColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRowReverse: {
    flexDirection: "row-reverse",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignBaseline: {
    alignItems: "baseline",
  },
  alignFlexstart: {
    alignItems: "flex-start",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyAround: {
    justifyContent: "space-around",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  flexNoWrap: {
    flexWrap: "nowrap",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
}));

const Flex = ({
  align = "center",
  position,
  children,
  direction = "row",
  className = "",
  flexWrap,
  sx = {},
  ml = 0,
  mt = 0,
  mb = 0,
  mr = 0,
  ...props
}: FlexProps) => {
  const { classes } = useStyles();

  let classNames = className;

  switch (direction) {
    case "row":
      classNames += ` ${classes.flexRow}`
      break;
    case "row-reverse":
      classNames += ` ${classes.flexRowReverse}`
      break;
    case "column":
      classNames += ` ${classes.flexColumn}`
      break;
    default:
      break;
  }

  switch (align) {
    case "center":
      classNames += ` ${classes.alignCenter}`
      break;
    case "baseline":
      classNames += ` ${classes.alignBaseline}`
      break;
    case "flex-start":
      classNames += ` ${classes.alignFlexstart}`
      break;
    default:
      break;
  }

  switch (position) {
    case "center":
      classNames += ` ${classes.justifyCenter}`
      break;
    case "space-around":
      classNames += ` ${classes.justifyAround}`
      break;
    case "space-between":
      classNames += ` ${classes.justifyBetween}`
      break;
    default:
      break;
  }

  switch (flexWrap) {
    case "wrap":
      classNames += ` ${classes.flexWrap}`
      break;
    case "nowrap":
      classNames += ` ${classes.flexNoWrap}`
      break;
    default:
      break;
  }

  return (
    <Box
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      display="flex"
      className={classNames}
      sx={sx}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Flex
