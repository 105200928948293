import { Text, Tooltip, useMantineTheme } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'wouter';
import TextColor from 'src/utils/textColor';
import { RootState } from 'src/redux/store';
import FormattedName from '@components/watchlist/formattedName';
import { instrumentSearch } from 'src/utils';
import Flex from '../atoms/flex/flex';
import useStyles from './styles';

interface NiftyHeaderProps {
  item: {
    symbol: string;
    token: number;
    exchange: string;
    segment: string;
  }
}

const AppBarSymbol = ({ item }: NiftyHeaderProps) => {
  const {
    symbol, token, segment, exchange,
  } = item;
  const { cx, classes } = useStyles();
  const { quoteSocketData } = useSelector((state: RootState) => state.sockets);
  const [, setLocation] = useLocation();

  const currentSocket = quoteSocketData[token] || {};

  const { netChange = 0 } = currentSocket || {}

  const theme = useMantineTheme();

  const handleChart = (selectedSymbol: string, selectedExchange: string) => {
    if (token) {
      setLocation(`/charts/${selectedExchange}/${selectedSymbol}/${token}`);
    }
  };

  const parsedSymbolObj = instrumentSearch.get(symbol, segment, exchange)

  const largeText = symbol.length > 14

  return (
    <Flex
      sx={{ width: "48%", cursor: "pointer", justifyContent: "center" }}
      direction={largeText ? "column" : "row"}
      align={largeText ? "flex-start" : "center"}
      onClick={() => handleChart(symbol, exchange)}
    >
      <Text mr={6}>
        <Tooltip
          label="Open chart"
          transitionProps={{ transition: 'slide-down', duration: 100 }}
        >
          <div>
            <FormattedName
              name={parsedSymbolObj.niceName}
              expiryDay={parsedSymbolObj.expiryDay}
              isWeekly={parsedSymbolObj.isWeekly}
              size="xs"
            />
          </div>
        </Tooltip>
      </Text>
      <Flex mt={largeText ? 3 : 0}>
        <Text
          sx={TextColor(netChange)}
          className={cx(
            classes.changeText
          )}
        >
          {(Number(currentSocket.ltp) / 100 || 0).toFixed(2)}
        </Text>
        <Text className={cx(classes.changeText, classes.netChange)} ml={4} color={theme.colors.colorLight[0]}>
          {netChange.toFixed(2)}
          {' '}
          <span className={classes.percentage}>%</span>
        </Text>
      </Flex>
    </Flex>
  );
};

export default AppBarSymbol
