import { createStyles, getStylesRef, keyframes } from "@mantine/core"
import { getTypographyStyles } from "@theme/themeConfig"

export const bounce = keyframes({
  'from, 0%, 53%, 80%, to': { transform: 'translateX( 0)' },
  '40%, 43%': { transform: 'translateX( 1.875rem)' },
  '70%': { transform: 'translateX( 0.9375rem)' },
  '90%': { transform: 'translateX( 0.25rem)' },
});

const useStyles = createStyles((theme) => {
  const { colors } = theme.other
  return {
    itemContainer: {
      width: "100%",
      position: "relative",
      "&:hover": {
        [`& .${getStylesRef("mg")}`]: {
          display: "flex",
        },
      },
    },
    item: {
      ...theme.fn.focusStyles(),
      borderBottom:
        theme.colorScheme === "dark" ? "1px solid #232325" : "1px solid #eee",
      padding: `${theme.spacing.sm} ${theme.spacing.md}`,
      backgroundColor: colors.base.white,
    },
    styledSelectedWatchlistItem: {
      boxShadow: `inset 1px -2px 0px 0px ${
        theme.colorScheme === "dark" ? theme.colors.dark[6] : "#eee"
      }`,
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.background1,
      // fontWeight: 600,
    },
    itemDragging: {
      boxShadow: theme.shadows.sm,
    },
    watchlistContainer: {
      scrollBehavior: "smooth",
      position: "relative",
      height: "calc(100vh - 56px)",
      // height: "100%",
      backgroundColor: colors.base.white,
    },
    watchlistItems: {
      height: "calc(100vh - 160px)",
    },
    tradeContentContainer: {
      border: `1px solid ${colors.disabled.muted}`,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    tradeContentInnerContainer: {
      padding: "0.8rem 1rem 1rem 1rem",
    },
    tradeFooterContainer: {
      padding: "0.5rem 1rem",
      backgroundColor: colors.base.white,
      // borderTop: "1px solid #eee",
      display: "flex",
      margin: 0,
    },
    tradeItemContainer: {
      marginTop: 16,
      display: "flex",
      justifyContent: "space-between",
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        flexDirection: "column",
      },
      [`input:focus`]: {
        border: `0.0625rem solid ${theme.colors.gray[5]}`,
      },
    },
    tradeItem: {
      width: "32%",
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        width: "100%",
        marginBottom: 24,
      },
    },
    tradeFooterBtnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        justifyContent: "flex-start",
      },
    },
    tabLabel: {
      ...getTypographyStyles("content_xs_regular"),
    },

    // search watchlist
    searchInputContainer: {
      position: "fixed",
      top: 56,
      width: "25.875rem",
      zIndex: 1,
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        width: "100%",
      },
    },
    searchIcon: {
      position: "absolute",
      left: 16,
      top: 15,
      fontSize: 10,
    },
    wlRightIcon: {
      position: "absolute",
      right: 16,
      top: 15,
      cursor: "pointer",
    },
    searchInput: {
      width: "100%",
      padding: "15px 10px 15px 40px",
      border: "none",
      borderBottom:
        theme.colorScheme === "dark" ? "1px solid #232325" : "1px solid #eee",
      fontSize: 14,
      ":focus": {
        outline: "none",
      },
      color: theme.colorScheme === "dark" ? "#C1C2C5" : theme.colors.dark[7],
      backgroundColor: colors.base.white,
    },
    searchItemContainer: {
      display: "block",
      cursor: "pointer",
      position: "relative",
      padding: "6px 14px",
      borderBottom:
        theme.colorScheme === "dark" ? "1px solid #232325" : "1px solid #eee",
      "&:hover": {
        [`& .${getStylesRef("mg")}`]: {
          display: "flex",
          transition: "display 0.1s",
        },
      },
    },
    checkIcon: {
      height: 21,
      lineHeight: "1.8",
    },
    searchContainer: {
      marginTop: 47,
      overflowY: "scroll",
      // height: 'calc(100vh - 102px)',
    },
    searchSelectedItem: {
      color: theme.colors.gray[6],
    },
    searchItemInner: {
      display: "flex",
      // fontSize: 14,
      ...getTypographyStyles("content_xs_medium"),
    },
    searchItemCompany: {
      fontSize: 10,
    },
    animateItem: {
      animation: `${bounce} 700ms ease-in-out`,
      animationIterationCount: 1,
    },
    scrollY: {
      overflowY: "auto",
      height: "calc(100% - 87.8px)",
      overflowX: "hidden",
    },
    scrollYUnset: {
      overflowY: "unset",
    },
  };
});

export default useStyles
