import {
  Badge, Box, Button, Divider, Group, Modal, Radio, Text, useMantineTheme,
} from "@mantine/core"
import { useEffect, useState } from "react"
import { InfoCircle } from "tabler-icons-react"
import { useDispatch, useSelector } from "react-redux"
import { showNotification } from "@mantine/notifications"
import { RootState } from "src/redux/store"
import usePrevious from "src/hooks/common/usePrevious"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { ICombinedOrderDetail } from "@redux/sagas/orders"
import {
  editGttOcoOrder,
  gttOcoOrder,
  gttSingleOrder,
  editGttSingleOrder,
  gttDetails,
} from "@redux/sagas/orders/ordersSlice"
import { setGttModalInfo } from "@redux/sagas/misc/miscSlice"
import { setQuoteTokens } from "@redux/sagas/sockets/socketsSlice"
import { selectQuoteSockets } from "@redux/sagas/sockets/socketSelectors"
import TriggerCncLimitPrice from "./TriggerCncLimit"
import { FAILURE, SUCCESS, gttOrderConst } from '../../../../utils/constant'
import { capitalizeFirstLetter, isEqual } from "../../../../utils"

interface OrderDetailsModalProps {
  isVisible: boolean
  selectedOrderDetails: ICombinedOrderDetail
}

const GttModal = ({
  isVisible,
  selectedOrderDetails,
}: OrderDetailsModalProps) => {
  const { isMobile } = useMediaCustom();
  const theme = useMantineTheme();

  const { colors } = theme.other;

  const dispatch = useDispatch();

  const quoteSocketsData = useSelector(selectQuoteSockets);

  const {
    gttSingle,
    editGttOco,
    gttOco,
  } = useSelector((state: RootState) => state.order)
  const {
    gttModalInfo: { isEdit, gttOrderDetails },
  } = useSelector((state: RootState) => state.misc);

  const prevProps = usePrevious({ gttSingle, editGttOco, gttOco })

  const [pGttQuantity, setPGttQuantity] = useState(1);
  const [nGttQuantity, setNGttQuantity] = useState(1);
  const [pTriggerPrice, setPTriggerPrice] = useState(0);
  const [nTriggerPrice, setNTriggerPrice] = useState(0);

  const [pGttPrice, setPGttPrice] = useState(0);
  const [nGttPrice, setNGttPrice] = useState(0);

  const [transactionType, setTransactionType] = useState("B");
  const [triggerType, setTriggerType] = useState(gttOrderConst.SINGLE);

  const quoteData = quoteSocketsData[Number(gttOrderDetails.token)]

  const editGttSingleType = gttOrderDetails.oiVariable?.length === 1

  const { ltp = 0 } = quoteData || {}
  const ltpPrice = (ltp / 100)

  const [pLtpPercent, setPLtpPercent] = useState(5)
  const [nLtpPercent, setNLtpPercent] = useState(-5)

  useEffect(() => {
    // price: 5% of ltp, round off to nearest 0.05 multiple
    setPGttPrice(Math.ceil(((ltpPrice * pLtpPercent) / 100 + ltpPrice) / 0.05) * 0.05);
    setNGttPrice(Math.ceil(((ltpPrice * nLtpPercent) / 100 + ltpPrice) / 0.05) * 0.05);
  }, [ltpPrice])

  // initial 5% of ltp / change of ltp value for +ve
  useEffect(() => {
    const changedByLtpVal = (ltpPrice * pLtpPercent) / 100 + ltpPrice
    setPTriggerPrice(changedByLtpVal)
  }, [ltpPrice, pLtpPercent])

  // initial 5% of ltp / change of ltp value for -ve
  useEffect(() => {
    const changedByLtpVal = ltpPrice + (ltpPrice * nLtpPercent) / 100
    setNTriggerPrice(changedByLtpVal)
  }, [ltpPrice, nLtpPercent])

  useEffect(() => {
    if (isEdit) {
      setTransactionType(gttOrderDetails.transactionType)
      setTriggerType(editGttSingleType ? gttOrderConst.SINGLE : gttOrderConst.OCO)
    }
  }, [editGttSingleType, gttOrderDetails.transactionType, isEdit])

  useEffect(() => {
    const selectedGttToken = gttOrderDetails.token
    if (!quoteData && selectedGttToken) {
      dispatch(setQuoteTokens({ [selectedGttToken]: 1 }))
    }
    return () => {
      dispatch(setQuoteTokens({ [selectedGttToken]: 0 }))
    }
  }, [dispatch, quoteData, gttOrderDetails.token])

  const handleCloseModal = () => {
    dispatch(setGttModalInfo({ showGttModal: false, gttOrderDetails: {} }))
  }

  const handleGttOrder = () => {
    const checkEQ = selectedOrderDetails.symbol.includes("-EQ")
    const ocoPayload = {
      exchange: selectedOrderDetails.exchange,
      symbol: checkEQ ? selectedOrderDetails.symbol : `${selectedOrderDetails.symbol}-EQ`,
      validity: "GTT", // hardcoded
      alertType: "LMT_BOS_O", // hardcoded
      variables: [
        {
          name: "x",
          value: pTriggerPrice.toString(),
        },
        {
          name: "y",
          value: nTriggerPrice.toString(),
        },
      ],
      leg1: {
        transactionType,
        order: "LMT", // hardcoded
        product: "C", // hardcoded
        retention: "GTT", // hardcoded
        remarks: "",
        qty: pGttQuantity.toString(),
        price: pGttPrice.toString(),
        triggerPrice: "",
      },
      leg2: {
        transactionType,
        order: "LMT", // hardcoded
        product: "C", // hardcoded
        retention: "GTT", // hardcoded
        remarks: "",
        qty: nGttQuantity.toString(),
        price: nGttPrice.toString(),
        triggerPrice: "",
      },
    }

    const singlePayload = {
      exchange: selectedOrderDetails.exchange,
      symbol: checkEQ ? selectedOrderDetails.symbol : `${selectedOrderDetails.symbol}-EQ`,
      alertType: pGttPrice > ltpPrice ? "LTP_A_O" : "LTP_B_O", // >ltp
      variables: [
        {
          name: "x",
          value: pTriggerPrice.toString(),
        },
      ],
      leg1: {
        qty: pGttQuantity.toString(),
        disclosedQty: "0",
        product: "C",
        transactionType,
        order: "LMT",
        price: pGttPrice.toString(),
        validity: "GTT",
        triggerPrice: "",
        remarks: "",
        retention: "GTT",
        disclosedQuantity: "0",
      },
    }

    // update
    if (isEdit) {
      if (!editGttSingleType) {
        dispatch(editGttSingleOrder({ data: singlePayload, id: gttOrderDetails.alertID }))
      } else {
        dispatch(editGttOcoOrder({ data: ocoPayload, id: gttOrderDetails.alertID }))
      }
      return
    }

    if (gttOrderConst.SINGLE === triggerType) {
      dispatch(gttSingleOrder(singlePayload));
    } else {
      dispatch(gttOcoOrder(ocoPayload))
    }
  }

  // handle single order status
  useEffect(() => {
    if (!isEqual(prevProps.gttSingle.status, gttSingle.status) && gttSingle.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(gttSingle.message),
        message: 'GTT Single Failed',
      })
    } else if (!isEqual(prevProps.gttSingle.status, gttSingle.status) && gttSingle.status === SUCCESS) {
      showNotification({
        title: "Created",
        message: "GTT created successfully",
      })
      dispatch(gttDetails([]))
      handleCloseModal()
    }
  }, [prevProps, gttSingle])

  // handle edit order status
  useEffect(() => {
    if (!isEqual(prevProps.editGttOco.status, editGttOco.status) && editGttOco.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(editGttOco.message),
        message: "Order failed",
      })
    } else if (!isEqual(prevProps.editGttOco.status, editGttOco.status) && editGttOco.status === SUCCESS) {
      showNotification({
        title: "GTT order updated",
        message: "",
      })
    }
  }, [editGttOco, prevProps])

  // handle oco order status
  useEffect(() => {
    if (!isEqual(prevProps.gttOco.status, gttOco.status) && gttOco.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(gttOco.message),
        message: 'GTT OCO Failed',
      })
    } else if (!isEqual(prevProps.gttOco.status, gttOco.status) && gttOco.status === SUCCESS) {
      showNotification({
        title: "Created",
        message: "GTT created successfully",
      })
      dispatch(gttDetails([]))
      handleCloseModal()
    }
  }, [prevProps, gttOco])

  return (
    <Modal
      onClose={() => { }}
      opened={isVisible}
      size="lg"
      withCloseButton={false}
      padding={0}
      fullScreen={!!isMobile}
    >
      {/* modal header */}
      <Group position="apart" p="lg">
        <Group spacing="xs">
          <Text size={18}>{selectedOrderDetails.symbol}</Text>
          <Badge radius="xs">{selectedOrderDetails.exchange}</Badge>
          <Text size={12} c="dimmed">{ltpPrice}</Text>
        </Group>
        <Group>
          <InfoCircle size={18} color={colors.blue[4]} />
          GTT
        </Group>
      </Group>
      <Divider />

      {/* transaction/trigger radio */}
      <Group align="flex-start" p="lg">
        <Box w={isMobile ? "100%" : "35%"}>
          <Text size="sm">Transaction type</Text>
          <Radio.Group
            className="gtt-modal"
            value={transactionType}
            onChange={setTransactionType}
            name="transactionType"
            size="xs"
          >
            <Group mt="xs">
              <Radio
                color={transactionType === "B" ? "blue" : "red"}
                value="B"
                label="Buy"
                disabled={triggerType === gttOrderConst.OCO}
              />
              <Radio
                color={transactionType === "B" ? "blue" : "red"}
                value="S"
                label="Sell"
              />
            </Group>
          </Radio.Group>
        </Box>

        <Box w={isMobile ? "100%" : "60%"}>
          <Text size="sm">Trigger type</Text>
          <Radio.Group
            styles={{ root: { textAlign: 'right' } }}
            className="gtt-modal"
            value={triggerType}
            onChange={setTriggerType}
            name="triggerType"
            size="xs"
          >
            <Group mt="xs">
              <Radio
                color={transactionType === "B" ? "blue" : "red"}
                disabled={isEdit}
                value={gttOrderConst.SINGLE}
                label="Single"
              />
              <Radio
                color={transactionType === "B" ? "blue" : "red"}
                disabled={transactionType === "B" || isEdit}
                value={gttOrderConst.OCO}
                label="OCO"
              />
            </Group>
          </Radio.Group>

          <Text
            mt="sm"
            color={colors.fg.subtle}
            size={10}
          >
            The order is placed when the Last Traded Price (LTP)
            crosses the trigger price. Can be used to enter or exit a position.
          </Text>
        </Box>
      </Group>
      <Divider />

      {/* trigger price, cnc/limit price */}
      {
        gttOrderConst.OCO === triggerType && (
          <>
            <TriggerCncLimitPrice
              ltpPercent={nLtpPercent}
              setLtpPercent={setNLtpPercent}
              gttPrice={nGttPrice}
              setGttPrice={setNGttPrice}
              gttQuantity={nGttQuantity}
              setGttQuantity={setNGttQuantity}
              triggerPrice={nTriggerPrice}
              setTriggerPrice={setNTriggerPrice}
              badgeValue="Stoploss"
            />
            <Divider />
          </>
        )
      }
      <TriggerCncLimitPrice
        ltpPercent={pLtpPercent}
        setLtpPercent={setPLtpPercent}
        gttPrice={pGttPrice}
        setGttPrice={setPGttPrice}
        gttQuantity={pGttQuantity}
        setGttQuantity={setPGttQuantity}
        triggerPrice={pTriggerPrice}
        setTriggerPrice={setPTriggerPrice}
        badgeValue={triggerType === gttOrderConst.OCO ? "Target" : ""}
      />

      {/* modal button */}
      <Divider />
      <Group position={isMobile ? "right" : "apart"} align="center" p="lg">
        <Text
          w={isMobile ? "100%" : "60%"}
          color={colors.fg.subtle}
          size={10}
        >
          By placing, I agree to the terms and accept that trigger executions are not guaranteed.
        </Text>

        <Box>
          <Button variant="outline" color={transactionType === "B" ? "blue" : "red"} onClick={handleGttOrder}>
            Place
          </Button>
          <Button
            variant="outline"
            color="gray"
            ml="sm"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </Box>
      </Group>
    </Modal>
  )
}

export default GttModal
