import primaryPalette from './primaryPalette.json';
import secondaryPalette from './secondaryPalette.json';
import { PrimaryColorsType, SecondaryColorsType } from './types';

// Function to convert tokens from json file to js object
const jsonToObject = (tokensObject) => Object.keys(tokensObject).reduce((acc, curr) => {
  const currentColor = tokensObject[curr];
  const currentColorObject = {};
  Object.keys(currentColor).forEach((shade) => {
    currentColorObject[`${shade}`] = currentColor[shade].value;
    return currentColorObject;
  });
  return { ...acc, [curr]: currentColorObject };
}, {});

const primaryColors = jsonToObject(primaryPalette) as PrimaryColorsType;
const secondaryColors:any = jsonToObject(secondaryPalette) as SecondaryColorsType;

export const lightThemeTokens = {
  bg: {
    default: primaryColors.gray['25'],
    subtle: primaryColors.gray['50'],
    muted: primaryColors.gray['100'],
  },
  fg: {
    default: primaryColors.gray['900'],
    subtle: primaryColors.gray['600'],
    muted: primaryColors.gray['400'],
  },
  primary: {
    dark: primaryColors.primary['700'],
    default: primaryColors.primary['500'],
    subtle: primaryColors.primary['100'],
    muted: primaryColors.primary['25'],
  },
  error: {
    dark: primaryColors.error['700'],
    default: primaryColors.error['500'],
    subtle: primaryColors.error['200'],
    muted: primaryColors.error['100'],
  },
  warning: {
    default: primaryColors.warning['600'],
    subtle: primaryColors.warning['50'],
  },
  success: {
    default: primaryColors.success['600'],
    subtle: primaryColors.success['100'],
  },
  disabled: {
    default: primaryColors.gray['400'],
    subtle: primaryColors.gray['300'],
    muted: primaryColors.gray['200'],
  },
  blue: {
    dark: secondaryColors.blue['800'],
    default: secondaryColors.blue['600'],
    subtle: secondaryColors.blue['400'],
    muted: secondaryColors.blue['200'],
    light: secondaryColors.blue['50'],
  },
  base: {
    white: '#ffffff',
    whiteFixed: primaryColors.gray['25'],
  },
};

export const darkThemeTokens = {
  bg: {
    default: primaryColors.gray['900'],
    subtle: primaryColors.gray['800'],
    muted: primaryColors.gray['800'],
  },
  fg: {
    default: primaryColors.gray['100'],
    subtle: primaryColors.gray['400'],
    muted: primaryColors.gray['500'],
  },
  primary: {
    dark: primaryColors.primary['700'],
    default: primaryColors.primary['500'],
    subtle: primaryColors.gray['700'],
    muted: primaryColors.gray['800'],
  },
  error: {
    dark: primaryColors.error['700'],
    default: primaryColors.error['500'],
    subtle: primaryColors.gray['800'],
    muted: primaryColors.gray['800'],
  },
  warning: {
    default: primaryColors.warning['400'],
    subtle: primaryColors.gray['800'],
  },
  success: {
    default: primaryColors.success['400'],
    subtle: primaryColors.gray['800'],
  },
  disabled: {
    default: primaryColors.gray['600'],
    subtle: primaryColors.gray['700'],
    muted: primaryColors.gray['800'],
  },
  blue: {
    dark: secondaryColors.blue['600'],
    default: secondaryColors.blue['500'],
    subtle: primaryColors.gray['700'],
    muted: primaryColors.gray['800'],
    light: primaryColors.gray['800'],
  },
  base: {
    white: primaryColors.gray['900'],
    whiteFixed: primaryColors.gray['25'],
  },
};

export const lightThemeColors = {
  ...primaryColors,
  ...lightThemeTokens,
};

export const darkThemeColors = {
  ...secondaryColors,
  ...darkThemeTokens,
}

// utility function to flatten object
function flattenObj(obj, parent, res = {}) {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const propName = parent ? `${parent}_${keys[i]}` : keys[i];
    if (typeof obj[keys[i]] === 'object') {
      flattenObj(obj[keys[i]], propName, res);
    } else {
      res[propName] = obj[keys[i]];
    }
  }

  return res;
}

// to make class names and is used in GlobalStyles Component
export const getThemeClasses = (theme) => {
  const classes = {};
  const textColors = flattenObj(theme?.other?.colors, '.text', {});
  const bgColors = flattenObj(theme?.other?.colors, '.bg', {});
  const borderColors = flattenObj(theme?.other?.colors, '.border', {});
  const textColorsKeys = Object.keys(textColors)
  const bgColorsKeys = Object.keys(bgColors)
  const borderColorsKeys = Object.keys(borderColors)

  for (let i = 0; i < textColorsKeys.length; i += 1) {
    classes[textColorsKeys[i]] = {
      color: textColors[textColorsKeys[i]],
    };
  }

  for (let i = 0; i < bgColorsKeys.length; i += 1) {
    classes[bgColorsKeys[i]] = {
      backgroundColor: bgColors[bgColorsKeys[i]],
    };
  }

  for (let i = 0; i < borderColorsKeys.length; i += 1) {
    classes[borderColorsKeys[i]] = {
      borderColor: borderColors[borderColorsKeys[i]],
    };
  }

  return classes;
};

// To generate color palette for mantine
// Primary Palette

// type PrimaryColors =

export const getMantinePrimaryColorsArray = ():Record<keyof PrimaryColorsType,
[string, string, string, string, string, string, string, string, string, string]> => Object.keys(primaryColors)
  .reduce((acc: any, item: any) => {
    if (item === 'base') {
      return acc;
    }
    const obj: any = {};
    obj[item] = Object.values(primaryColors[item]).slice(1);
    return { ...acc, ...obj };
  }, {});
// Secondary Palette
export const getMantineSecondaryColorsArray = ():Record<keyof SecondaryColorsType,
[string, string, string, string, string, string, string, string, string, string]> => Object.keys(secondaryColors)
  .reduce((acc: any, item: any) => {
    if (item === 'base') {
      return acc;
    }
    const obj: any = {};
    obj[item] = Object.values(secondaryColors[item]).slice(1);
    return { ...acc, ...obj };
  }, {});

// Fonts

const contentFontSizes = {
  '3xs': 0.5,
  '2xs': 0.625,
  xs: 0.813,
  sm: 0.875,
  md: 1,
  lg: 1.125,
  xl: 1.25,
  '2xl': 1.5,
  '3xl': 1.875,
  '4xl': 2.25,
};

const contentLineHeights = {
  '2xs': 1.125,
  xs: 1.25,
  sm: 1.25,
  md: 1.5,
  lg: 1.75,
  xl: 1.875,
  '2xl': 2,
  '3xl': 2.375,
  '4xl': 2.75,
};

const headingsFontSizes = {
  '3xs': 0.75,
  '2xs': 0.875,
  xs: 1,
  sm: 1.125,
  md: 1.25,
  lg: 1.5,
  xl: 1.875,
  '2xl': 2.25,
};

const headingsLineHeights = {
  '2xs': 1.5,
  xs: 1.5,
  sm: 1.75,
  md: 1.875,
  lg: 2,
  xl: 2.375,
  '2xl': 2.75,
};

const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const spacing = '-0.02em';
const spacingVariant = ['md', 'lg', 'xl', 'xxl'];
const headingsFontFamily = 'Open Sans, sans-serif';
const contentFontFamily = 'Open Sans, sans-serif';
const getLetterSpacing = (size) => {
  if (spacingVariant.includes(size)) {
    return {
      letterSpacing: spacing,
    };
  }
  return {};
};

export const getTextStyles = () => {
  const classes = {};
  const fontWeightsKeys = Object.keys(fontWeights);
  // Content Font
  const contentFontSizesKeys = Object.keys(contentFontSizes);
  for (let i = 0; i < contentFontSizesKeys.length; i += 1) {
    for (let j = 0; j < fontWeightsKeys.length; j += 1) {
      classes[`.content_${contentFontSizesKeys[i]}_${fontWeightsKeys[j]}`] = {
        fontSize: `${contentFontSizes[contentFontSizesKeys[i]]}rem`,
        fontFamily: contentFontFamily,
        fontWeight: `${fontWeights[fontWeightsKeys[j]]}`,
        lineHeight: `${contentLineHeights[contentFontSizesKeys[i]]}rem`,
      };
    }
  }
  // // Headings Font
  const headingsFontSizesKeys = Object.keys(headingsFontSizes);
  for (let i = 0; i < headingsFontSizesKeys.length; i += 1) {
    for (let j = 0; j < fontWeightsKeys.length; j += 1) {
      classes[`.heading_${headingsFontSizesKeys[i]}_${fontWeightsKeys[j]}`] = {
        fontSize: `${headingsFontSizes[headingsFontSizesKeys[i]]}rem`,
        fontFamily: headingsFontFamily,
        fontWeight: `${fontWeights[fontWeightsKeys[j]]}`,
        lineHeight: `${headingsLineHeights[headingsFontSizesKeys[i]]}rem`,
        ...getLetterSpacing(headingsFontSizesKeys[i]),
      };
    }
  }

  // classes['.text_rupee'] = {
  //   fontFamily: rupeeFont,
  //   fontWeight: 500,
  // };
  return classes;
};

export const getTypographyStyles = (variant) => {
  const classes = getTextStyles();
  const res = classes[`.${variant}`];
  return res;
};
