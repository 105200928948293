import { createStyles } from '@mantine/core';
import { Icon } from 'tabler-icons-react';
import Flex from './flex/flex'

interface ITitleIcon {
  icon: Icon,
  title: string
  [x: string]: any;
}

const useStyles = createStyles(() => ({
  title: {
    marginLeft: 6,
  },
}));

const TitleIcon = ({ icon: IconElement, title, ...props }: ITitleIcon) => {
  const { classes, cx } = useStyles();
  return (
    <Flex className="cursor-pointer" {...props}>
      {/* <Icon size="20" /> */}
      <IconElement size={12} className="text_primary_default" />
      <p className={cx(classes.title, 'heading_3xs_semibold text_primary_default')}>{title}</p>
    </Flex>
  )
}

export default TitleIcon
