import Flex from '@components/atoms/flex/flex';
import { Text, useMantineTheme } from '@mantine/core';
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes';
import { getSegment, instrumentSearch } from '@utils/index';
import FormattedName from '@components/watchlist/formattedName';
import HoldingsDropdown from '@containers/holdings/holdingsDropdown';

interface IHoldingIntrument {
  row: HoldingItem,
  width?: number,
}

const HoldingIntrument = ({
  row,
  width = 200,
}: IHoldingIntrument) => {
  const theme = useMantineTheme()
  const { symbol, exchange } = row;
  const segment = getSegment(symbol, exchange);
  const instrumentName = instrumentSearch.tokenMap[row.token]
  const parsedSymbolObj = instrumentName ? instrumentSearch.get(instrumentName, segment, exchange) : null

  return (
    <div
      style={{ width: `${width}px` }}
      className="holding-intrument"
    >
      <Flex align="baseline">
        {
          instrumentName ? (
            <FormattedName
              name={parsedSymbolObj?.niceName}
              expiryDay={parsedSymbolObj?.expiryDay || 0}
              isWeekly={parsedSymbolObj?.isWeekly}
            />
          ) : (
            <p>N/A</p>
          )
        }
        <Text size={10} ml={3} color={theme.other.colors.fg.muted}>
          {row.exchange}
        </Text>
      </Flex>
      <HoldingsDropdown row={row} />
    </div>
  )
}

export default HoldingIntrument
