import {
  Box, Group, NumberInput, Radio, Tabs, Text,
} from "@mantine/core"
import {
  memo, useEffect, useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown, ChevronUp } from "tabler-icons-react"
import { useFocusTrap } from '@mantine/hooks';
import { RootState } from "src/redux/store";
import useOrderWL from "@hooks/common/useOrderWL"
import useTradeMarginWL from "@hooks/common/useTradeWL"
import {
  processOrderInitialState, setProceedOrder, setTradeModalInfo, clearOrderMargin,
} from "@redux/sagas/trades/tradesSlice"
import {
  exchanges, OrderTypeML, OrderTypeSLM, productOptions, ValidityRetention, varietyValues,
} from "../../../utils/constant"
import CustomDraggable from "../../customDraggable"
import useStyles from "../styled"
import {
  handlePriceState,
  handleTriggerPriceError,
  handleTriggerPriceState,
} from "../utils"
import GttTradePart from "./gttTrade"
import TradeModalFooter from "./tradeFooter"
import TraderHeader from "./tradeHeader"
import ValidityOptions from "./validityOptions"

interface IOrderPreferences {
  isValidityOption: boolean
  orderType: string
  product: string
  variety: string
}

const TradeContent = () => {
  const { cx, classes } = useStyles()
  const dispatch = useDispatch();

  const {
    processOrderInfo: { processOrder, selectedToken },
  } = useSelector((state: RootState) => state.trade);

  // const { ltp } = useGetSocketVal(currentAssetItem?.token || 0)
  const {
    tradeModalInfo: {
      currentAssetItem,
      buySellToggle,
      quantity: tradeQuantity,
    },
  } = useSelector((state: RootState) => state.trade);

  const [productValue, setProductValue] = useState("")
  const [orderTypeMLVal, setOrderTypeMLVal] = useState("")
  const [activeTab, setActiveTab] = useState("")
  const [showValidityOption, setShowValidityOption] = useState<Boolean | null>(null)
  const [validityVal, setValidityVal] = useState(ValidityRetention.DAY)
  // const [heightTop, setHeightTop] = useState(0)
  const [disclosedQty, setDisclosedQty] = useState(0)
  const [priceField, setPriceField] = useState(currentAssetItem?.price || currentAssetItem.ltp)
  const [triggerPriceField, setTriggerPriceField] = useState(currentAssetItem.ltp)
  const [selectedExchange, setSelectedExchange] = useState(exchanges.NSE)

  const [stoplossState, setStoplossState] = useState(false)
  const [targetState, setTargetState] = useState(false)
  const [quantity, setQuantity] = useState<number>(1)
  const [reloadOrderMargin, setReloadOrderMargin] = useState<boolean>(false)

  const [ltp, setLtp] = useState(0)

  const focusTrapRef = useFocusTrap();

  const { handleOrder, handlePriceVal, handleTriggerPriceVal } = useOrderWL({
    orderTypeMLVal,
    priceField,
    activeTab,
    triggerPriceField,
  })

  const { marginUsed, charge } = useTradeMarginWL({
    selectedExchange: currentAssetItem.exchange || selectedExchange,
    token: currentAssetItem.token,
    quantity,
    priceField,
    buySellToggle,
    orderTypeMLVal,
    reloadOrderMargin,
    productValue,
    activeTab,
  })

  const orderPreferences: IOrderPreferences = JSON.parse(localStorage.getItem("orderPreferences") || "{}")
  const {
    isValidityOption, orderType, product, variety,
  } = orderPreferences

  useEffect(() => {
    if (orderType && product && variety) {
      setShowValidityOption(isValidityOption)
      setOrderTypeMLVal(orderType)
      setProductValue(product)
      setActiveTab(variety)
    } else {
      localStorage.setItem("orderPreferences", JSON.stringify({
        isValidityOption: (isValidityOption || showValidityOption) ? isValidityOption : false,
        orderType: orderType || OrderTypeML[0].value,
        product: product || productOptions[0].value,
        variety: variety || varietyValues.REGULAR,
      }))
    }
  }, [isValidityOption, orderType, product, variety]);

  const handleProductValue = (val: string) => {
    setProductValue(val)
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      product: val,
    }))
  }

  useEffect(() => {
    if (exchanges.NFO === currentAssetItem.exchange) {
      setActiveTab(varietyValues.REGULAR);
      handleProductValue("I");
    }
  }, [currentAssetItem.exchange])

  const handleOrderType = (val: string) => {
    setOrderTypeMLVal(val)
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      orderType: val,
    }))
  }

  const handleTabChange = (tab: string) => {
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      variety: tab,
      orderType: OrderTypeML[0].value,
      product: productOptions[0].value,
    }))
    setActiveTab(tab)
    // setOrderTypeMLVal(OrderTypeML[0].value)
    setValidityVal(ValidityRetention.DAY)
    // setProductValue(productOptions[0].value)
  }

  const handleResetTradeModalInfo = () => {
    dispatch(clearOrderMargin())
    dispatch(setProceedOrder(processOrderInitialState));
  }

  const handleCancelTrade = () => {
    dispatch(
      setTradeModalInfo({
        buySellToggle: false,
        showTradeModal: false,
        currentAssetItem: {},
      })
    );
    handleResetTradeModalInfo()
  }

  const handleProceedOrder = (token: number) => {
    handleOrder({
      token: token || currentAssetItem.token,
      selectedExchange: currentAssetItem.exchange || selectedExchange,
      quantity,
      disclosedQty,
      productValue,
      buySellToggle,
      orderTypeMLValue: orderTypeMLVal,
      validityVal,
    })
  }

  useEffect(() => {
    if (selectedToken !== 0) {
      handleProceedOrder(selectedToken)
    }
    dispatch(setProceedOrder(processOrderInitialState));
  }, [processOrder])

  const lotsize = currentAssetItem.lotSize
  useEffect(() => {
    if (tradeQuantity) {
      setQuantity(Math.abs(tradeQuantity))
    } else if (lotsize) {
      setQuantity(lotsize)
    }
  }, [tradeQuantity, lotsize])

  const currentAssetLtp = currentAssetItem.price || currentAssetItem.ltp
  useEffect(() => {
    setPriceField(currentAssetLtp)
    setTriggerPriceField(currentAssetLtp)
  }, [currentAssetLtp])

  useEffect(() => {
    setPriceField(currentAssetItem.price || ltp)
    setTriggerPriceField(ltp)
  }, [ltp])

  const handleValidityOption = () => {
    setShowValidityOption(!showValidityOption)
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      isValidityOption: !showValidityOption,
    }))
    setValidityVal(ValidityRetention.DAY)
    // setHeightTop(30)
  }

  const handleOrderMargin = () => {
    setReloadOrderMargin((prevState) => !prevState)
  }
  const separateText = (text:string) => {
    const textValue = text.split(' ')
    return (
      <p>
        {textValue[0]}
        {' '}
        <span className="text_fg_muted">
          {' '}
          {textValue[1]}
          {' '}
        </span>
        {' '}
      </p>
    )
  }

  return (
    <CustomDraggable
      buySellToggle={buySellToggle}
      headerContent={(
        <TraderHeader
          selectedExchange={selectedExchange}
          setSelectedExchange={setSelectedExchange}
          currentAssetItem={currentAssetItem}
          quantity={quantity}
          setLtp={setLtp}
          ltpVal={ltp}
        />
      )}
      // manageTop={heightTop}
    >
      <Box className={classes.tradeContentContainer}>
        <Tabs
          classNames={{
            tabLabel: classes.tabLabel,
          }}
          color="blue"
          value={activeTab}
          onTabChange={handleTabChange}
        >
          <Tabs.List>
            <Tabs.Tab value={varietyValues.REGULAR}>Regular</Tabs.Tab>
            {exchanges.NSE === currentAssetItem.exchange && (
              <Tabs.Tab value={varietyValues.COVER}>Cover</Tabs.Tab>
            )}
            <Tabs.Tab value={varietyValues.AMO}>AMO</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={varietyValues.REGULAR}> </Tabs.Panel>
          <Tabs.Panel value={varietyValues.COVER}> </Tabs.Panel>
          <Tabs.Panel value={varietyValues.AMO}> </Tabs.Panel>

          <Box className={classes.tradeContentInnerContainer}>
            {/* intraday/longterm */}
            <Radio.Group
              label=""
              size="xs"
              value={productValue}
              onChange={handleProductValue}
              color="red"
            >
              <Group>
                <Radio
                  value={productOptions[0].value}
                  label={separateText(productOptions[0].label)}
                  color={buySellToggle ? "red" : "blue"}
                />
                {activeTab !== varietyValues.COVER
                && currentAssetItem.exchange !== exchanges.NFO ? (
                  <Radio
                    value={productOptions[1].value}
                    label={separateText(productOptions[1].label)}
                    color={buySellToggle ? "red" : "blue"}
                  />
                  ) : null}
                {currentAssetItem.exchange === exchanges.NFO ? (
                  <Radio
                    value={productOptions[2].value}
                    label={separateText(productOptions[2].label)}
                    color={buySellToggle ? "red" : "blue"}
                  />
                ) : null}
              </Group>
            </Radio.Group>

            {/* main content qty, price, trigger price */}
            <Box className={cx("trade-content", classes.tradeItemContainer)}>
              <Box ref={focusTrapRef} className={classes.tradeItem}>
                <NumberInput
                  type="number"
                  id="trade-qty"
                  mt="xl"
                  min={lotsize || 1}
                  step={lotsize || 1}
                  placeholder="Qty"
                  description="Qty"
                  disabled={false}
                  value={Number(quantity)}
                  onChange={(val: number) => setQuantity(val)}
                />
                {lotsize > 1 && (
                  <Text color="dimmed" size="sm" pt={8}>
                    {Math.floor(quantity / lotsize)}
                    {' '}
                    lot(s)
                  </Text>
                )}
              </Box>
              <Box className={classes.tradeItem}>
                <NumberInput
                  type="number"
                  mt="xl"
                  id="trade-price"
                  placeholder="Price"
                  description="Price"
                  precision={2}
                  step={0.05}
                  min={0.05}
                  value={handlePriceVal()}
                  onChange={(val: number) => setPriceField(val)}
                  disabled={handlePriceState(orderTypeMLVal)}
                />
                <Radio.Group
                  label=""
                  size="xs"
                  value={orderTypeMLVal}
                  onChange={handleOrderType}
                >
                  <Group mt="xs">
                    {OrderTypeML.map((item) => (
                      <Radio
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        color={buySellToggle ? "red" : "blue"}
                      />
                    ))}
                  </Group>
                </Radio.Group>
              </Box>
              <Box className={classes.tradeItem}>
                <NumberInput
                  type="number"
                  mt="xl"
                  id="trigger-price"
                  placeholder={
                    activeTab === varietyValues.COVER
                      ? "Stoploss Trigger Price"
                      : "Trigger Price"
                  }
                  description={
                    activeTab === varietyValues.COVER
                      ? "Stoploss Trigger Price"
                      : "Trigger Price"
                  }
                  precision={2}
                  step={0.05}
                  min={0.05}
                  disabled={handleTriggerPriceState(activeTab, orderTypeMLVal)}
                  value={handleTriggerPriceVal()}
                  onChange={(val: number) => setTriggerPriceField(val)}
                  error={handleTriggerPriceError(
                    triggerPriceField,
                    priceField,
                    orderTypeMLVal
                  )}
                />
                <div className="orderTypeSLM">
                  <Radio.Group
                    value={orderTypeMLVal}
                    onChange={handleOrderType}
                    label=""
                    size="xs"
                  >
                    <Group mt="xs">
                      {OrderTypeSLM.map((item) => (
                        <Radio
                          key={item.value}
                          value={item.value}
                          label={item.label}
                          color={buySellToggle ? "red" : "blue"}
                          disabled={activeTab === varietyValues.COVER}
                        />
                      ))}
                    </Group>
                  </Radio.Group>
                </div>
              </Box>
            </Box>

            {/*  */}
            <Text
              sx={{
                marginTop: 20,
                textAlign: "right",
                cursor: "pointer",
                display: "flex",
                justifyContent: "end",
              }}
              color="blue"
              size="sm"
              onClick={handleValidityOption}
            >
              {showValidityOption ? (
                <>
                  Hide options
                  <ChevronUp />
                </>
              ) : (
                <>
                  More options
                  <ChevronDown />
                </>
              )}
            </Text>

            {showValidityOption ? (
              <ValidityOptions
                activeTab={activeTab}
                validityVal={validityVal}
                setValidityVal={setValidityVal}
                disclosedQty={disclosedQty}
                setDisclosedQty={setDisclosedQty}
              />
            ) : null}

            <GttTradePart
              productValue={productValue}
              activeTab={activeTab}
              stoplossState={stoplossState}
              setStoplossState={setStoplossState}
              targetState={targetState}
              setTargetState={setTargetState}
            />
          </Box>
        </Tabs>
        <TradeModalFooter
          marginUsed={marginUsed}
          totalCharge={charge.total || 0}
          buySellToggle={buySellToggle}
          handleCancelTrade={handleCancelTrade}
          currentToken={currentAssetItem.token}
          triggerPriceError={handleTriggerPriceError(
            triggerPriceField,
            priceField,
            orderTypeMLVal
          )}
          handleProceedOrder={handleProceedOrder}
          handleOrderMargin={handleOrderMargin}
        />
      </Box>
    </CustomDraggable>
  );
}

export default memo(TradeContent)
