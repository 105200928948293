import {
  Box, Flex, Text,
} from "@mantine/core"
import { AlertCircle } from "tabler-icons-react"
import { capitalizeFirstLetter } from "../../utils"

interface ErrorElementProps {
  component?: string
  message?: string | boolean
  children?: React.ReactNode
}

const ErrorElement = ({ component = "", message = "Something went wrong", children }: ErrorElementProps) => {
  const errorText = `${component} couldn't be loaded.`

  return (
    <Box>
      <Flex gap="sm" align="center">
        <AlertCircle size={20} />
        <Text className="content_md_regular">
          {capitalizeFirstLetter(errorText.trim())}
        </Text>
      </Flex>

      <Text className="content_xs_regular text_fg_muted">
        {message || "No response from server. Check if you are still connected to internet. (NetworkException)"}
      </Text>
      {children}
    </Box>
  )
}

export default ErrorElement
