import { Divider, Menu, createStyles } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  AlignCenter, Settings, Telescope, TrendingUp,
} from 'tabler-icons-react'
import { useLocation } from 'wouter'
import useMarketDepth from 'src/hooks/common/useMarketDepth'
import { setGttModalInfo } from '@redux/sagas/misc/miscSlice'
import { IGttOrderDetail, IUserOrder } from '@redux/sagas/orders'
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import { selectSelectedWLIndex, selectWatchlistData } from '@redux/sagas/watchlists/watchlistSelectors'
import { addWLItem } from '@redux/sagas/watchlists/watchlistsSlice'
import { IGttSearchItem } from '@redux/sagas/misc'
import { IPositionItem } from '@redux/sagas/positions'

export const useStyles = createStyles(() => ({
  tableDropDownDots: {
    paddingTop: 5,
    marginLeft: 4,
  },
  tableDropdown: {
    position: "absolute",
  },
}));

interface ITableDropdown {
  row: IUserOrder | HoldingItem | IGttOrderDetail | IPositionItem,
}

const CommonTableDropdown = ({
  row,
}: ITableDropdown) => {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();

  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)

  const { handleMarketDepth } = useMarketDepth();

  const selectedWatchlist = watchlistsData[selectedWLIndex]

  const handleChart = (item: HoldingItem) => {
    setLocation(`/charts/${item.exchange}/${item.tradingSymbol}/${item.token}`);
  };

  const handleAddWatchlistItem = (item: IUserOrder) => {
    const payload = {
      exchange: item.exchange,
      exchangeSegment: "NSE",
      token: Number(item.token),
      symbol: item.symbol,
      sequence: Number(selectedWatchlist.items.length) + 1,
      // expiry: assetItem.e,
    };
    dispatch(
      addWLItem({
        data: payload,
        mwId: selectedWatchlist?.id,
      })
    );
  };

  const handleGttModal = (isBoolean: boolean, selectedOrderItem?: IUserOrder) => {
    dispatch(setGttModalInfo({ showGttModal: isBoolean }))
    if (selectedOrderItem) {
      dispatch(setGttModalInfo({ showGttModal: isBoolean, gttOrderDetails: selectedOrderItem }))
    }
  }

  return (
    <>
      <Divider />
      <Menu.Item
        icon={<Settings size={14} />}
        onClick={() => handleGttModal(true, row as IUserOrder)}
      >
        Create GTT
      </Menu.Item>
      <Menu.Item
        icon={<AlignCenter size={14} />}
        onClick={() => handleMarketDepth(row as IUserOrder | IGttSearchItem)}
      >
        Market depth
      </Menu.Item>
      <Menu.Item
        icon={<TrendingUp size={14} />}
        onClick={() => handleChart(row as any)}
      >
        Chart
      </Menu.Item>
      <Menu.Item
        icon={<Telescope size={14} />}
        onClick={() => handleAddWatchlistItem(row as IUserOrder)}
      >
        Add to marketwatch
      </Menu.Item>
    </>
  )
}

export default CommonTableDropdown
