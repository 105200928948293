import { createSelector } from "reselect"
import type { RootState } from "src/redux/store"

export const getFullSocket = (state: RootState) => state.sockets.fullSocketData
export const getQuoteSockets = (state: RootState) => state.sockets.quoteSocketData
export const getQuoteTokens = (state: RootState) => state.sockets.quoteTokens

export const getOrderUpdateSockets = (state: RootState) => state.sockets.orderUpdate

export const getFullTokens = (state: RootState) => state.sockets.fullTokens
export const getLoadingSocket = (state: RootState) => state.sockets.loadingSocket

export const selectFullSocket = createSelector([getFullSocket], (data) => data)

export const selectQuoteSockets = createSelector([getQuoteSockets], (data) => data)

export const selectHoldingsSockets = createSelector(
  [getQuoteSockets, (state, tokens: string[]) => tokens],
  (quotes, tokens) => {
    const holdingsLTP: Record<string, any> = {}
    tokens.forEach((token) => {
      holdingsLTP[token] = quotes[token] || {}
    })
    return holdingsLTP
  }
);

export const selectQuoteSocketsSelect = createSelector([
  // Usual first input - extract value from `state`
  getQuoteSockets,
  // Take the second arg, `category`, and forward to the output selector
  (state, token) => token,
], (data, token) => ((token && data) ? data[token] : {}))

export const selectOrderUpdate = createSelector([getOrderUpdateSockets], (data) => data)

export const selectQuoteTokens = createSelector(
  [getQuoteTokens],
  (data) => data || {}
)

// full token - for market depth
export const selectFullToken = createSelector(
  [getFullTokens],
  (token) => token || {}
)

// select full token - for market depth
export const selectFullSocketsSelect = createSelector([
  getFullTokens,
  (state, token) => token,
], (data, token) => ((token && data) ? data[token] : {}))

export const selectLoadingSocket = createSelector(
  [getLoadingSocket],
  (token) => token || false
)
