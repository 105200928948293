import React, { memo } from 'react'
import Flex from '@components/atoms/flex/flex';
import { Check } from 'tabler-icons-react';
import { Badge, Container } from '@mantine/core';
import useMarketDepth from '@hooks/common/useMarketDepth';
import { EXCHANGE_COLOR, INDICES } from '@utils/constant';
import { instrumentSearch } from '@utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { addWLItem } from '@redux/sagas/watchlists/watchlistsSlice';
import {
  selectSelectedWLIndex,
  selectWatchlistData,
} from "@redux/sagas/watchlists/watchlistSelectors"
import { SearchWLItemProps } from "./index.d";
import useStyles from './styled';
import FormattedName from './formattedName';
import SymbolItem from './symbolItem';

interface IWatchlistSearchItem {
  setShowSymbol: React.Dispatch<React.SetStateAction<boolean>>
  item: SearchWLItemProps
  selectedWLItemIndex: number
  index: number
  isExisted: boolean | null
  showSymbol: boolean
  handleSearch: (val: string) => void
}

const WatchlistSearchItem = ({
  setShowSymbol,
  item,
  selectedWLItemIndex,
  index,
  isExisted,
  showSymbol,
  handleSearch,
}: IWatchlistSearchItem) => {
  const { classes, cx } = useStyles();
  const parsedSymbolObj = instrumentSearch.get(item?.tradingSymbol, item?.segment, item?.exchange)
  const { handleMarketDepth } = useMarketDepth();
  const dispatch = useDispatch();
  const watchlistsData = useSelector(selectWatchlistData)
  const selectedWLIndex = useSelector(selectSelectedWLIndex)
  const selectedWatchlist = watchlistsData[selectedWLIndex]

  const handleAddWatchlistItem = (assetItem: Record<string, any>) => {
    const payload = {
      exchange: assetItem.exchange === INDICES ? "NSE" : assetItem.exchange,
      segment: assetItem.segment,
      token: assetItem.exchangeToken,
      symbol: assetItem.fullName,
      sequence: Number(selectedWatchlist?.items?.length) + 1,
    }
    dispatch(
      addWLItem({
        data: payload,
        mwId: selectedWatchlist?.id,
      })
    )
    handleSearch("")
  }
  return (
    <Container
      onMouseEnter={() => {
        setShowSymbol(true);
      }}
      onMouseLeave={() => {
        setShowSymbol(false);
      }}
      key={item?.exchangeToken}
      className={cx(
        classes.searchItemContainer,
        selectedWLItemIndex === index
        && classes.styledSelectedWatchlistItem,
        isExisted ? classes.searchSelectedItem : ""
      )}
      onClick={() => handleAddWatchlistItem(item)}
    >
      <Flex
        position="space-between"
        align="center"
      >
        <span className={classes.searchItemInner}>
          <FormattedName
            name={item.niceName}
            expiryDay={item.expiryDay}
            isWeekly={item.isWeekly}
          />
          {isExisted ? (
            <span className={classes.checkIcon}>
              <Check size={14} />
            </span>
          ) : null}
        </span>
        <span className={classes.searchItemCompany}>
          {item.company.toUpperCase()}
          <Badge color={EXCHANGE_COLOR[item.exchange]} variant="light" ml="sm" size="xs" radius="xs">
            {item.exchange}
          </Badge>
        </span>
      </Flex>
      {showSymbol ? (
        <SymbolItem
          isAdd={!isExisted}
          item={{ ...item, token: item.exchangeToken }}
          token={item.exchangeToken}
          isSearch
          parsedSymbolObj={parsedSymbolObj}
          handleMarketDepth={handleMarketDepth}
          handleSearch={handleSearch}
        />
      ) : null}
    </Container>
  )
}

export default memo(WatchlistSearchItem)
