export const baseURL = process.env.REACT_APP_BASE_URL
export const omsURL = "oms"
export const authURL = "auth"

export const authEndPoints = {
  login: `${authURL}/login`,
  appLogin: `${authURL}/app/login`,
  appLoginGenerateToken: `${authURL}/app/generate-token`,
  forgotPassword: `${authURL}/forgot-password`,
  resendOtp: `${authURL}/resend-otp`,
  logout: `${authURL}/logout`,
  captchaUrl: `${authURL}/captcha`,
  reloadCaptchaUrl: `${authURL}/captcha-reload`,
  resetPasswordUrl: `${authURL}/reset-password`,
  validateResetUrl: `${authURL}/validate-reset-request`,
  validate2faUrl: `${authURL}/validate-2fa`,
  createPasswordUrl: `${authURL}/set-user-password`,
  setup2faUrl: `${authURL}/set-user-2fa`,
}

export const watchlistsEndPoints = {
  mwListUrl: `${omsURL}/mw/list`,
  mwUrl: `${omsURL}/mw/`,
  addMwItemUrl: `${omsURL}/mw/\${mwId}/add`,
  updateSequenceUrl: `${omsURL}/mw/\${mwId}/update-sequence`,
}

export const usersEndPoints = {
  userLimitsUrl: `${omsURL}/user/limits`,
  userPreferencesUrl: `${omsURL}/user/preferences`,
  userDetailsUrl: `${omsURL}/user/details`,
  userAddImageUrl: `${omsURL}/user/add-image`,
  enable2faUrl: `${authURL}/enable-2fa`,
  verify2faUrl: `${authURL}/verify-2fa`,
  changePasswordUrl: `${authURL}/change-password`,
}

export const tradesEndPoints = {
  regularOrderUrl: `${omsURL}/order/regular`,
  coverOrderUrl: `${omsURL}/order/cover`,
  orderMarginUrl: `${omsURL}/margin/order`,
  convertPositionUrl: `${omsURL}/order/convert`,
}

export const ordersEndPoints = {
  userOrdersUrl: `${omsURL}/user/orders`,
  orderHistoryUrl: `${omsURL}/order/`,
  userTradesUrl: `${omsURL}/user/trades`,
  alertOcoUrl: `${omsURL}/alert/oco`,
  alertSingleUrl: `${omsURL}/alert/gtt`,
  gttDetailsUrl: `${omsURL}/alert/details`,
  deleteGttUrl: `${omsURL}/alert/`,
}

export const positionsEndPoints = {
  userPositionsUrl: `${omsURL}/user/positions`,
}

export const holdingsEndPoints = {
  userHoldingsUrl: `${omsURL}/user/holdings`,
}
