import {
  memo, useEffect, useState,
} from "react"
import {
  Tabs, Box, createStyles, useMantineTheme, TabsValue,
} from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/redux/store"
import useMediaCustom from "src/hooks/common/useMediaCustom"
import { bounceAnimation, isEqual } from "src/utils"
import { getTypographyStyles } from "@theme/themeConfig"
import useRouteChange from "@hooks/common/useRouteChange"
import OrderDetailsModal from "@components/modals/order/OrderDetailsModal"
import OrderHistoryModal from "@components/modals/order/OrderHistoryModal"
import usePrevious from '@hooks/common/usePrevious'
import {
  cancelRegularOrder,
  gttDetails,
  getUserOrders,
} from "@redux/sagas/orders/ordersSlice"
import { IGttOrderDetail, IUserOrder } from "@redux/sagas/orders"
import { selectOrderUpdate } from "@redux/sagas/sockets/socketSelectors"
import { setQuoteTokens } from "@redux/sagas/sockets/socketsSlice"
import GttDetailsModal from "@components/modals/order/gttDetailsModal"
import { showNotification } from "@mantine/notifications"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import ErrorElement from "@components/errorElement"
import {
  FAILURE, SUCCESS, orderTabTypes,
} from "../../utils/constant"
import OpenOrderTab from "./open/OpenOrderTab"
import GttOrderTab from "./gtt/GttOrderTab"

const useStyles = createStyles((theme) => ({
  tabLabel: {
    ...getTypographyStyles('heading_xs_semibold'),
  },
  orderBox: {
    minHeight: "87vh",
    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
      width: "97%",
    },
  },
}))

const Orders = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { isLargeScreen } = useMediaCustom();
  const theme = useMantineTheme();
  const { colors } = theme.other

  const ordersState = useSelector((state: RootState) => state.order.orders)
  const isOrdersStateFailure = ordersState.status === FAILURE
  const gttDetailsState = useSelector((state: RootState) => state.order.gttDetails)
  const isGttDetailsStateFailure = gttDetailsState.status === FAILURE
  const {
    cancelOrder,
    deleteGtt,
  } = useSelector((state: RootState) => state.order)

  const orderUpdate = useSelector(selectOrderUpdate) as any;
  const prevProps = usePrevious({ cancelOrder, orderUpdate, deleteGtt })

  const [showOrderDetails, setShowOrderDetails] = useState(false)
  const [showOrderHistory, setShowOrderHistory] = useState(false)
  const [showGttDetails, setShowGttDetails] = useState(false)

  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Partial<IUserOrder>>({})
  const [selectedGttDetails, setSelectedGttDetails] = useState({} as IGttOrderDetail);

  const { isNavigateLoading } = useRouteChange()

  const [activeTab, setActiveTab] = useState<string | null>(orderTabTypes.ORDERS);
  const [reloadTab, setReloadTab] = useState<number>(0);
  // const [tokens, setTokens] = useState<string[]>([]);
  // const socketsData = useSelector((state: RootState) => selectHoldingsSockets(state, tokens));
  const openOrdersData = ordersState.data ? ordersState.data.filter(
    (item: IUserOrder) => item.orderStatus === "OPEN"
  ) : [];
  const filteredOrdersData = ordersState.data ? ordersState.data.filter(
    (item: IUserOrder) => item.orderStatus !== "OPEN"
  ) : [];

  // const { dataWithSockets: updatedOpenOrders } = useSocketUpdate(openOrdersData)
  // const { dataWithSockets: updatedGttOrders } = useSocketUpdate(gttDetailsData)
  useEffect(() => {
    if (activeTab === orderTabTypes.ORDERS) {
      dispatch(getUserOrders())
      // dispatch(getUserTrades())
    } else if (activeTab === orderTabTypes.GTT) {
      dispatch(gttDetails([]))
    }
  }, [activeTab, reloadTab])

  useEffect(() => {
    const unSubObj: Record<string, number> = {};
    if (activeTab === orderTabTypes.GTT) {
      const subsObj: Record<string, number> = {};
      if (gttDetailsState.data) {
        gttDetailsState.data.forEach((item: any) => {
          subsObj[item.token] = 1;
          unSubObj[item.token] = 0;
        });
      }

      dispatch(setQuoteTokens(subsObj))
    }
    return () => {
      if (activeTab === orderTabTypes.GTT) {
        dispatch(setQuoteTokens(unSubObj))
      }
    }
  }, [activeTab, gttDetailsState])

  useEffect(() => {
    const unSubObj: Record<string, number> = {};
    if (activeTab === orderTabTypes.ORDERS) {
      const subsObj: Record<string, number> = {};
      if (ordersState.data) {
        ordersState.data.forEach((item: any) => {
          subsObj[item.token] = 1;
          unSubObj[item.token] = 0;
        });
      }

      dispatch(setQuoteTokens(subsObj))
    }
    return () => {
      dispatch(setQuoteTokens(unSubObj))
    }
  }, [activeTab, ordersState])

  useEffect(() => {
    if (!isEqual(deleteGtt.status, prevProps.deleteGtt.status) && deleteGtt.status === SUCCESS) {
      dispatch(gttDetails([]))
      showNotification({
        title: "Deleted",
        message: "GTT deleted successfully",
      })
    }
  }, [deleteGtt, prevProps])

  const handleTab = (currentTab: TabsValue) => {
    setReloadTab((prev) => prev + 1)
    setActiveTab(currentTab);
  }

  const handleOrderDetailsModal = (item: any) => {
    setShowOrderDetails(true)
    setSelectedOrderDetails(item)
  }

  const handleOrderDetailsCloseModal = () => {
    setShowOrderDetails(false)
  }

  const handleGttDetailsCloseModal = () => {
    setShowGttDetails(false)
  }

  const handleGttDetailsModal = (item: any) => {
    setShowGttDetails(true)
    setSelectedGttDetails(item)
  }

  const handleOrderHistoryModal = () => {
    setShowOrderDetails(false)
    setShowOrderHistory(true)
  }

  const handleOrderDetailsHistoryModal = () => {
    setShowOrderDetails(true)
    setShowOrderHistory(false)
  }

  const handleOrderHistoryCloseModal = () => {
    setShowOrderHistory(false)
  }

  useEffect(() => {
    if (!isEqual(cancelOrder.status, prevProps.cancelOrder.status) && cancelOrder.status === SUCCESS) {
      dispatch(getUserOrders())
      showNotification({
        title: "Cancelled",
        message: "Order is cancelled.",
        color: "green",
      })
    }
  }, [cancelOrder, prevProps])

  const handleCancelOrder = (orderId: string) => {
    dispatch(cancelRegularOrder({ orderId }))
  }

  const loadingCheck = (ordersState.loading || gttDetailsState.loading)
    && (filteredOrdersData.length
      || (gttDetailsState.data && gttDetailsState.data.length)
      || openOrdersData.length)
    && isNavigateLoading
    ? bounceAnimation
    : {};

  return (
    <Box
      sx={loadingCheck}
      p={isLargeScreen ? "lg" : "sm"}
      className={classes.orderBox}
    >
      <Tabs
        keepMounted={false}
        classNames={{ tabLabel: classes.tabLabel }}
        styles={() => ({
          tab: {
            color: theme.other.colors.fg.muted,
            '&[data-active]': {
              color: colors.primary.default,
            },
          },
        })}
        value={activeTab}
        onTabChange={(val) => handleTab(val)}
      >
        <Tabs.List>
          <Tabs.Tab value={orderTabTypes.ORDERS}>Orders</Tabs.Tab>
          <Tabs.Tab value={orderTabTypes.GTT}>GTT</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={orderTabTypes.ORDERS} pt="xs">
          <RenderBoundary
            loading={ordersState.loading}
            status={ordersState.status}
            data={ordersState.data}
            errorState={(
              <ErrorElement component="Positions" message={isOrdersStateFailure && ordersState.message} />
            )}
          >
            <OpenOrderTab
              ordersLoading={ordersState.loading}
              filteredOrdersData={filteredOrdersData}
              openOrdersData={openOrdersData}
              ordersIsError={isOrdersStateFailure}
              ordersErrMessage={isOrdersStateFailure ? ordersState.message : ''}
              handleOrderDetailsModal={handleOrderDetailsModal}
              handleCancelOrder={handleCancelOrder}
            />
          </RenderBoundary>

        </Tabs.Panel>

        <Tabs.Panel value={orderTabTypes.GTT} pt="xs">
          <GttOrderTab
            gttLoading={gttDetailsState.loading}
            gttDetailsData={gttDetailsState.data ? gttDetailsState.data : []}
            gttIsError={isGttDetailsStateFailure}
            gttErrMessage={isGttDetailsStateFailure ? gttDetailsState.message : ''}
            handleGttDetailsModal={handleGttDetailsModal}
          />
        </Tabs.Panel>
      </Tabs>

      {showOrderDetails ? (
        <OrderDetailsModal
          isVisible={showOrderDetails}
          handleOrderDetailsCloseModal={handleOrderDetailsCloseModal}
          handleOrderHistoryModal={handleOrderHistoryModal}
          selectedOrderDetails={selectedOrderDetails}
        />
      ) : null}

      {showGttDetails ? (
        <GttDetailsModal
          isVisible={showGttDetails}
          handleGttDetailsCloseModal={handleGttDetailsCloseModal}
          selectedGttDetails={selectedGttDetails}
        />
      ) : null}

      {showOrderHistory ? (
        <OrderHistoryModal
          selectedOrderId={selectedOrderDetails?.id!}
          isVisible={showOrderHistory}
          handleOrderDetailsHistoryModal={handleOrderDetailsHistoryModal}
          handleOrderHistoryCloseModal={handleOrderHistoryCloseModal}
        />
      ) : null}
    </Box>
  )
}

export default memo(Orders)
