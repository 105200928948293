import {
  Header,
  Group,
  Menu,
  Avatar,
  useMantineTheme,
  Switch,
  useMantineColorScheme,
  Box,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import {
  Settings,
  Logout,
  BallFootball,
  BrandCoinbase,
  SunHigh,
  MoonStars,
} from "tabler-icons-react";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "wouter";
import { setQuoteTokens } from "src/redux/sagas/sockets/socketsSlice";
import { selectUserPreferences, selectUsersDetails } from "src/redux/sagas/users/userSelectors";
import useMediaCustom from "src/hooks/common/useMediaCustom";
import {
  logoutUser,
} from "@redux/sagas/auth/authSlice";
import { getUserOrders } from "@redux/sagas/orders/ordersSlice";
import { getHoldings } from "@redux/sagas/holdings/holdingsSlice";
import { getUserPositions } from "@redux/sagas/positions/positionsSlice";
import { getUserLimits } from "@redux/sagas/users/usersSlice";
import LogoUrlLight from "@assets/images/logo_light.svg";
import LogoUrlDark from "@assets/images/logo_dark.svg"
import { getFirstLetterOfWord } from "@utils/index";
import { resetAllSlices } from "@redux/store";
import Flex from "../atoms/flex/flex";
import useStyles from "./styles";
import AppBarSymbol from "./appBarSymbol";
import links from "./links";

interface AppBarSymbolProps {
  token: number;
  symbol: string;
  exchange: string;
  expiry: number;
  segment: string;
}

const AppBar = (): JSX.Element => {
  const [userName] = useLocalStorage({
    key: "name",
  });

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { isLargeScreen } = useMediaCustom();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  // location cause re-rendering
  const [location, setLocation] = useLocation();
  const { data: detailsData } = useSelector(selectUsersDetails);
  const { data: preferencesData } = useSelector(selectUserPreferences)

  const [showAvatar, setShowAvatar] = useState(false);

  const filteredLinks = links.filter((item) => (isLargeScreen ? item.label !== "Watchlist" : item));

  // redirecting user to homepage if user are in watchlist page in big screen
  useEffect(() => {
    if (isLargeScreen && location === "/watchlist") {
      setLocation("/home");
    }
  }, [isLargeScreen, location, setLocation]);

  const handleLogout = () => {
    dispatch(logoutUser());
    resetAllSlices()(dispatch)
  };

  const tempPinnedInstruments = preferencesData.pinnedInstruments;
  useEffect(() => {
    const subsObj: Record<string, number> = {};
    const unsubObj: Record<string, number> = {};
    if (isLargeScreen) {
      tempPinnedInstruments.forEach((item: AppBarSymbolProps) => {
        subsObj[item.token] = 1
        unsubObj[item.token] = 0
      });
    }
    // todo: this causing re-rendering of the whole page
    dispatch(setQuoteTokens(subsObj))
    return () => {
      dispatch(setQuoteTokens(unsubObj))
    }
  }, [dispatch, isLargeScreen, tempPinnedInstruments]);

  // const handleOrderUpdates = (data: boolean | string, type: string) => {
  //   if (type === "theme") {
  //     toggleColorScheme();
  //   }
  //   dispatch(updateUserPreferences({ ...preferencesData, [type]: data }));
  // };

  const handleLinkClick = (currentTo: string) => {
    if (currentTo === location) {
      setLocation(location)
      if (links[1].to === location) {
        dispatch(getUserLimits())
        dispatch(getHoldings([]))
      } else if (links[2].to === location) {
        dispatch(getUserOrders())
        // dispatch(getUserTrades())
      } else if (links[3].to === location) {
        dispatch(getHoldings({}))
      } else if (links[4].to === location) {
        dispatch(getUserPositions())
      } else if (links[5].to === location) {
        dispatch(getUserLimits())
      }
    }
  };

  const items = filteredLinks?.map((link) => (
    <Link
      key={link.label}
      to={link.to}
      className={location !== link.to ? classes.link : classes.linkActive}
      onClick={() => handleLinkClick(link.to)}
    >
      {isLargeScreen ? (
        link.label
      ) : (
        <span
          className={location !== link.to ? classes.link : classes.linkActive}
        >
          {link.icon}
        </span>
      )}
    </Link>
  ));

  return (
    <Header
      className={classes.header}
      height={`${Number(theme.other.navHeight) / 16}rem`}
    >
      <div className={classes.headerInner} key={crypto.randomUUID()}>
        {/* Indices Ticker */}
        {isLargeScreen && (
          <Flex
            position="space-between"
            className={classes.watchlistHeaderWrapper}
          >
            {preferencesData?.pinnedInstruments.map(
              (item: AppBarSymbolProps) => (
                <AppBarSymbol item={item} key={crypto.randomUUID()} />
              )
            )}
          </Flex>
        )}
        {/* Navbar */}
        <div className={classes.inner}>
          <Flex>
            {isLargeScreen && (
              <Flex mr={20}>
                <img
                  alt="logo"
                  src={
                    theme.colorScheme === "dark" ? LogoUrlDark : LogoUrlLight
                  }
                  width="80"
                  height="24"
                />
              </Flex>
            )}
            <Group>{items}</Group>
          </Flex>

          <Group className={classes.navItemsContainer}>
            <Menu
              transitionProps={{ transition: "pop", duration: 100 }}
              position="bottom-end"
              offset={10}
            >
              <Menu.Target>
                <Box className="cursor-pointer">
                  {detailsData && detailsData.image && !showAvatar ? (
                    <img
                      width={32}
                      height={32}
                      alt="user"
                      className={classes.avatarImg}
                      src={detailsData.image}
                      onError={() => setShowAvatar(true)}
                    />
                  ) : (
                    <Avatar color="cyan" size={32} radius={32}>
                      {getFirstLetterOfWord(userName)}
                    </Avatar>
                  )}
                </Box>
              </Menu.Target>
              <Menu.Dropdown>
                <Link to="/profile">
                  <Menu.Item icon={<Settings size={14} />}>
                    My Profile / Settings
                  </Menu.Item>
                </Link>
                <Link to="/profile">
                  <Menu.Item icon={<BrandCoinbase size={14} />}>
                    Console
                  </Menu.Item>
                </Link>
                <Link to="/profile">
                  <Menu.Item icon={<BallFootball size={14} />}>
                    Support
                  </Menu.Item>
                </Link>
                <Menu.Item
                  icon={
                    theme.colorScheme === "dark" ? (
                      <SunHigh size={14} />
                    ) : (
                      <MoonStars size={14} />
                    )
                  }
                >
                  <Switch
                    radius="md"
                    checked={colorScheme === "dark"}
                    onChange={() => toggleColorScheme()}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    //   handleOrderUpdates(event.target.value, "theme");
                    // }}
                    size="md"
                    color={theme.colorScheme === "dark" ? "gray" : "dark"}
                  />
                </Menu.Item>
                <Menu.Item
                  onClick={handleLogout}
                  color="red"
                  icon={<Logout size={14} />}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </div>
      </div>
    </Header>
  );
};

export default memo(AppBar);
