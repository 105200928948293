import { Box, Button, TextInput } from '@mantine/core';
import { RootState } from '@redux/store';
import { allowOnlyNumber, fadeInAnimation } from '@utils/index';
import { useSelector } from 'react-redux';
import { UseFormReturnType, useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import ExistingUser from './existingUser';

interface RenderLoggedInStateProps {
  existedUser: string
  userId: string
  userImg: string
  handleChangeUser: () => void
  validate2faRequestId: string
  handleSubmit: (values: { code: string }, event: React.FormEvent<HTMLFormElement>) => void,
  validateApiCalled: any,
  formLogin: UseFormReturnType<{
    userId: string;
    password: string;
    captchaValue: string;
  }, (values: {
    userId: string;
    password: string;
    captchaValue: string;
  }) => {
    userId: string;
    password: string;
    captchaValue: string;
  }>
}

const TotpInput = ({
  userId,
  userImg,
  handleChangeUser,
  handleSubmit,
  validateApiCalled,
}: RenderLoggedInStateProps) => {
  const focusTrapRef = useFocusTrap();

  const validate2faState = useSelector((state: RootState) => state.auth.validate2fa);

  const formValidate = useForm({
    initialValues: { code: "" },
    validate: {
      code: (value) => (value?.length < 6 ? "TOTP must have at least 6 digits" : null),
    },
  });

  const handleTOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (allowOnlyNumber(value)) {
      validateApiCalled.current = false; // eslint-disable-line no-param-reassign
      formValidate.setFieldValue('code', value);
    }
  }

  return (
    <Box sx={fadeInAnimation}>
      <ExistingUser
        userId={userId}
        userImg={userImg}
        handleChangeUser={handleChangeUser}
      />
      <form ref={focusTrapRef} onSubmit={formValidate.onSubmit(handleSubmit)}>
        <TextInput
          label="Enter TOTP"
          mt="md"
          placeholder="123456"
          {...formValidate.getInputProps("code")}
          maxLength={6}
          id="code"
          onChange={handleTOTPChange}
          autoComplete="off"
          data-autofocus
          inputMode="numeric"
          pattern="[0-9]*"
        />
        <Button
          aria-label="signin"
          disabled={!formValidate.isValid()}
          name="signin"
          fullWidth
          mt="xl"
          type="submit"
          loading={validate2faState.loading}
        >
          Sign In
        </Button>
      </form>
    </Box>
  )
}

export default TotpInput
