import { createSlice } from "@reduxjs/toolkit"
import {
  IFullSocketData, FullToken, IQuoteSocketData, QuoteTokens, IOrderUpdate,
} from ".";

const initialState = {
  quoteSocketData: {} as IQuoteSocketData,
  quoteTokens: {} as QuoteTokens,
  fullTokens: {} as FullToken,
  fullSocketData: {} as IFullSocketData,
  orderUpdate: {} as IOrderUpdate,
  loadingSocket: false,
}

export const socketsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    setQuoteSocketsData: (state, action) => {
      state.quoteSocketData = {
        ...state.quoteSocketData,
        ...action.payload,
      }
    },
    setQuoteTokens: (state, action) => {
      state.quoteTokens = { ...state.quoteTokens, ...action.payload }
    },
    setFullToken: (state, action) => {
      state.fullTokens = { ...state.fullTokens, ...action.payload }
    },
    setFullSocketData: (state, action) => {
      const fullSocketDataState = state;
      fullSocketDataState.fullSocketData = { ...fullSocketDataState.fullSocketData, ...action.payload }
    },

    setOrderUpdate: (state, action) => {
      const orderUpdateState = state;
      orderUpdateState.orderUpdate = action.payload
    },
    setLoadingSocket: (state, action) => {
      state.loadingSocket = action.payload.loading
    },

    resetSocketsSlice: () => initialState,
  },
})

// Action
export const {
  setQuoteTokens,
  setQuoteSocketsData,
  setOrderUpdate,
  setFullToken,
  setFullSocketData,
  setLoadingSocket,

  resetSocketsSlice,
} = socketsSlice.actions

export default socketsSlice.reducer
