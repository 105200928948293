import { useMediaQuery } from '@mantine/hooks';
import theme from '@theme/theme';

const useMediaCustom = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.xs}px)`);
  const isLargeScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints?.md}px)`,
    window.innerWidth > Number(theme.breakpoints?.md)
  );

  return { isMobile, isLargeScreen }
}

export default useMediaCustom;
