import { Alert, Box, Button } from "@mantine/core"
import { AlertCircle } from "tabler-icons-react"
import React, { Component, ReactNode } from "react"

type ErrorBoundaryProps = {
  FallbackComponent?: React.ComponentType<{ error: Error; onReset?: () => void }>
  onReset?: () => void
  children: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

const DefaultErrorFallback = ({ onReset }: { onReset?: () => void }) => {
  const handleReset = () => {
    window.location.reload()
    if (onReset) {
      onReset()
    }
  }

  return (
    <Alert icon={<AlertCircle size={16} />} title="Error !" color="red">
      <Box>
        Something terrible happened! We are trying our best to solve this error.
      </Box>
      <Button onClick={handleReset} mt={12} variant="outline" color="red">
        Reset
      </Button>
    </Alert>
  )
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
  }

  render() {
    const { FallbackComponent, onReset, children } = this.props
    const { hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI
      return FallbackComponent ? (
        <FallbackComponent error={new Error("Error")} onReset={onReset} />
      ) : (
        <DefaultErrorFallback onReset={onReset} />
      )
    }

    return children
  }
}

export default ErrorBoundary
