import { call, put } from "redux-saga/effects"
import { removeEQFromSymbol } from "@utils/index"
import {
  requestCancelRegularOrder,
  requestEditOcoAlert,
  requestEditSingleAlert,
  requestGttDetails,
  requestGttOrder,
  requestOcoAlert,
  requestOrderHistory,
  requestSingleAlert,
  requestUserOrders,
  requestUserTrades,
} from "./ordersRequests"

import {
  cancelRegularOrderFailure,
  cancelRegularOrderSuccess,
  deleteGttOrderFailure,
  deleteGttOrderSuccess,
  editGttOcoOrderFailure,
  editGttOcoOrderSuccess,
  editGttSingleOrderFailure,
  editGttSingleOrderSuccess,
  getOrderHistoryFailure,
  getOrderHistorySuccess,
  getUserOrdersFailure,
  getUserOrdersSuccess,
  getUserTradesFailure,
  getUserTradesSuccess,
  gttDetailsFailure,
  gttDetailsSuccess,
  gttOcoOrderFailure,
  gttOcoOrderSuccess,
  gttSingleOrderFailure,
  gttSingleOrderSuccess,
} from "./ordersSlice"

import { IGttOrderDetail, IUserOrder } from "."

// handle regular order
export function* handleUserOrders(): Generator<any, any, any> {
  // const { ordersData = [] } = yield select((state: RootState) => state.order.orders);
  // yield put(setUserOrders({ loading: ordersData.length === 0, data: ordersData }))
  try {
    const { data: { data, status } } = yield call(requestUserOrders)
    const formattedData = data?.map((item: IUserOrder) => ({
      ...item,
      quantity: Number(item.quantity),
      price: Number(item.price),
      averagePrice: Number(item.averagePrice),
    }))
    yield put(getUserOrdersSuccess({ data: formattedData, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getUserOrdersFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// handle regular order
export function* handleOrderHistory(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOrderHistory, action?.payload?.orderId)
    yield put(getOrderHistorySuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getOrderHistoryFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// cancel regular order
export function* handleCancelRegularOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestCancelRegularOrder, action?.payload?.orderId)
    yield put(cancelRegularOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(cancelRegularOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// fetch user trades history
export function* handleUserTrades(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestUserTrades)
    const formattedData = data?.map((item: IUserOrder) => ({
      ...item,
      quantity: Number(item.quantity),
      price: Number(item.price),
      averagePrice: Number(item.averagePrice),
    }))
    yield put(getUserTradesSuccess({ data: formattedData, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(getUserTradesFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : oco order
export function* handleGttOcoOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestOcoAlert, action.payload)
    yield put(gttOcoOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(gttOcoOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : update oco order
export function* handleEditOcoOrder(action: any): Generator<any, any, any> {
  const { data: payloadData, id } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditOcoAlert, payloadData, id)
    yield put(editGttOcoOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(editGttOcoOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : single order
export function* handleGttSingleOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestSingleAlert, action.payload)
    yield put(gttSingleOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(gttSingleOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : edit single order
export function* handleEditSingleOrder(action: any): Generator<any, any, any> {
  const { data: payloadData, id } = action.payload
  try {
    const { data: { data, status } } = yield call(requestEditSingleAlert, payloadData, id)
    yield put(editGttSingleOrderSuccess({ data, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(editGttSingleOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : get order details
export function* handleGttOrderDetails(): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGttDetails)
    const formattedData = data?.map((item: IGttOrderDetail) => ({
      ...item,
      symbol: removeEQFromSymbol(item.symbol),
      orderParams1: Number(item.orderParamsLeg1.price) || 0,
    }))
    yield put(gttDetailsSuccess({ data: formattedData, status }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(gttDetailsFailure({
      errorCode, message, status, errorData,
    }))
  }
}

// gtt : delete order
export function* handleDeleteGttOrder(action: any): Generator<any, any, any> {
  try {
    const { data: { data, status } } = yield call(requestGttOrder, action.payload)
    yield put(deleteGttOrderSuccess({ data, status, loading: false }))
  } catch (error:any) {
    const {
      errorCode, message, status, errorData,
    } = error.response.data
    yield put(deleteGttOrderFailure({
      errorCode, message, status, errorData,
    }))
  }
}
