import {
  Flex,
  Tooltip, useMantineTheme,
} from '@mantine/core'
import {
  AlertTriangle,
  // Rotate,
} from 'tabler-icons-react'

const ReloadIcons = () => {
  const theme = useMantineTheme();
  const { colors } = theme.other;

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  return (
    <Flex align="center" mt={10} ml={4} h="100%">
      <Tooltip
        label="No response from server. Please check your internet connection and reload the page"
        withArrow
        transitionProps={{ transition: 'scale', duration: 100 }}
      >
        <p className="cursor-pointer ml-4">
          <AlertTriangle size={18} color={colors.error.default} />
        </p>
      </Tooltip>
      {/* <Tooltip
        label="Reload page"
        withArrow
        transitionProps={{ transition: 'scale', duration: 100 }}
      >
        <p className="cursor-pointer">
          <Rotate color={colors.primary.default} onClick={handleRefresh} cursor="pointer" size={20} />
        </p>
      </Tooltip> */}
    </Flex>
  )
}

export default ReloadIcons
