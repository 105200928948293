import {
  EffectCallback, DependencyList, useEffect, useRef, useCallback,
} from 'react';
import { debounce } from '../../utils';

// https://stackoverflow.com/a/67504622/51500
const useDebouncedEffect = (effect: EffectCallback, deps: DependencyList, delay: number) => {
  const cleanUp = useRef<any>();
  const effectRef = useRef<EffectCallback>();
  // console.log('deps', deps);
  effectRef.current = useCallback(effect, deps);
  const lazyEffect = useCallback(
    // eslint-disable-next-line no-return-assign
    debounce(() => (cleanUp.current = effectRef.current?.()), delay),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => () => (cleanUp.current instanceof Function ? cleanUp.current() : undefined), []);
};

export default useDebouncedEffect;
