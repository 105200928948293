import {
  Title,
  TextInput,
  Button,
  Group,
  Anchor,
  Text,
  createStyles,
  Box,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { Link } from "wouter"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { showNotification } from "@mantine/notifications"
import usePrevious from "src/hooks/common/usePrevious"
import useValidateOtp from "@hooks/handleApiCall/useValidateOtp"
import { resendOtp } from "@redux/sagas/auth/authSlice"
import type { RootState } from '@redux/store'
import Logo from "@components/logo";
import { FAILURE, SUCCESS, OTP_PREFERENCE } from "@utils/constant";
import {
  allowOnlyNumber, capitalizeFirstLetter, getQueryItem, isEqual,
} from "../../utils"

const useStyles = createStyles((theme) => ({
  resendActive: {
    pointerEvents: "none",
    color: theme.colors.gray[6],
  },
  resendInActive: {
    pointerEvents: "unset",
    cursor: "pointer",
  },
}))

const ValidateOtp = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const {
    resendOtp: resendOtpData,
  } = useSelector((state: RootState) => state.auth)
  const { loading } = useSelector((state: RootState) => state.auth.validateReset)

  const prevProps = usePrevious({ resendOtpData })

  const { handleValidateOtp } = useValidateOtp()

  const otpType = getQueryItem(window.location.search, "otpType") || OTP_PREFERENCE.EMAIL.toLowerCase()

  const requestId = getQueryItem(window.location.search, "requestId")

  const [resendTimeLeft, setResendTimeLeft] = useState(30)

  const form = useForm({
    initialValues: { otp: "" },
    validate: {
      otp: (value) => (value?.length !== 4 ? "Otp must be of 4 digits" : null),
    },
  })

  const handleSubmit = async (val: typeof form.values) => {
    if (requestId) {
      const payload = { otp: val.otp, requestId }
      handleValidateOtp(payload)
    }
  }

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (allowOnlyNumber(value)) {
      form.setFieldValue("otp", value)
    }
  }

  const handleResendOtp = () => {
    dispatch(resendOtp({ requestId }))
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (resendTimeLeft > 0) {
        setResendTimeLeft((prevTime) => prevTime - 1)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [resendTimeLeft])

  useEffect(() => {
    if (!isEqual(prevProps.resendOtpData.status, resendOtpData.status) && resendOtpData.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(resendOtpData.message),
        message: "resend failed",
      })
    } else if (!isEqual(prevProps.resendOtpData.status, resendOtpData.status) && resendOtpData.status === SUCCESS) {
      setResendTimeLeft(30)
      showNotification({
        title: "Otp resent sucessfully",
        message: "",
      })
    }
  }, [resendOtpData, prevProps])

  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Validate OTP </h2>
      </div>
      <Box p={16}>
        <Title order={6} mb="md" align="center">
          Enter the 4 digit OTP received in
          {' '}
          {otpType}
          {' '}
          to reset your password.
        </Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            id="totp-input"
            label="OTP"
            placeholder="1234"
            {...form.getInputProps("otp")}
            maxLength={4}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={handleOtpChange}
          />

          <Button fullWidth mt="xl" type="submit" loading={loading}>
            Continue
          </Button>

          <Group sx={{ gap: 6 }} align="baseline" position="center" mt="lg">
            <Text
              className={resendTimeLeft > 0 ? classes.resendActive : classes.resendInActive}
              variant="link"
              onClick={handleResendOtp}
              size="sm"
            >
              Resend Otp
            </Text>
            {
              resendTimeLeft > 0 && (
                <Text size="sm">
                  {resendTimeLeft.toString()}
                  {' '}
                  sec
                </Text>
              )
            }

          </Group>
          <Group align="baseline" position="center" mt={3}>
            <Anchor component={Link} to="/" size="sm">
              Back to login
            </Anchor>
          </Group>
        </form>
      </Box>
    </div>
  )
}

export default ValidateOtp
