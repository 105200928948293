import {
  Box,
  Button,
  Grid,
  Loader,
} from "@mantine/core"
import { ChartPie, Wallet } from "tabler-icons-react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Flex from "@components/atoms/flex/flex"
import { getUserLimits } from "@redux/sagas/users/usersSlice"
import { IUserLimit } from "@redux/sagas/users"
import PlainCard from "@components/plainCard"
import useMediaCustom from "src/hooks/common/useMediaCustom";
import useRouteChange from "@hooks/common/useRouteChange"
import ErrorElement from "@components/errorElement"
import Loading from "@components/loading/loading"
import ZeroState from '@components/zeroState'
import { RootState } from "@redux/store"
import { FAILURE } from "@utils/constant"
import RenderBoundary from "@components/renderBoundary/renderBoundary"
import ErrorBoundary from "../../ErrorBoundary"
import {
  bounceAnimation, formatToINR, getCookie,
} from "../../utils"
import useStyles from "./styles";

const PrimaryCard = ({
  title,
  value,
  isLargeScreen,
}: {
  title: string;
  value: string | number;
  isLargeScreen: boolean
}) => {
  const { classes } = useStyles()
  return (
    <Flex
      className={classes.primaryCard}
      direction={isLargeScreen ? "column" : "row"}
      align="flex-start"
    >
      <p className="heading_xs_regular text_fg_subtle">{title}</p>
      <p className="content_xl_regular">{value}</p>
    </Flex>
  );
}

const SecondaryCard = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  const { isLargeScreen } = useMediaCustom();
  return (
    <Flex position="space-between" mr={16} my={12} ml={isLargeScreen ? 16 : 0}>
      <p className="heading_sm_regular text_fg_subtle">{title}</p>
      <p className="content_lg_regular">{value}</p>
    </Flex>
  );
}

const FundsCardContent = ({ data }: { data: IUserLimit | [] }) => {
  const { isLargeScreen } = useMediaCustom();
  const { classes } = useStyles()
  const { cash = 0, marginUsed = 0 } = data as IUserLimit;

  return (
    <Flex
      pt={12}
      sx={{ justifyContent: "space-between" }}
      direction={isLargeScreen ? "row" : "column"}
    >
      <Flex className={isLargeScreen ? '' : classes.fullWidth}>
        {isLargeScreen ? (
          <div className={classes.walletIconWrapper}>
            <Wallet className="text_fg_subtle" size={26} />
          </div>
        ) : null}
        <PrimaryCard
          title="Margin Available"
          value={formatToINR(Number(cash) - Number(marginUsed))}
          isLargeScreen={isLargeScreen}
        />
      </Flex>
      <PrimaryCard
        title="Margin Used"
        value={formatToINR(Number(marginUsed))}
        isLargeScreen={isLargeScreen}
      />
      <PrimaryCard
        title="Total Cash"
        value={formatToINR(Number(cash))}
        isLargeScreen={isLargeScreen}
      />
    </Flex>
  );
}

const EquityFundsCardContent = ({ data }: { data: IUserLimit | [] }) => {
  const { classes } = useStyles()
  const {
    payIn,
    payOut,
    brokerCollateralAmount,
    premium,
    exposureMargin,
    span,
  } = data as IUserLimit
  const { isLargeScreen } = useMediaCustom();
  return (
    <Grid className={classes.eqFundsContent}>
      <Grid.Col span={isLargeScreen ? 7 : 12}>
        <div>
          <Flex position="space-between" mr={12} my={12}>
            <p className="heading_sm_semibold">Opening Balance</p>
            <p className="heading_sm_semibold">N/A</p>
          </Flex>
          <div className={classes.border} />
          <Flex position="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Pay-in</p>
            <p className="content_md_regular">
              {formatToINR(Number(payIn))}
            </p>
          </Flex>
          <Flex position="space-between" mr={12} mt={12} mb={48}>
            <p className="heading_xs_regular">Pay-out</p>
            <p className="content_md_regular">
              {formatToINR(Number(payOut))}
            </p>
          </Flex>
        </div>
        <div>
          <Flex position="space-between" mr={12} my={12}>
            <p className="heading_sm_semibold">Total Collateral</p>
            <p className="heading_sm_semibold">{formatToINR(Number(brokerCollateralAmount))}</p>
          </Flex>
          <div className={classes.border} />
          <Flex position="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Liquid Collateral</p>
            <p className="content_md_regular">N/A</p>
          </Flex>
          <Flex position="space-between" mr={12} my={12}>
            <p className="heading_xs_regular">Equity Collateral</p>
            <p className="content_md_regular">N/A</p>
          </Flex>
        </div>
      </Grid.Col>
      <Grid.Col className={classes.eqFundsContentLeft} span={isLargeScreen ? 5 : 12}>
        <SecondaryCard title="Option Premium" value={premium} />
        <SecondaryCard title="Exposure" value={exposureMargin} />
        <SecondaryCard title="Span" value={span} />
        <SecondaryCard title="Delivery Margin" value="N/A" />
      </Grid.Col>
    </Grid>
  );
}
// Main Component
const Funds = () => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const colorScheme = getCookie("tiqs-theme")
  const { isNavigateLoading } = useRouteChange()
  const { isMobile } = useMediaCustom()

  const limitsState = useSelector((state: RootState) => state.user.limits)
  const { status, loading, data } = limitsState;
  const isLimitsData = !!(data && data.length > 0);
  const isError = status === FAILURE;

  useEffect(() => {
    dispatch(getUserLimits())
  }, [dispatch])

  const handleAddFunds = () => {
    const url = process.env.REACT_APP_FUNDS_URL
    window.open(
      `${url}?theme=${colorScheme}`,
      '_blank',
      `left=${window.screen.width / 2 - 550 / 2},top=50,height=750,width=550`
    )
  }
  const handleWithdrawFunds = () => {
    const url = `${process.env.REACT_APP_HUB_URL}/funds/overview`
    window.open(
      `${url}?theme=${colorScheme}`,
      "_blank"
    );
  };
  const handleViewStatement = () => {
    window.open(`${process.env.REACT_APP_HUB_URL}/funds/statement`, "_blank");
  }

  const secondLoading = loading && isNavigateLoading && isLimitsData;

  return (
    <Box className={classes.wrapper} sx={secondLoading ? bounceAnimation : {}}>
      <RenderBoundary
        loading={loading}
        status={status}
        data={data}
        errorState={(
          <ErrorElement component="Positions" message={isError && limitsState.message} />
        )}
        customLoader={(
          <Loading />
        )}
      >
        {
          isLimitsData ? (
            <ErrorBoundary>
              {/* Funds Card */}
              <PlainCard
                headerClassName={classes.fundsCardHeader}
                leftContent={(
                  <Flex>
                    <p className={cx("cardTitle", classes.cardTitleFunds)}>
                      {" "}
                      Funds
                    </p>
                    {secondLoading ? <Loader size="sm" ml="md" /> : null}
                  </Flex>
                )}
                rightContent={(
                  <Flex direction={isMobile ? "column" : "row"}>
                    <Button
                      mr={isMobile ? 0 : 12}
                      mb={isMobile ? 12 : 0}
                      className="bg_primary_default"
                      variant="filled"
                      onClick={handleAddFunds}
                      fullWidth={isMobile}
                    >
                      Add Funds
                    </Button>
                    <Button
                      variant="outline"
                      onClick={handleWithdrawFunds}
                      fullWidth={isMobile}
                    >
                      Withdraw Funds
                    </Button>
                  </Flex>
                )}
                mainContent={<FundsCardContent data={data[0]} />}
              />

              {/* Equity Funds Card */}
              <PlainCard
                className={classes.equityFundsCardWrapper}
                leftContent={<p className="cardTitle">Equity Funds</p>}
                rightContent={(
                  <Button
                    onClick={handleViewStatement}
                    className="subtleButton"
                    variant="subtle"
                  >
                    View Statement
                  </Button>
                )}
                mainContent={<EquityFundsCardContent data={data[0]} />}
              />
            </ErrorBoundary>
          ) : (<ZeroState title="Equity" icon={<ChartPie size={18} />} />)
        }

      </RenderBoundary>
    </Box>
  );
}

export default Funds
