import {
  Button,
  Group,
  Anchor,
  PasswordInput,
  Box,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { Link } from "wouter"
import useResetPassword from "@hooks/handleApiCall/useResetPassword"
import Logo from "@components/logo";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { getQueryItem } from "../../utils"

const ResetPassword = () => {
  const { handleResetPassword } = useResetPassword()
  const { loading } = useSelector((state: RootState) => state.auth.reset)

  const form = useForm({
    initialValues: { password: "", repeatPassword: "" },

    validate: {
      password: (value) => (value?.length < 3 ? "User Id must have at least 3 letters" : null),
      repeatPassword: (value, values) => (value !== values.password ? "Passwords did not match" : null),
    },
  })

  const handleSubmit = async (val: typeof form.values) => {
    const requestId = getQueryItem(window.location.search, "requestId")
    if (requestId) {
      const payload = {
        newPassword: val.password,
        requestId,
      }
      handleResetPassword(payload)
    }
  }

  return (
    <div className="loggedOutCard">
      <div className="loggedOutCardHeader">
        <Logo />
        <h2 className="loggedOutCardTitle">Reset Password  </h2>
      </div>
      <Box p={16}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <PasswordInput
            label="New Password"
            placeholder="******"
            {...form.getInputProps("password")}
          />

          <PasswordInput
            label="Repeat New Password"
            placeholder="******"
            {...form.getInputProps("repeatPassword")}
            sx={{ marginTop: 14 }}
          />

          <Button fullWidth mt="xl" type="submit" loading={loading}>
            Continue
          </Button>

          <Group position="center" mt="lg">
            <Anchor component={Link} to="/" size="sm">
              Back to login
            </Anchor>
          </Group>
        </form>
      </Box>
    </div>
  )
}

export default ResetPassword
