import {
  Badge, Box, Button, Divider, Group, Modal,
} from "@mantine/core"
import {
  buySellConst, orderType, productType,
} from "src/utils/constant"
import { memo } from "react"
import useMediaCustom from "@hooks/common/useMediaCustom"
import { IGttOrderDetail, OrderParamsLeg } from "@redux/sagas/orders"

interface GttDetailsModalProps {
  isVisible: boolean
  handleGttDetailsCloseModal: () => void
  selectedGttDetails: IGttOrderDetail
}

interface OrderDetailListItemProps {
  title: string
  value: string | number | undefined
}

const OrderDetailListItem = ({ title, value }: OrderDetailListItemProps) => (
  <Group mb="sm" position="apart" align="center">
    <p className="content_md_regular text_fg_muted">
      {title}
    </p>
    <p className="content_md_regular text_fg_default">{value || '0.00'}</p>
  </Group>
)

const GttOrderLeg = ({ data, item }: { data: string, item: OrderParamsLeg }) => (
  <>
    <p className="content_md_regular text_content_default">
      Trigger @
      {data}
    </p>
    <Divider mt="sm" />
    <Group sx={{ gap: '2rem' }} position="apart" align="flex-start" mt="xl" mb="xl">
      <Box sx={{ flex: 1 }}>
        <OrderDetailListItem title="Transaction type" value={buySellConst[item.transactionType]} />
        <OrderDetailListItem title="Product" value={productType[item.product]} />
        <OrderDetailListItem title="Order type" value={orderType[item.orderType]} />
        <OrderDetailListItem title="Order ID" value="---" />
      </Box>
      <Box sx={{ flex: 1 }}>
        <OrderDetailListItem title="Quantity" value={item.quantity} />
        <OrderDetailListItem title="Price" value={item.price} />
        <OrderDetailListItem title="Trigger at price" value={data} />
        <OrderDetailListItem title="Triggered at" value="-" />
      </Box>
    </Group>
  </>
)

const GttDetailsModal = ({
  isVisible,
  handleGttDetailsCloseModal,
  selectedGttDetails,
}: GttDetailsModalProps) => {
  const { isMobile } = useMediaCustom();
  const {
    exchange,
    symbol,
    data,
    orderParamsLeg1,
    orderParamsLeg2,
  } = selectedGttDetails

  return (
    <Modal
      onClose={handleGttDetailsCloseModal}
      opened={isVisible}
      transitionProps={{ transition: 'fade', duration: 500, timingFunction: 'linear' }}
      size="lg"
      withCloseButton={false}
      fullScreen={!!isMobile}
    >
      <Group position="apart" mb="sm">
        <Group position="apart">
          <Badge color={orderParamsLeg2.quantity ? "orange" : "purple"} radius="xs" variant="light">
            {orderParamsLeg2.quantity ? "TWO LEG" : "SINGLE"}
          </Badge>
          <p className="heading_sm_semibold">{symbol}</p>
          <p className="text_fg_muted content_2xs_regular">{exchange}</p>
        </Group>
        <Badge color="green" radius="xs" variant="light">
          Active
        </Badge>
      </Group>
      <Divider />

      {/* modal data */}
      <Group sx={{ gap: '2rem' }} position="apart" align="flex-start" mt="xl" mb="xl">
        <Box sx={{ flex: 1 }}>
          <OrderDetailListItem title="Created at" value="Created at" />
          <OrderDetailListItem title="Updated at" value="price" />
        </Box>
        <Box sx={{ flex: 1 }}>
          <OrderDetailListItem title="Expires at" value="-" />
          <OrderDetailListItem title="LTP created at" value="-" />
        </Box>
      </Group>

      <GttOrderLeg data={data} item={orderParamsLeg1} />
      {orderParamsLeg2.quantity ? <GttOrderLeg data={data} item={orderParamsLeg2} /> : null}

      {/* modal button */}
      <Divider />
      <Group position="right" align="flex-start" mt="md">
        <Button
          variant="outline"
          color="gray"
          onClick={handleGttDetailsCloseModal}
        >
          Close
        </Button>
      </Group>
    </Modal>
  )
}

export default memo(GttDetailsModal)
