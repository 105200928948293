import { cleanNotificationsQueue, showNotification } from "@mantine/notifications"
import { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "wouter"
import { FAILURE, OrderTypeML, SUCCESS } from "src/utils/constant"
import { selectUserPreferences } from "src/redux/sagas/users/userSelectors"
import { getUserOrders } from "@redux/sagas/orders/ordersSlice"
import {
  setTradeModalInfo, orderMargin,
} from "@redux/sagas/trades/tradesSlice"
import type { RootState } from '@redux/store'
import { debounce, capitalizeFirstLetter, isEqual } from "@utils/index"
import usePrevious from "./usePrevious"

const audio = new Audio("/mess.mp3")

interface IUseTradeMarginWL {
  selectedExchange: string
  token: number
  quantity: number
  priceField: number
  buySellToggle: boolean
  orderTypeMLVal: string
  reloadOrderMargin: boolean
  productValue: string
  activeTab: string
}

const useTradeMarginWL = ({
  selectedExchange,
  token,
  quantity,
  priceField,
  buySellToggle,
  orderTypeMLVal,
  reloadOrderMargin,
  productValue,
  activeTab,
}: IUseTradeMarginWL) => {
  const dispatch = useDispatch()
  const [, setLocation] = useLocation();

  const {
    regularOrder,
    coverOrder,
    editRegular,
  } = useSelector((state: RootState) => state.trade)
  const { data: tradeMarginData } = useSelector((state:RootState) => state.trade.orderMargin)

  const {
    data: preferencesData,
  } = useSelector(selectUserPreferences)

  const { tradeModalInfo } = useSelector((state: RootState) => state.trade)

  const prevProps = usePrevious({ regularOrder, editRegular, coverOrder })

  const processChange = useCallback(
    debounce((payload: any) => dispatch(orderMargin(payload)), 250),
    []
  )

  useEffect(() => {
    const priceVal = orderTypeMLVal === OrderTypeML[0].value ? "" : priceField?.toString()
    const payload = {
      exchange: selectedExchange,
      token: token?.toString(),
      quantity: quantity?.toString(),
      price: priceVal,
      product: productValue,
      transactionType: buySellToggle ? "S" : "B",
      order: orderTypeMLVal,
    }
    if (token && orderTypeMLVal && priceField) {
      processChange(payload)
    }
  }, [
    token,
    quantity,
    selectedExchange,
    priceField,
    orderTypeMLVal,
    buySellToggle,
    processChange,
    reloadOrderMargin,
    productValue,
    activeTab,
  ])

  const regularOrderId = regularOrder.status === SUCCESS
    && regularOrder.data
    && regularOrder.data.id;

  const previousRegularOrderId = prevProps.regularOrder.status === SUCCESS
    && prevProps.regularOrder.data
    && prevProps.regularOrder.data.id;

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.regularOrder.status, regularOrder.status) && regularOrder.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(regularOrder.message),
        message: "Order failed",
      })
    } else if (
      !isEqual(previousRegularOrderId, regularOrderId) && regularOrder.status === SUCCESS
    ) {
      audio.play()
      dispatch(getUserOrders())
      // dispatch(getUserTrades())
      dispatch(
        setTradeModalInfo({
          ...tradeModalInfo,
          showTradeModal: preferencesData.stickyOrderWindow,
        })
      );
      setLocation("/orders");
    }
  }, [prevProps, regularOrder])

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.editRegular.status, editRegular.status) && editRegular.status === FAILURE) {
      showNotification({
        color: "red",
        title: capitalizeFirstLetter(editRegular.message),
        message: "Update order failed",
      })
    } else if (!isEqual(prevProps.editRegular.status, editRegular.status) && editRegular.status === SUCCESS) {
      audio.play()
      dispatch(getUserOrders())
      showNotification({
        title: "Order updated successfully",
        message: "",
      })
    }
  }, [prevProps, editRegular])

  const coverOrderId = regularOrder.data && regularOrder.data.id;
  const previousCoverOrderId = prevProps.regularOrder.data && prevProps.regularOrder.data.id

  useEffect(() => {
    cleanNotificationsQueue()
    if (!isEqual(prevProps.coverOrder.status, coverOrder.status) && coverOrder.status === FAILURE) {
      showNotification({
        color: "red",
        title: coverOrder.message,
        message: "Order failed",
      })
    } else if (!isEqual(previousCoverOrderId, coverOrderId) && coverOrder.status === SUCCESS) {
      // setShowTradeModal(preferencesData?.stickyOrderWindow)
      dispatch(
        setTradeModalInfo({
          ...tradeModalInfo,
          showTradeModal: preferencesData.stickyOrderWindow,
        })
      );
      showNotification({
        title: "Cover order placed successfully",
        message: "",
      })
    }
  }, [prevProps, coverOrder])

  return tradeMarginData ? {
    marginUsed: tradeMarginData.marginUsed,
    charge: tradeMarginData.charge,
  } : {
    marginUsed: 'N/A',
    charge: {
      total: 0,
    },
  }
}

export default useTradeMarginWL
