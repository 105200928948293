import { createSlice } from "@reduxjs/toolkit"
import { IInitialMiscSlice } from "."
import { ICombinedOrderDetail, IGttOrderDetail, IUserOrder } from "../orders"

const initialState: IInitialMiscSlice = {
  gttModalInfo: {
    isEdit: false,
    showGttModal: false,
    gttOrderDetails: {} as ICombinedOrderDetail,
  },
  deleteGttModalInfo: {
    alertId: "",
    showDeleteGttModal: false,
    gttOrderDetails: {} as IGttOrderDetail,
  },
  marketDepthInfo: {
    showMarketDepthModal: false,
    marketDepthDetails: {} as IUserOrder,
  },
  gttSearchResults: [],
}

export const miscSlice = createSlice({
  name: "misc",
  initialState: {
    ...initialState,
  },
  reducers: {
    setGttModalInfo: (state, action) => ({
      ...state,
      gttModalInfo: action.payload,
    }),
    setGttDeleteModalInfo: (state, action) => ({
      ...state,
      deleteGttModalInfo: action.payload,
    }),
    setMarketDepthInfo: (state, action) => ({
      ...state,
      marketDepthInfo: action.payload,
    }),
    setGttSearchResults: (state, action) => ({
      ...state,
      gttSearchResults: action.payload,
    }),

    resetMiscSlice: () => initialState,
  },
})

// Action
export const {
  setGttModalInfo,
  setGttDeleteModalInfo,
  setMarketDepthInfo,
  setGttSearchResults,
  resetMiscSlice,
} = miscSlice.actions

export default miscSlice.reducer
