import {
  Box, Menu, Portal, createStyles,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dots, InfoCircle, Minus, Plus,
} from 'tabler-icons-react'
import { useFocusWithin } from '@mantine/hooks'
import { setTradeModalInfo } from '@redux/sagas/trades/tradesSlice'
import { HoldingItem } from '@redux/sagas/holdings/holdingsTypes'
import CommonTableDropdown from '@components/table/commonTableDropdown'
import { getDropdownCoordinates } from '@utils/index'

export const useStyles = createStyles((theme, { showMenu }: { showMenu:boolean }) => {
  const { colors } = theme.other
  return {
    menuWrapper: {
      border: `1px solid ${colors.disabled.subtle}`,
      borderRadius: 3,
      padding: '0 2px',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: showMenu ? 'visible' : 'hidden',
      paddingTop: '5px',
    },
    menuPopupWrapper: {
      position: "absolute",
      zIndex: 500,
      height: 'min-content',
    },
  }
});

interface ITableDropdown {
  row: HoldingItem,
}

const HoldingsDropdown = ({
  row,
}: ITableDropdown) => {
  const [showMenu, setShowMenu] = useState(false);
  const { classes, cx } = useStyles({ showMenu });
  const dispatch = useDispatch();
  const { ref, focused } = useFocusWithin();

  const handleShowMenuDropdown = (isOpen: boolean) => {
    setShowMenu(isOpen)
  }
  useEffect(() => {
    handleShowMenuDropdown(focused)
  }, [focused])

  const handleSelectBuy = (item: HoldingItem, isBuy: boolean) => {
    const {
      symbol, token, exchange, effectiveQty,
    } = item;
    dispatch(
      setTradeModalInfo({
        currentAssetItem: {
          expiry: 0, symbol, token, exchange,
        },
        buySellToggle: isBuy,
        showTradeModal: true,
        quantity: effectiveQty,
      })
    );
  }

  return (
    <span ref={ref} className={cx(!showMenu ? 'dots' : null, classes.menuWrapper)}>
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ duration: 150 }}
        position="bottom-end"
        withArrow
        offset={-8}
        opened={showMenu}
        onChange={() => handleShowMenuDropdown(!showMenu)}
      >
        <Box onMouseLeave={() => handleShowMenuDropdown(false)}>
          <Menu.Target>
            <span>
              <Dots cursor="pointer" />
            </span>
          </Menu.Target>
          <Portal>
            <Menu.Dropdown
              sx={{
                top: getDropdownCoordinates().top,
                right: getDropdownCoordinates().right,
                bottom: getDropdownCoordinates().bottom,
                left: getDropdownCoordinates().left,
              }}
              className={cx(classes.menuPopupWrapper, 'tableDropDowns')}
            >
              <Menu.Item
                icon={<Minus size={14} />}
                onClick={() => handleSelectBuy(row as HoldingItem, true)}
              >
                Exit
              </Menu.Item>
              <Menu.Item icon={<Plus size={14} />} onClick={() => handleSelectBuy(row as HoldingItem, false)}>
                Add
              </Menu.Item>
              <Menu.Item
                icon={<InfoCircle size={14} />}
              >
                View breakdown +
              </Menu.Item>
              <CommonTableDropdown row={row} />
            </Menu.Dropdown>
          </Portal>
        </Box>
      </Menu>
    </span>
  )
}

export default HoldingsDropdown
